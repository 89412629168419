import React, { Suspense, useState, useEffect, useReducer } from "react";
import { useStore, useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory, NavLink } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from "react-helmet";
import Layout from '../../../utils/templates/layout/default';
import { APIS } from '../config/constant';
import { API_STATUS, TITLE, FORMAT_FORM_DATA } from '../../../config/constant';
import { CONFIG } from '../../../config/settings';
import { initUserAuthentication } from '../../../redux/actions/auth'

import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";


const Login = () => {
    const history = useHistory()
    // const store = useStore()
    const [meta_title, setMeta_title] = useState(TITLE);
    const [meta_description, setMeta_description] = useState(TITLE);
    const [is_submitted, setIs_submitted] = useState(false);
    const url = new URL(window.location.href);
    const redirect = url.searchParams.get('redirect');
    let redirect_url = false;
    if (redirect) {
        redirect_url = '/' + redirect;
    }

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const { register, errors, handleSubmit } = useForm();

    useEffect(() => {
        if (auth && auth.token) {
            toast.error('You are already logged in');
            history.push('/');
        }

        axios.get(APIS.PAGES + '?slug=login')
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        if (response.data.response.data.others) {
                            setMeta_title(response.data.response.data.others.meta_title)
                            setMeta_description(response.data.response.data.others.meta_description)
                        }
                    }
                }
            });

    }, []);

    const submitLogin = (formdata) => {
        setIs_submitted(true)
        axios.post(APIS.LOGIN, FORMAT_FORM_DATA(formdata))
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        // store.dispatch({ type: TYPES.INIT_USER_AUTHENTICATION, payload: response.data.response.data })
                        dispatch(initUserAuthentication(response.data.response.data))
                        if (redirect_url) {
                            toast.success(response.data.response.message)
                            history.push(redirect_url);
                        } else {
                            toast.success(response.data.response.message)
                            history.push('/myaccount');
                        }
                    } else {
                        toast.error(response.data.response.message)
                    }
                    console.log('response.data', response.data)
                }
                setIs_submitted(false)
            });
    }

    return (
        <Suspense>
            <Layout>
                <section className="login-page">
                    <Helmet>
                        <title>{meta_title}</title>
                        <meta name="description" content={meta_description} />
                        <link rel="canonical" href={`${CONFIG.BASE_URL}/login`} />
                    </Helmet>
                    <div className="container-fluid title-inner-card">
                        <div className="all-page-title">
                            <h2>Login</h2>
                        </div>
                    </div>
                    <div className="container py-md-80 py-50 container-maxWidth">
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <form method="POST" className="form-box" onSubmit={handleSubmit(submitLogin)}>
                                    <h4 className="welcome-text">Welcome</h4>
                                    <div className="login-input-field user">
                                    <input type="text" name="email" className="form-control" ref={register({
                                        required: true
                                    })} placeholder="Username/Email" />
                                    {errors.email && <label className="text-danger">* This field is required</label>}
                                    </div>
                                   <div className="login-input-field lock">
                                   <input type="password" name="password" className="form-control" ref={register({
                                        required: true
                                    })} placeholder="Password" />
                                    {errors.password && <label className="text-danger">* This field is required</label>}
                                   </div>
                                    
                                    <br />
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="text-center"><input className="btn btn-warning coyote-btn form-control" type="submit" name="submit" disabled={is_submitted} value={is_submitted ? 'Please Wait ...' : 'Login'} /></div>
                                        </div>
                                        <div className="col-12 mt-20 d-flex justify-content-end align-items-center"><NavLink to="/auth/register" className="register">Register</NavLink> <a href="#">|</a> <NavLink to="/auth/forgot-password" className="forgetPass"> Forgot Password?</NavLink></div>
                                    </div>

                                </form>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                    <br />
                </section>
            </Layout>
        </Suspense>
    )
};

export default withRouter(Login);