/**
 * All common variables, methods.
 *
 * @author MD. KHAIRULLAH NAYAN <khairullah@codersbucket.com>
 *
 */

import moment from "moment";
export const ROUTES = {
  ROOT: "/",
  LOGIN: "/auth/login",
};

export const TITLE = "MOOD";

export const API_STATUS = {
  OK: "OK",
};

export const APIS = {
  SETTINGS: "/settings",
};

export const SET_STORAGE = (name, value) => {
  return localStorage.setItem(name, value);
};
export const GET_STORAGE = (name) => {
  return localStorage.getItem(name);
};
export const REMOVE_STORAGE = (name) => {
  return localStorage.removeItem(name);
};
export const SET_SESSION = (name, value) => {
  return sessionStorage.setItem(name, value);
};
export const GET_SESSION = (name) => {
  return sessionStorage.getItem(name);
};
export const REMOVE_SESSION = (name) => {
  return sessionStorage.removeItem(name);
};

export const USER = "_moodu_";
export const SETTINGS = "_mood_s_";
export const CART = "_mood_c_";
export const WISHLIST = "_mood_w_";
export const QUANTITY_UPDATE = "_mood_c_q_up_";
export const MAX_PRICE_RANGE = 1000;

export const PROCESS_PRODUCT_FOR_CART = (product) => {
  let processed_product = {};
  processed_product.id = product.id ? product.id : 0;
  processed_product.uuid = product.uuid ? product.uuid : "-";
  processed_product.slug = product.slug ? product.slug : "-";
  processed_product.name = product.name ? product.name : "-";
  processed_product.type = product.type ? product.type : "general";

  if (product.type == "custom") {
    processed_product.price = product.price_total
      ? parseFloat(product.price_total).toFixed(2)
      : parseFloat("0").toFixed(2);
    processed_product.price_with_variation = processed_product.price;
    processed_product.c_quantity = product.c_quantity
      ? product.c_quantity
      : product.options && product.options.quantity
      ? product.options.quantity
      : 1;
    processed_product.options = product.attributes;
    processed_product.base_product_id = product.base_product_id;
    processed_product.stages = product.stages;
  } else {
    // processed_product.price = product.price ? parseFloat(product.price).toFixed(2) : parseFloat('0').toFixed(2);
    processed_product.price = CALCULATE_PRODUCT_PRICE_NEW(product);
    processed_product.c_quantity = product.c_quantity ? product.c_quantity : 1;
    processed_product.price_with_variation =
      CALCULATE_PRODUCT_PRICE_NEW(product);
  }

  processed_product.sku = product.sku ? product.sku : "-";
  processed_product.sale_price = product.sale_price ? product.sale_price : 0.0;
  processed_product.c_options = product.c_options ? product.c_options : {};
  processed_product.image = require("../assets/img/no-image.png").default;
  if (product.others) {
    if (typeof product.others == "string") {
      const others_data = JSON.parse(product.others);
      processed_product.image =
        others_data && others_data.feature_image_full_url
          ? others_data.feature_image_full_url.thumb_img
          : processed_product.image;
    } else {
      processed_product.image =
        product.others && product.others.feature_image_full_url
          ? product.others.feature_image_full_url.thumb_img
          : processed_product.image;
    }
  }
  // if (Object.keys(processed_product.c_options).length > 0) {
  //     let max_price_value = processed_product.price;
  //     Object.keys(processed_product.c_options).map((index) => {
  //         const option_price = eval(processed_product.price + processed_product.c_options[index].price_prefix + parseFloat(processed_product.c_options[index].price))
  //         if (option_price > max_price_value) {
  //             max_price_value = option_price;
  //         }
  //     })
  //     processed_product.price_with_variation = max_price_value;
  // }
  return processed_product;
};

export const PROCESS_CART_FOR_PAYPAL_PAYMENT = () => {
  const cart_data = JSON.parse(GET_STORAGE(CART));
  let cart_items = [];
  if (cart_data && Object.keys(cart_data).length > 0) {
    Object.keys(cart_data).map((cart_data_index) => {
      const cart_data_single = cart_data[cart_data_index];
      let single_cart = {
        reference_id: cart_data_single.id,
        name: cart_data_single.name,
        quantity: cart_data_single.c_quantity,
        description: cart_data_single.name,
        sku: cart_data_single.sku,
        category: "PHYSICAL_GOODS",
        amount: {
          value: cart_data_single.price_with_variation,
        },
      };
      cart_items.push(single_cart);
    });
  }
  return cart_items;
};

export const CART_TOTAL = () => {
  const cart_data = JSON.parse(GET_STORAGE(CART));
  let cart_total = 0;
  if (cart_data && Object.keys(cart_data).length > 0) {
    Object.keys(cart_data).map((cart_data_index) => {
      const cart_data_single = cart_data[cart_data_index];
      cart_total =
        cart_total +
        "+" +
        parseFloat(cart_data_single.price_with_variation).toFixed(2);
    });
  }
  return eval(cart_total);
};

export const CALCULATE_PRODUCT_PRICE_NEW = (product) => {
  let actual_price =
    product.sale_price > 0 ? product.sale_price : product.price;
  let calculated_unit_price = parseFloat(actual_price).toFixed(2);
  let sizePrice = 0;
  let mkn_options = false;
  if (product.c_options) {
    mkn_options = product.c_options;
  }
  if (mkn_options && Object.keys(mkn_options).length > 0) {
    Object.keys(mkn_options).map((index) => {
      if (index == "Size") {
        sizePrice = mkn_options[index].price
          ? parseFloat(mkn_options[index].price).toFixed(2)
          : 0;
      }
    });
  }
  const total_unit_price = eval(calculated_unit_price + "+" + sizePrice);
  return total_unit_price;
};

export const CALCULATE_PRODUCT_PRICE = (product) => {
  let calculated_unit_price = parseFloat(product.price).toFixed(2);
  let calculated_max_price = parseFloat(product.price).toFixed(2);
  let calculated_max_unit_price = parseFloat(product.price).toFixed(2);
  let calculated_discount_percentage = false;
  let calculated_max_unit_price_exists = false;
  let calculated_wholesale_unit_price = parseFloat(
    product.wholesale_price
  ).toFixed(2);
  let calculated_wholesale_max_price = parseFloat(
    product.wholesale_price
  ).toFixed(2);
  let calculated_wholesale_max_unit_price = parseFloat(
    product.wholesale_price
  ).toFixed(2);
  let calculated_wholesale_discount_percentage = false;
  let calculated_wholesale_max_unit_price_exists = false;

  const special_price = CALCULATE_SPECIAL_PRICE(product);
  if (special_price.special_price_value) {
    calculated_unit_price = special_price.special_price_value;
    calculated_max_unit_price = special_price.special_price_value;
    calculated_max_price = special_price.special_price_value;
    calculated_discount_percentage =
      special_price.special_price_disount_percentage;
  } else {
    const discount_price = CALCULATE_DISCOUNT(product);
    if (discount_price.discounted_price_exists) {
      calculated_unit_price = discount_price.discounted_price_amount;
      calculated_max_price = discount_price.discounted_price_amount;
      calculated_max_unit_price = discount_price.discounted_price_amount;
      calculated_discount_percentage =
        discount_price.discounted_price_percentage;
    }
  }

  if (product.options && product.options != "") {
    const option_prices = OPTION_PRICE(product.options);
    calculated_unit_price =
      parseFloat(calculated_unit_price) +
      parseFloat(option_prices.min_price_value);
    calculated_max_unit_price =
      parseFloat(calculated_max_unit_price) +
      parseFloat(option_prices.max_price_value);
    calculated_max_price =
      parseFloat(calculated_max_price) +
      parseFloat(option_prices.max_price_value_with_checkbox);
    calculated_max_unit_price_exists = true;

    //wholesale price
    calculated_wholesale_unit_price =
      parseFloat(calculated_wholesale_unit_price) +
      parseFloat(option_prices.min_price_value);
    calculated_wholesale_max_unit_price =
      parseFloat(calculated_wholesale_max_unit_price) +
      parseFloat(option_prices.max_price_value);
    calculated_wholesale_max_price =
      parseFloat(calculated_wholesale_max_price) +
      parseFloat(option_prices.max_price_value_with_checkbox);
    calculated_wholesale_max_unit_price_exists = true;

    if (
      product.c_options &&
      product.c_options != "" &&
      Object.keys(product.c_options).length > 0
    ) {
      const selected_option_prices = SELECTED_OPTION_PRICE(product.c_options);
      calculated_unit_price =
        parseFloat(calculated_unit_price) +
        parseFloat(selected_option_prices.min_price_value_with_checkbox);
      calculated_max_unit_price =
        parseFloat(calculated_max_unit_price) +
        parseFloat(selected_option_prices.max_price_value);
      // calculated_max_price = parseFloat(calculated_max_price) + parseFloat(selected_option_prices.max_price_value_with_checkbox)
      calculated_max_unit_price_exists = true;

      //wholesale price
      calculated_wholesale_unit_price =
        parseFloat(calculated_wholesale_unit_price) +
        parseFloat(selected_option_prices.min_price_value_with_checkbox);
      calculated_wholesale_max_unit_price =
        parseFloat(calculated_wholesale_max_unit_price) +
        parseFloat(selected_option_prices.max_price_value);
      // calculated_wholesale_max_price = parseFloat(calculated_wholesale_max_price) + parseFloat(selected_option_prices.max_price_value_with_checkbox)
      calculated_wholesale_max_unit_price_exists = true;
    }
  }

  return {
    calculated_unit_price,
    calculated_max_unit_price,
    calculated_max_price,
    calculated_discount_percentage,
    calculated_max_unit_price_exists,
    calculated_wholesale_unit_price,
    calculated_wholesale_max_unit_price,
    calculated_wholesale_max_price,
    calculated_wholesale_discount_percentage,
    calculated_wholesale_max_unit_price_exists,
  };
};

export const CALCULATE_SPECIAL_PRICE = (product) => {
  let special_price_value = false;
  let special_price_date = false;
  let special_price_disount_percentage = false;
  let others = false;
  if (product && product.others && typeof product.others == "object") {
    others = product.others;
  } else {
    others = JSON.parse(product.others);
  }

  if (others) {
    if (others.date_available && others.date_available != "") {
      const end_date = moment(others.date_available, "MM/DD/YYYY").format(
        "DD/MM/YYYY"
      );
      const current_date = moment().format("DD/MM/YYYY");
      if (current_date <= end_date) {
        special_price_value = others.special_price
          ? parseFloat(others.special_price).toFixed(2)
          : false;
        special_price_date = end_date;
        special_price_disount_percentage = others.special_price
          ? parseFloat(
              ((parseFloat(product.price) - parseFloat(others.special_price)) /
                parseFloat(product.price)) *
                100
            ).toFixed(2)
          : false;
      }
    } else {
      special_price_value = others.special_price
        ? parseFloat(others.special_price).toFixed(2)
        : false;
      special_price_disount_percentage = others.special_price
        ? parseFloat(
            ((parseFloat(product.price) - parseFloat(others.special_price)) /
              parseFloat(product.price)) *
              100
          ).toFixed(2)
        : false;
    }
  }
  return {
    special_price_value,
    special_price_date,
    special_price_disount_percentage,
  };
};

export const CALCULATE_DISCOUNT = (product) => {
  let discounted_price_amount = 0;
  let discounted_price_value = 0;
  let discounted_price_percentage = false;
  let discounted_wholesale_price_amount = 0;
  let discounted_wholesale_price_value = 0;
  let discounted_wholesale_price_percentage = false;
  let discounted_price_start_date = false;
  let discounted_price_end_date = false;
  let discounted_price_exists = false;
  if (product && product.discounts_list) {
    let discounts_list = false;
    if (product.discounts_list && typeof product.discounts_list == "object") {
      discounts_list = product.discounts_list;
    } else {
      discounts_list = JSON.parse(product.discounts_list);
    }

    if (discounts_list && discounts_list.length > 0) {
      discounts_list.some(function (discounts_list_single) {
        if (
          discounts_list_single.start_date &&
          discounts_list_single.end_date &&
          discounts_list_single.start_date != "" &&
          discounts_list_single.end_date != ""
        ) {
          const start_date = moment(
            discounts_list_single.start_date,
            "MM/DD/YYYY"
          ).format("DD/MM/YYYY");
          const end_date = moment(
            discounts_list_single.end_date,
            "MM/DD/YYYY"
          ).format("DD/MM/YYYY");
          const current_date = moment().format("DD/MM/YYYY");
          if (current_date >= start_date && current_date <= end_date) {
            discounted_price_start_date = start_date;
            discounted_price_end_date = end_date;
            if (discounts_list_single.price_type == "P") {
              discounted_price_amount = parseFloat(
                parseFloat(product.price) -
                  parseFloat(product.price) *
                    (parseFloat(discounts_list_single.price) / 100)
              ).toFixed(2);
              discounted_price_percentage = discounts_list_single.price;
              discounted_wholesale_price_amount = parseFloat(
                parseFloat(product.wholesale_price) -
                  parseFloat(product.wholesale_price) *
                    (parseFloat(discounts_list_single.price) / 100)
              ).toFixed(2);
              discounted_wholesale_price_percentage =
                discounts_list_single.price;
              discounted_price_value = parseFloat(
                parseFloat(product.price) - discounted_price_amount
              ).toFixed(2);
              discounted_wholesale_price_value = parseFloat(
                parseFloat(product.wholesale_price) -
                  discounted_wholesale_price_amount
              ).toFixed(2);
              return;
            } else {
              discounted_price_amount = parseFloat(
                parseFloat(product.price) -
                  parseFloat(discounts_list_single.price)
              ).toFixed(2);
              discounted_price_percentage = parseFloat(
                (parseFloat(discounts_list_single.price) /
                  parseFloat(product.price)) *
                  100
              ).toFixed(2);
              discounted_wholesale_price_amount = parseFloat(
                parseFloat(product.wholesale_price) -
                  parseFloat(discounts_list_single.price)
              ).toFixed(2);
              discounted_wholesale_price_percentage = parseFloat(
                (parseFloat(discounts_list_single.price) /
                  parseFloat(product.wholesale_price)) *
                  100
              ).toFixed(2);
              discounted_price_value = discounts_list_single.price;
              discounted_wholesale_price_value = discounts_list_single.price;
              return;
            }
          }
        } else {
          if (discounts_list_single.price_type == "P") {
            discounted_price_amount = parseFloat(
              parseFloat(product.price) -
                parseFloat(product.price) *
                  (parseFloat(discounts_list_single.price) / 100)
            ).toFixed(2);
            discounted_price_percentage = discounts_list_single.price;
            discounted_wholesale_price_amount = parseFloat(
              parseFloat(product.wholesale_price) -
                parseFloat(product.wholesale_price) *
                  (parseFloat(discounts_list_single.price) / 100)
            ).toFixed(2);
            discounted_wholesale_price_percentage = discounts_list_single.price;
            discounted_price_value = parseFloat(
              parseFloat(product.price) - discounted_price_amount
            ).toFixed(2);
            discounted_wholesale_price_value = parseFloat(
              parseFloat(product.wholesale_price) -
                discounted_wholesale_price_amount
            ).toFixed(2);
            discounted_price_exists = true;
            return;
          } else {
            discounted_price_amount = parseFloat(
              parseFloat(product.price) -
                parseFloat(discounts_list_single.price)
            ).toFixed(2);
            discounted_price_percentage = parseFloat(
              (parseFloat(discounts_list_single.price) /
                parseFloat(product.price)) *
                100
            ).toFixed(2);
            discounted_wholesale_price_amount = parseFloat(
              parseFloat(product.wholesale_price) -
                parseFloat(discounts_list_single.price)
            ).toFixed(2);
            discounted_wholesale_price_percentage = parseFloat(
              (parseFloat(discounts_list_single.price) /
                parseFloat(product.wholesale_price)) *
                100
            ).toFixed(2);
            discounted_price_value = discounts_list_single.price;
            discounted_wholesale_price_value = discounts_list_single.price;
            discounted_price_exists = true;
            return;
          }
        }
      });
    }
  }

  return {
    discounted_price_amount,
    discounted_price_value,
    discounted_price_percentage,
    discounted_wholesale_price_amount,
    discounted_wholesale_price_value,
    discounted_wholesale_price_percentage,
    discounted_price_start_date,
    discounted_price_end_date,
    discounted_price_exists,
  };
};

export const OPTION_PRICE = (options) => {
  let min_price_value = 0;
  let max_price_value = 0;
  let checkbox_total_selected_prices = 0;
  let mkn_options = false;
  if (options && typeof options == "object") {
    mkn_options = options;
  } else {
    mkn_options = JSON.parse(options);
  }

  if (mkn_options && Object.keys(mkn_options).length > 0) {
    Object.keys(mkn_options).map((index) => {
      if (
        mkn_options[index].option_type == "Select" ||
        mkn_options[index].option_type == "Radio" ||
        mkn_options[index].option_type == "Checkbox"
      ) {
        if (
          mkn_options[index].choose_field &&
          Object.keys(mkn_options[index].choose_field).length > 0
        ) {
          Object.keys(mkn_options[index].choose_field).map((i) => {
            const option_price = mkn_options[index].choose_field[i].price
              ? parseFloat(
                  mkn_options[index].choose_field[i].price_prefix +
                    mkn_options[index].choose_field[i].price
                )
              : 0;
            if (mkn_options[index].option_type == "Checkbox") {
              checkbox_total_selected_prices = eval(
                checkbox_total_selected_prices +
                  "+" +
                  (mkn_options[index].choose_field[i].price
                    ? parseFloat(
                        mkn_options[index].choose_field[i].price
                      ).toFixed(2)
                    : 0)
              );
            } else {
              if (option_price > max_price_value) {
                max_price_value = option_price;
              }
              if (option_price < min_price_value) {
                min_price_value = option_price;
              }
            }
          });
        }
      }
    });
  }

  return {
    min_price_value,
    max_price_value,
    checkbox_total_selected_prices,
    min_price_value_with_checkbox:
      min_price_value + checkbox_total_selected_prices,
    max_price_value_with_checkbox:
      max_price_value + checkbox_total_selected_prices,
  };
};

export const SELECTED_OPTION_PRICE = (c_options) => {
  let min_price_value = 0;
  let max_price_value = 0;
  let checkbox_total_selected_prices = 0;
  let mkn_options = false;
  if (c_options && typeof c_options == "object") {
    mkn_options = c_options;
  } else {
    mkn_options = JSON.parse(c_options);
  }
  if (mkn_options && Object.keys(mkn_options).length > 0) {
    Object.keys(mkn_options).map((index) => {
      if (
        mkn_options[index].option_type == "Select" ||
        mkn_options[index].option_type == "Radio" ||
        mkn_options[index].option_type == "Checkbox"
      ) {
        const option_price = mkn_options[index].price
          ? parseFloat(
              mkn_options[index].price_prefix + mkn_options[index].price
            )
          : 0;
        if (mkn_options[index].option_type == "Checkbox") {
          const selected_values = mkn_options[index].option_value;
          if (selected_values && selected_values.length > 0) {
            Object.keys(mkn_options[index].choose_field).map((i) => {
              if (
                selected_values.includes(
                  mkn_options[index].choose_field[i].option_value
                )
              ) {
                checkbox_total_selected_prices = eval(
                  checkbox_total_selected_prices +
                    "+" +
                    (mkn_options[index].choose_field[i].price
                      ? parseFloat(
                          mkn_options[index].choose_field[i].price
                        ).toFixed(2)
                      : 0)
                );
              }
            });
          }
        } else {
          if (option_price > max_price_value) {
            max_price_value = option_price;
          }
          if (option_price < min_price_value) {
            min_price_value = option_price;
          }
        }
      }
    });
  }

  return {
    min_price_value,
    max_price_value,
    checkbox_total_selected_prices,
    min_price_value_with_checkbox:
      min_price_value + checkbox_total_selected_prices,
    max_price_value_with_checkbox:
      max_price_value + checkbox_total_selected_prices,
  };
};

export const ORDER_STATUS = [
  { value: "C", name: "Canceled", class: "danger" },
  { value: "CO", name: "Complete", class: "success" },
  { value: "DEI", name: "Denied", class: "dark" },
  { value: "EXP", name: "Expired", class: "secondary" },
  { value: "FAI", name: "Failed", class: "danger" },
  { value: "P", name: "Pending", class: "warning" },
  { value: "PO", name: "Processed", class: "info" },
  { value: "POG", name: "Processing", class: "primary" },
  { value: "SHP", name: "Shipped", class: "success" },
];

export const FIND_ORDER_STATUS = (key) => {
  let return_text = [{ value: "P", name: "Pending", class: "warning" }];
  return_text = ORDER_STATUS.filter(
    (order_status_value, order_status_index) => {
      return order_status_value.value == key;
    }
  );
  return return_text[0]
    ? return_text[0]
    : { value: "P", name: "Pending", class: "warning" };
};

export const FORMAT_FORM_DATA = (data) => {
  let formattedData = new FormData();
  if (data && Object.keys(data).length > 0) {
    Object.keys(data).map((data_index) => {
      formattedData.set(data_index, data[data_index]);
    });
  }
  return formattedData;
};

export const randomIntFromInterval = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);
