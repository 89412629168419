import React, {
  Suspense,
  useState,
  useEffect,
  useReducer,
  useRef,
  Fragment,
} from "react";
import { useStore, useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory, NavLink } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import Layout from "../../../utils/templates/layout/default";
import { APIS } from "../config/constant";
import { API_STATUS, TITLE, FORMAT_FORM_DATA } from "../../../config/constant";
import { CONFIG } from "../../../config/settings";
import { initUserAuthentication } from "../../../redux/actions/auth";
import ReactImageFallback from "react-image-fallback";

import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const Register = () => {
  const history = useHistory();
  // const store = useStore()
  const [meta_title, setMeta_title] = useState(TITLE);
  const [meta_description, setMeta_description] = useState(TITLE);
  const [is_submitted, setIs_submitted] = useState(false);
  const [countries, setCountries] = useState({});
  const [province_list, setProvince_list] = useState({});
  const [loading, setLoading] = useState(true);
  const url = new URL(window.location.href);
  const redirect = url.searchParams.get("redirect");
  let redirect_url = false;
  if (redirect) {
    redirect_url = "/" + redirect;
  }
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, errors, handleSubmit, watch, setValue } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    if (auth && auth.token) {
      toast.error("You are already logged in");
      history.push("/");
    }
  }, []);

  useEffect(() => {
    axios.get(APIS.COUNTRYLIST).then((response) => {
      if (response.data.status == API_STATUS.OK) {
        if (response.data.response.result) {
          setCountries(response.data.response.data);

          setTimeout(() => {
            setValue("country", "173");
            setValue("country_text", "Canada");

            let country = document.getElementById("country");
            let changeEvent = new Event("change", {
              bubbles: true,
              cancelable: true,
            });
            country.dispatchEvent(changeEvent);
          }, 500);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    });

    axios.get(APIS.PAGES + "?slug=register").then((response) => {
      if (response.data.status == API_STATUS.OK) {
        if (response.data.response.result) {
          if (response.data.response.data.others) {
            setMeta_title(response.data.response.data.others.meta_title);
            setMeta_description(
              response.data.response.data.others.meta_description
            );
          }
        }
      }
    });
  }, []);

  const updateProvince = (e) => {
    const country_text = e.target.options[e.target.selectedIndex].text;
    setProvince_list({});
    setValue("city", "");
    setValue("postal_code", "");
    setValue("country_text", country_text);
    setValue("province", "187");
    setValue("province_text", "Alberta");
    setValue("international_province", "");

    axios.get(APIS.PROVINCELIST + "/" + e.target.value).then((response) => {
      if (response.data.status == API_STATUS.OK) {
        if (response.data.response.result) {
          setProvince_list(response.data.response.data);
          setValue("province", "187");
          setValue("province_text", "Alberta");
        }
      }
    });
  };

  const provinceHandler = (e) => {
    const province_text = e.target.options[e.target.selectedIndex].text;
    setValue("province_text", province_text);
    setValue("international_province", province_text);
  };

  const submitForm = (formdata) => {
    setIs_submitted(true);
    axios.post(APIS.REGISTER, FORMAT_FORM_DATA(formdata)).then((response) => {
      if (response.data.status == API_STATUS.OK) {
        if (response.data.response.result) {
          // store.dispatch({ type: TYPES.INIT_USER_AUTHENTICATION, payload: response.data.response.data })
          // dispatch(initUserAuthentication(response.data.response.data))
          if (redirect_url) {
            toast.success(response.data.response.message);
            history.push("/auth/login?redirect=" + redirect);
            // history.push(redirect_url);
          } else {
            toast.success(response.data.response.message);
            history.push("/auth/login");
          }
        } else {
          toast.error(response.data.response.message);
        }
      }
      setIs_submitted(false);
    });
  };

  return (
    <Suspense>
      <Layout>
        <section className="login-page">
          <Helmet>
            <title>{meta_title}</title>
            <meta name="description" content={meta_description} />
            <link rel="canonical" href={`${CONFIG.BASE_URL}/register`} />
          </Helmet>
          <div className="container-fluid title-inner-card">
            <div className="all-page-title">
              <h2>Register</h2>
            </div>
          </div>
          <div className="container py-md-80 py-50 container-maxWidth">
            {loading ? (
              <div className="loading">
                <ReactImageFallback
                  src={require("../../../assets/img/loading.gif").default}
                  fallbackImage={
                    require("../../../assets/img/no-image.png").default
                  }
                  initialImage={
                    require("../../../assets/img/loading.gif").default
                  }
                  alt="no image"
                  style={{ width: "30px" }}
                  className=""
                />
              </div>
            ) : (
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                  <form method="POST" onSubmit={handleSubmit(submitForm)}>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          name="first_name"
                          className="form-control"
                          id="first_name"
                          placeholder="First Name"
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.first_name && (
                          <label className="text-danger">
                            * This field is required
                          </label>
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          name="last_name"
                          className="form-control"
                          id="last_name"
                          placeholder="Last Name"
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.last_name && (
                          <label className="text-danger">
                            * This field is required
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          name="username"
                          className="form-control"
                          id="username"
                          placeholder="Username"
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.username && (
                          <label className="text-danger">
                            * This field is required
                          </label>
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="form-control"
                          ref={register({
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "invalid email address",
                            },
                          })}
                        />
                        {errors.email && (
                          <label className="text-danger">
                            *{" "}
                            {errors.email.message
                              ? errors.email.message
                              : "This field is required"}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          id="password"
                          placeholder="Password"
                          ref={register({
                            required: true,
                            minLength: 8,
                          })}
                        />
                        {errors.password &&
                          errors.password.type === "required" && (
                            <label className="text-danger">
                              * This field is required
                            </label>
                          )}
                        {errors.password &&
                          errors.password.type === "minLength" && (
                            <label className="text-danger">
                              * Password must be at least 8 characters long
                            </label>
                          )}
                      </div>
                      <div className="form-group col-md-6">
                        <input
                          type="password"
                          name="re_password"
                          className="form-control"
                          id="re_password"
                          placeholder="Confirm Password"
                          ref={register({
                            required: true,
                            minLength: 8,
                            validate: (value) =>
                              value === password.current ||
                              "Confirm Password did not match",
                          })}
                        />
                        {errors.re_password &&
                          errors.re_password.type === "required" && (
                            <label className="text-danger">
                              *{" "}
                              {errors.re_password.message
                                ? errors.re_password.message
                                : "This field is required"}
                            </label>
                          )}
                        {errors.re_password &&
                          errors.re_password.type === "minLength" && (
                            <label className="text-danger">
                              * Password must be at least 8 characters long
                            </label>
                          )}
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="address1"
                        className="form-control"
                        id="address1"
                        placeholder="Address 1"
                        ref={register({
                          required: true,
                        })}
                      />
                      {errors.address1 && (
                        <label className="text-danger">
                          * This field is required
                        </label>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="address2"
                        className="form-control"
                        id="address2"
                        placeholder="Address 2"
                        ref={register()}
                      />
                      {errors.address2 && (
                        <label className="text-danger">
                          * This field is required
                        </label>
                      )}
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <select
                          id="country"
                          className="form-control"
                          name="country"
                          ref={register({
                            required: true,
                          })}
                          onChange={updateProvince}
                        >
                          <option value="">Country</option>
                          {Object.keys(countries).length > 0 && (
                            <Fragment>
                              {Object.keys(countries).map((countries_index) => {
                                return (
                                  <option
                                    key={`country_id_${countries_index}`}
                                    value={countries_index}
                                  >
                                    {countries[countries_index]}
                                  </option>
                                );
                              })}
                            </Fragment>
                          )}
                        </select>
                        <input
                          type="hidden"
                          id="country_text"
                          name="country_text"
                          ref={register()}
                        />
                        {errors.country && (
                          <label className="text-danger">
                            * This field is required
                          </label>
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <select
                          id="province"
                          className="form-control"
                          name="province"
                          ref={register({
                            required: true,
                          })}
                          onChange={provinceHandler}
                        >
                          <option value="">State/Province</option>
                          {Object.keys(province_list).length > 0 && (
                            <Fragment>
                              {Object.keys(province_list).map(
                                (province_list_index) => {
                                  return (
                                    <option
                                      key={`province_list_id_${province_list_index}`}
                                      value={province_list_index}
                                    >
                                      {province_list[province_list_index]}
                                    </option>
                                  );
                                }
                              )}
                            </Fragment>
                          )}
                        </select>
                        <input
                          type="hidden"
                          id="province_text"
                          name="province_text"
                          ref={register()}
                        />
                        <input
                          type="hidden"
                          id="international_province"
                          name="international_province"
                          ref={register()}
                        />
                        {errors.province && (
                          <label className="text-danger">
                            * This field is required
                          </label>
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          name="city"
                          className="form-control"
                          id="city"
                          placeholder="City"
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.city && (
                          <label className="text-danger">
                            * This field is required
                          </label>
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          name="postal_code"
                          className="form-control"
                          id="postal_code"
                          placeholder="Postal Code / Zip"
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.postal_code && (
                          <label className="text-danger">
                            * This field is required
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <input
                          type="text"
                          name="telephone"
                          className="form-control"
                          id="telephone"
                          placeholder="Phone"
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.telephone && (
                          <label className="text-danger">
                            * This field is required
                          </label>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="text-center">
                          <input
                            className="btn btn-warning coyote-btn form-control"
                            type="submit"
                            name="submit"
                            disabled={is_submitted}
                            value={
                              is_submitted ? "Please Wait ..." : "Register"
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-end align-items-center">
                        <NavLink to="/auth/login">
                          Already have account? Please login!
                        </NavLink>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-1"></div>
              </div>
            )}
          </div>
          <br />
        </section>
      </Layout>
    </Suspense>
  );
};

export default withRouter(Register);
