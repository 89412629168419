import React, { Fragment, Suspense, useState, useEffect } from 'react';
import Parser from 'html-react-parser';
import { toast } from 'react-toastify';
import axios from 'axios';
import { APIS } from '../config/constant';
import { API_STATUS, FORMAT_FORM_DATA } from '../../../config/constant';
import { useForm } from "react-hook-form";

const ContactUsPage = (props) => {
    const [is_submitted, setIs_submitted] = useState(false);
    const { register, errors, handleSubmit, setValue, setError } = useForm();


    const submitForm = (formdata) => {
        setIs_submitted(true)
        formdata = FORMAT_FORM_DATA(formdata)
        axios.post(APIS.CONTACTSUBMIT, formdata)
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        toast.success(response.data.response.message)
                        setValue('name', '')
                        setValue('email', '')
                        setValue('message', '')
                    } else {
                        toast.error(response.data.response.message)
                        if (response.data.response.data && response.data.response.data.errors && Object.keys(response.data.response.data.errors) && Object.keys(response.data.response.data.errors).length > 0) {
                            Object.keys(response.data.response.data.errors).map((error_index) => {
                                let errors = '';
                                Object.keys(response.data.response.data.errors[error_index]).map((error_single_index) => {
                                    errors += response.data.response.data.errors[error_index][error_single_index] + ' ';
                                })
                                setError(error_index, {
                                    type: "manual",
                                    message: errors
                                });
                            })
                        }
                    }
                }
                setIs_submitted(false)
            });
    }

    return (
        <div className="contact-us row">
            <div className="col-md-7">
                <div className="row">
                    <div className="col-md-6 mood-img">
                        <img className="img-fluid" src={require('../../../assets/img/contact-page-img.jpg').default} alt="Mood Intro" />
                    </div>
                    <div className="col-md-6">
                        <div className="address-section">
                            <h3>Mood</h3>
                            <h6 className="address">
                                2515, 90 Ave SW, Calgary T2V 0L8
                            </h6>
                            <h6 className="phone">
                                <a href="tel:+14034758299" className="phone-number">+1 (403) 475-8299</a>
                            </h6>
                            <h6 className="email">
                                <a href="mailto:info@moodyyc.com">info@moodyyc.com</a>
                            </h6>
                            <h6 className="web-address"> 
                                <a href="http://www.moodyyc.com">www.moodyyc.com</a>
                            </h6>
                        </div>
                        <div className="office-time">
                            <h4>Hours</h4>
                            <h6>
                                <span>MON – FRI:</span> 9 am – 5 pm<br/>
                                <span>SAT:</span> 11 am – 3 pm<br/>
                                <span>SUN:</span> Closed<br/>
                                <span className="notice">We are closed from Sat – Mon for<br /> all long weekends.</span>
                            </h6>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="break-line"><span></span> </div>
            <div className="col-md-5">
                <form method="POST" onSubmit={handleSubmit(submitForm)}>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" className="form-control" id="name" placeholder="Name" ref={register({
                            required: true,
                            pattern: {
                                value: /^[a-zA-Z]+$/i,
                                message: "invalid name field"
                            }
                        })} />
                        {errors.name && <label className="text-danger">* This field is required</label>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="name">Email</label>
                        <input type="email" name="email" placeholder="Email" className="form-control" ref={register({
                            required: true,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address"
                            }
                        })} />
                        {errors.email && <label className="text-danger">* {errors.email.message ? errors.email.message : 'This field is required'}</label>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea className="form-control" name="message" id="message" rows="3" ref={register({
                            required: true
                        })}></textarea>
                        {errors.message && <label className="text-danger">* This field is required</label>}
                    </div>

                    <div className="text-center">
                        <input className="btn btn-warning coyote-btn form-control" type="submit" name="submit" disabled={is_submitted} value={is_submitted ? 'Please Wait ...' : 'Submit'} />
                    </div>
                </form>                
            </div>

            {/*
            <div className="col-md-6">
                {props.pageData && props.pageData.description && props.pageData.description != '' ? Parser(props.pageData.description) : <div className="text-center">No Data Found</div>}
            </div>
            <div className="col-md-6">
                <form method="POST" onSubmit={handleSubmit(submitForm)}>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" className="form-control" id="name" placeholder="Name" ref={register({
                            required: true,
                            pattern: {
                                value: /^[a-zA-Z]+$/i,
                                message: "invalid name field"
                            }
                        })} />
                        {errors.name && <label className="text-danger">* This field is required</label>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="name">Email</label>
                        <input type="email" name="email" placeholder="Email" className="form-control" ref={register({
                            required: true,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address"
                            }
                        })} />
                        {errors.email && <label className="text-danger">* {errors.email.message ? errors.email.message : 'This field is required'}</label>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea className="form-control" name="message" id="message" rows="3" ref={register({
                            required: true
                        })}></textarea>
                        {errors.message && <label className="text-danger">* This field is required</label>}
                    </div>

                    <div className="text-center">
                        <input className="btn btn-warning coyote-btn form-control" type="submit" name="submit" disabled={is_submitted} value={is_submitted ? 'Please Wait ...' : 'Submit'} />
                    </div>
                </form>
            </div>
            */}

        </div>
    )
}

export default ContactUsPage

