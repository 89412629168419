import React, { useRef, useEffect, useState, useContext } from 'react'
import CheckoutContext from '../contexts/CheckoutServiceProvider'
import { toast } from 'react-toastify';
import { PROCESS_CART_FOR_PAYPAL_PAYMENT } from '../../../config/constant';
import ReactImageFallback from 'react-image-fallback'

function PayPal(props) {
    const { setIs_submitted, placeOrder } = useContext(CheckoutContext);

    const paypal = useRef();

    useEffect(() => {
        const cart_data = PROCESS_CART_FOR_PAYPAL_PAYMENT();
        window.paypal.Buttons({
            style: {
                layout: 'vertical',
                color: 'gold',
                shape: 'rect',
                label: 'pay',
                tagline: false
            },
            createOrder: function (data, actions, err) {
                // console.log('data', data);
                // Set up the transaction
                return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units: cart_data
                });
            },
            onApprove: async (data, actions) => {
                setIs_submitted(true);
                const order = await actions.order.capture();
                console.log('order', order)
                placeOrder(order)
            },
            onCancel: function (data, actions) {
                setIs_submitted(false);
                // Show a cancel page or return to cart
                // console.log('onCancel data', data)
                // console.log('onCancel data', data.orderID)
                // console.log('onCancel actions', actions)
                toast.error("Transaction cancelled")
            },
            onError: (err) => {
                setIs_submitted(false);
                console.log('err', err)
            }
        }).render(paypal.current);

    }, [])

    return (
        <div>
            <div ref={paypal}></div>
        </div>
    )
}

export default PayPal
