import React, { Fragment, Suspense, useEffect, useState } from "react";
import { CART, GET_STORAGE, SET_STORAGE, SET_SESSION, GET_SESSION, QUANTITY_UPDATE } from "../../../config/constant";
import { CONFIG } from '../../../config/settings';
import ReactImageFallback from "react-image-fallback";
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { ManageCart, RemoveFromCart } from '../../../redux/actions/cart';
import { APIS } from '../config/constant';
import { API_STATUS, FORMAT_FORM_DATA } from '../../../config/constant';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';

const MyAccountInfo = (props) => {
    const [profileData, setProfileData] = useState({});
    const [is_submitted, setIs_submitted] = useState(false);
    const { register, errors, handleSubmit, setValue, setError } = useForm();

    useEffect(() => {
        setValue('first_name', props.profileData ? props.profileData.first_name : '')
        setValue('last_name', props.profileData ? props.profileData.last_name : '')
        setValue('email', props.profileData ? props.profileData.email : '')
    }, [props.profileData]);

    const submitForm = (formdata) => {
        setIs_submitted(true)
        axios.post(APIS.EIDTPROFILE, FORMAT_FORM_DATA(formdata))
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        toast.success(response.data.response.message)
                        props.setUpdateProfileData(!props.updateProfileData)
                    } else {
                        toast.error(response.data.response.message)
                        if (response.data.response.data && response.data.response.data.errors && Object.keys(response.data.response.data.errors) && Object.keys(response.data.response.data.errors).length > 0) {
                            Object.keys(response.data.response.data.errors).map((error_index) => {
                                let errors = '';
                                Object.keys(response.data.response.data.errors[error_index]).map((error_single_index) => {
                                    errors += response.data.response.data.errors[error_index][error_single_index] + ' ';
                                })
                                setError(error_index, {
                                    type: "manual",
                                    message: errors
                                });
                            })
                        }
                    }
                }
                setIs_submitted(false)
            });
    }

    return (
        <Fragment>
            <div className="account-info-form">
                <form method="POST" onSubmit={handleSubmit(submitForm)}>
                    <div className="form-group">
                        <label htmlFor="first_name">First Name</label>
                        <input type="text" name="first_name" className="form-control" id="first_name" placeholder="First Name" ref={register({
                            required: true
                        })} />
                        {errors.first_name && <label className="text-danger">* This field is required</label>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="last_name">Last Name</label>
                        <input type="text" name="last_name" className="form-control" id="last_name" placeholder="Last Name" ref={register({
                            required: true
                        })} />
                        {errors.last_name && <label className="text-danger">* This field is required</label>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="name">Email</label>
                        <input type="email" name="email" placeholder="Email" className="form-control" ref={register({
                            required: true,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address"
                            }
                        })} />
                        {errors.email && <label className="text-danger">* {errors.email.message ? errors.email.message : 'This field is required'}</label>}
                    </div>

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="text-center">
                                <input className="btn btn-warning coyote-btn form-control" type="submit" name="submit" disabled={is_submitted} value={is_submitted ? 'Please Wait ...' : 'Update'} />
                            </div>
                        </div>
                        <div className="col-md-4"></div>
                    </div>

                </form>
            </div>
        </Fragment>
    )
}

export default MyAccountInfo;