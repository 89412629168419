import React, {Fragment, Suspense, useEffect, useState} from "react";
import Layout from "../../../utils/templates/layout/default";
import Cart from "../component/cart"


const CartDetail = (props) => {

    const RedirectToCheckout = function (){

        props.history.push({
            pathname: "/check-out",
            state: {}
        })
    }

    return (
        <Suspense>
            <Layout>
                <Cart
                    action={RedirectToCheckout}
                    button={'Proceed Check Out'}
                />
            </Layout>
        </Suspense>
    )
}

export default CartDetail;