import { TYPES } from '../types/index';

export default (state = [{}], action = {}) => {
    switch (action.type) {
        case TYPES.UPDATE_WISHLIST:
            return [
                // ...state,
                action.payload
            ];
        case TYPES.REMOVE_FROM_WISHLIST:
            return [
                // ...state,
                action.payload
            ];
        default: return state;
    }
}