import React, {Suspense, useEffect} from "react";
import Layout from "../../../utils/templates/layout/default";
import Order from "../component/order"


const OrderDetails = (props) => {

    const redirectToDashboard = function (){
        props.history.push({
            pathname: "/myaccount",
            state: {}
        })
    }

    return (
        <Suspense>
            <Layout>
                <div className="search-wrapper">
                    <div className="row align-items-center justify-content-around">

                        <div className="col-8 shipping-info-section">
                            <div className="form-group shipping-info">
                                <div className="row">
                                    <h4> Order Details </h4>
                                </div>


                                <Order
                                    button={'Dashboard'}
                                    action={redirectToDashboard}
                                    shipmentInfo={props?.location.state.shipmentInfo ?? {}}
                                    cartProducts={props?.location.state.cartProducts ?? {}}
                                />

                            </div>
                        </div>

                        <div className="col-6 cart-section">

                        </div>

                    </div>
                </div>
            </Layout>
        </Suspense>
    )
}

export default OrderDetails;