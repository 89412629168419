import React, { Component, Suspense, useState, useEffect, Fragment } from "react";
import axios from "axios";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { CONFIG } from "../../../config/settings";
import ReactImageFallback from "react-image-fallback";
import { Helmet } from "react-helmet";
import Layout from "../../../utils/templates/layout/default";
import { APIS } from "../config/constant";
import { API_STATUS, GET_STORAGE, SET_STORAGE, TITLE, MAX_PRICE_RANGE, SETTINGS } from "../../../config/constant";
import CartOperations from "../../../utils/cart/cart";
import ProductListSingle from "../component/ProductListSingle";
import ProductCategoryList from "../component/ProductCategoryList";
import { toast } from "react-toastify";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import ContentLoader from "react-content-loader";
import NewsletterArea from "../component/NewsletterArea";

{/*import category_bg from "../../../src/assets/img/category-bg.jpg";*/ }

{/* From temporary Image get */}



const Products = (props) => {
  const [meta_title, setMeta_title] = useState(TITLE);
  const [meta_description, setMeta_description] = useState(TITLE);
  const [products, setProducts] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [categories, setCategories] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [activeCategories, setActiveCategories] = useState("");
  const [activeBrands, setActiveBrands] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [orderby, setOrderby] = useState("");
  const [product_limit, setProduct_limit] = useState(18);
  const [priceRange, setPriceRange] = useState({ min: 0, max: MAX_PRICE_RANGE });
  const [loading, setLoading] = useState(true);
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [brandLoading, setBrandLoading] = useState(true);
  const [show_category, setShow_category] = useState('off');
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const history = useHistory();
  const [clocation, setClocation] = useState(location.search);

  {/*test state for showing the category*/}
  {/*const [ has_category ] = useState('1');*/}

  useEffect(() => {
    let settings = GET_STORAGE(SETTINGS)
    if (settings) {
      settings = JSON.parse(settings)
      if (settings && settings.shopSettingData && settings.shopSettingData.product_options && settings.shopSettingData.product_options.show_category) {
        setShow_category(settings.shopSettingData.product_options.show_category);
      }
    }
  }, [])


  useEffect(() => {
    let settings = GET_STORAGE(SETTINGS)
    let show_product_category = "off";
    if (settings) {
      settings = JSON.parse(settings)
      if (settings && settings.shopSettingData && settings.shopSettingData.product_options && settings.shopSettingData.product_options.show_category) {
        show_product_category = settings.shopSettingData.product_options.show_category;
      }
    }

    document.querySelector("body").scrollIntoView();
    setLoading(true);
    let c_location = location.search;
    setClocation(c_location);
    const parsedQueryString = queryString.parse(c_location, { arrayFormat: "bracket" });
    if (c_location && parsedQueryString) {
      if (parsedQueryString.orderby) {
        setOrderby(parsedQueryString.orderby);
      }
      if (parsedQueryString.product_limit) {
        setProduct_limit(parsedQueryString.product_limit);
      }
      if (parsedQueryString.max_price) {
        setPriceRange({ min: parsedQueryString.min_price, max: parsedQueryString.max_price });
      }
      if (parsedQueryString.cat_id) {
        setActiveCategories(parsedQueryString.cat_id);
      }
      if (parsedQueryString.brand) {
        setActiveBrands(parsedQueryString.brand);
      }
    }
    axios.get((show_product_category == "off" ? APIS.PRODUCTS:APIS.CATEGORY_PRODUCTS) + c_location).then((response) => {
      if (response.data.status == API_STATUS.OK) {
        if (response.data.response.result) {
          setLoading(false);
          if(show_product_category == "off"){
            setProducts(response.data.response.data?response.data.response.data:[]);
          }else{
            setProducts(response.data.response.data.products?response.data.response.data.products:[]);
            setProductCategories(response.data.response.data.category_list ? response.data.response.data.category_list : []);
          }
          setPageCount(response.data.response.total_no_of_pages);
          setPageNo(response.data.response.page_no);
        } else {
          // toast.error(response.data.response.message)
          setLoading(false);
          setProducts([]);
          setProductCategories([]);
          setPageCount(0);
          setPageNo(1);
        }
      }
    });
  }, [props.match.params]);

  useEffect(() => {
    if (categories.length == 0) {
      axios.get(APIS.CATEGORIES).then((response) => {
        if (response.data.status == API_STATUS.OK) {
          if (response.data.response.result) {
            setCategories(response.data.response.data);
            setCategoryLoading(false);
          } else {
            // toast.error(response.data.response.message)
            setCategoryLoading(false);
          }
        }
      });
    }
    axios.get(APIS.BRANDS).then((response) => {
      if (response.data.status == API_STATUS.OK) {
        if (response.data.response.result) {
          setBrands(response.data.response.data);
          setBrandLoading(false);
        } else {
          // toast.error(response.data.response.message)
          setBrandLoading(false);
        }
      }
    });
    axios.get(APIS.PAGES + "?slug=products").then((response) => {
      if (response.data.status == API_STATUS.OK) {
        if (response.data.response.result) {
          if (response.data.response.data.others) {
            setMeta_title(response.data.response.data.others.meta_title);
            setMeta_description(response.data.response.data.others.meta_description);
          }
        }
      }
    });
  }, []);

  const CategoryLoop = ({ categories }) => {
    return (
      <Fragment>
        <ul>
          {categories.map((categories_single, categories_index) => {
            let c_location = location.search;
            let newurl = queryString.stringifyUrl(
              { url: c_location, query: { cat_id: categories_single.id } },
              { arrayFormat: "bracket" }
            );
            return (
              <Fragment key={`category-${categories_single.id}`}>
                <li>
                  <a onClick={(e) => handleCategoryClick(e, categories_single.id)} href={`/products${newurl}`}>
                    {categories_single.name}
                  </a>
                </li>
                { categories_single.children && categories_single.children.length > 0 ? (
                  <CategoryLoop categories={categories_single.children} />
                ) : (
                  ""
                ) }

              </Fragment>
            );
          })}
        </ul>
      </Fragment>
    );
  };

  const handleCategoryClick = (e, id) => {
    e.preventDefault();
    setPageNo(1);
    setPriceRange({ min: 0, max: MAX_PRICE_RANGE });
    setOrderby("");
    setProduct_limit(18);
    setActiveBrands([]);
    //history.push("/products?cat_id=" + id);
    window.location.href= CONFIG.BASE_URL + "/products?cat_id=" + id;
  };

  const handlePageClick = (data) => {
    let selected = parseInt(data.selected);
    let c_location = location.search;
    let newurl = queryString.stringifyUrl(
      { url: c_location, query: { page: selected + 1 } },
      { arrayFormat: "bracket" }
    );
    history.push("/products" + newurl);
  };

  const handlePriceRange = (value) => {
    setPriceRange(value);
    // let c_location = location.search;
    // let newurl = queryString.stringifyUrl({ url: c_location, query: { 'min_price': value.min, 'max_price': value.max } }, { arrayFormat: 'bracket' });
    // history.push('/products' + newurl)
  };

  const priceRangeFilter = (e) => {
    e.preventDefault();
    let c_location = location.search;
    let newurl = queryString.stringifyUrl(
      { url: c_location, query: { min_price: priceRange.min, max_price: priceRange.max } },
      { arrayFormat: "bracket" }
    );
    history.push("/products" + newurl);
  };

  const searchProductByText = (e) => {
    e.preventDefault();
    let c_location = location.search;
    let newurl = queryString.stringifyUrl(
      { url: c_location, query: { search_text: searchText } },
      { arrayFormat: "bracket" }
    );
    history.push("/products" + newurl);
  };

  const handlePriceOrder = (e) => {
    setOrderby(e.target.value);
    let c_location = location.search;
    let newurl = queryString.stringifyUrl(
      { url: c_location, query: { orderby: e.target.value } },
      { arrayFormat: "bracket" }
    );
    history.push("/products" + newurl);
  };

  const handleProductLimit = (e) => {
    setProduct_limit(e.target.value);
    let c_location = location.search;
    let newurl = queryString.stringifyUrl(
      { url: c_location, query: { product_limit: e.target.value } },
      { arrayFormat: "bracket" }
    );
    history.push("/products" + newurl);
  };

  const addToCart = function (e, products, totalPrice) {
    e.preventDefault();
    CartOperations.addToCart(products, 1, {}, totalPrice);
  };

  const removeFromCart = function (e, products) {
    e.preventDefault();
    CartOperations.removeFromCart(products);
  };

  return (
    <Suspense>
      <Layout>
        <section className="shop-category-area">
          <Helmet>
            <title>{meta_title}</title>
            <meta name="description" content={meta_description} />
            <link rel="canonical" href={`${CONFIG.BASE_URL}/products`} />
          </Helmet>
          <div className="container-fluid title-inner-card">
            <div className="all-page-title">
              <h2>Store </h2>
            </div>
          </div>
          {/* <div className="page-title">
                        <h2>Products</h2>
                    </div> */}
          <div className="container py-md-80 py-50">
            <div className="row">
              {/* <!-- sidebar area --> */}
              <div className="col-lg-3 order-md-first col-md-12 mb-md-60px mb-lm-60px sidebar_area d-none">
                <div className="mood-sidebar">
                  <div className="sidebar-title">Search</div>
                  <div className="search-element" style={{ position: "relative" }}>
                    <form className="d-flex" action="#">
                      <input
                        type="text"
                        name="search_text"
                        value={searchText}
                        placeholder="I am looking for ... "
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                      <button onClick={searchProductByText}>
                        <i className="fa fa-search"></i>
                      </button>
                    </form>
                  </div>

                  <div className="sidebar-title">Filter by price</div>
                  <div className="sidebar-widget">
                    <div className="price-filter">
                      <InputRange
                        formatLabel={(value) => ``}
                        step={1}
                        // classNames="PriceRange"
                        maxValue={MAX_PRICE_RANGE}
                        minValue={0}
                        value={priceRange}
                        onChange={(value) => handlePriceRange(value)}
                      />

                      {/* <div id="slider-range"
                                                className="ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content">
                                                <div className="ui-slider-range ui-corner-all ui-widget-header"
                                                    style={{ left: '0%', width: '81%' }}></div>
                                                <span tabIndex="0"
                                                    className="ui-slider-handle ui-corner-all ui-state-default"
                                                    style={{ left: '0%' }}></span>
                                                <span tabIndex="0"
                                                    className="ui-slider-handle ui-corner-all ui-state-default"
                                                    style={{ left: '81%' }}></span>
                                            </div> */}
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p>
                        price ${priceRange.min} - ${priceRange.max}
                      </p>
                      <a href="#" className="filter_btn" onClick={priceRangeFilter}>
                        Filter
                      </a>
                    </div>
                  </div>

                    {
                      show_category == "off" && 
                      <Fragment>
                        <div className="sidebar-title">Product Categories</div>
                        <div className="sidebar-categories">
                          {categoryLoading ? (
                            <div className="loading">
                              <ReactImageFallback
                                src={require("../../../assets/img/loading.gif").default}
                                fallbackImage={require("../../../assets/img/no-image.png").default}
                                initialImage={require("../../../assets/img/loading.gif").default}
                                alt="no image"
                                style={{ width: "30px" }}
                                className=""
                              />
                            </div>
                          ) : (
                            <Fragment>{categories.length > 0 ? <CategoryLoop categories={categories} /> : ""}</Fragment>
                          )}
                        </div>
                      </Fragment>
                    }
                  
                </div>
                {/* <div>
                                    <h2 className="" style={{ marginTop: '0px' }}>Search</h2>
                                    <div className="search-element" style={{ position: 'relative' }}>
                                        <form className="d-flex" action="#">
                                            <input type="text" name="search_text" value={searchText} placeholder="I am looking for ... " onChange={(e) => setSearchText(e.target.value)} />
                                            <button onClick={searchProductByText}><i className="fa fa-search"></i></button>
                                        </form>
                                    </div>
                                </div> */}

                {/* <div>
                                    <h2>Filter by price</h2>
                                    <div className="sidebar-widget">
                                        <div className="price-filter">
                                            <InputRange
                                                formatLabel={value => ``}
                                                step={1}
                                                // classNames="PriceRange"
                                                maxValue={MAX_PRICE_RANGE}
                                                minValue={0}
                                                value={priceRange}
                                                onChange={value => handlePriceRange(value)} />
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <p>price ${priceRange.min} - ${priceRange.max}</p>
                                            <a href="#" className="filter_btn" onClick={priceRangeFilter}>Filter</a>
                                        </div>
                                    </div>
                                </div> */}
                {/* <div>
                                    <h2>Product Categories</h2>
                                    <div>
                                        {
                                            categoryLoading ?
                                                <div className="loading">
                                                    <ReactImageFallback
                                                        src={require('../../../assets/img/loading.gif').default}
                                                        fallbackImage={require('../../../assets/img/no-image.png').default}
                                                        initialImage={require('../../../assets/img/loading.gif').default}
                                                        alt="no image"
                                                        style={{ width: '30px' }}
                                                        className="" />
                                                </div>
                                                :
                                                <Fragment>
                                                    {
                                                        categories.length > 0 ?
                                                            <CategoryLoop categories={categories} />
                                                            :
                                                            ''
                                                    }
                                                </Fragment>
                                        }

                                    </div>
                                </div> */}
              </div>
              {/* <!-- end sidebar area --> */}

              {/* start product list part */}
              <div className="col-lg-12 order-lg-last col-md-12 order-md-first">

                {/* Shop Top Area Start */}
                <div className="shop-top-bar">
                  {/* Right Side Start */}
                  <div className="select-shoing-wrap d-flex align-items-center float-right">
                    <div className="shop-select">
                      {/* <select className="shop-sort" style={{ display: 'none' }}> */}
                      <select
                        className="nice-select"
                        name="orderby"
                        id="sortPrice"
                        onChange={handlePriceOrder}
                        value={orderby}
                      >
                        <option value="">Product Sort by</option>
                        <option value="name_asc"> Name, A to Z </option>
                        <option value="name_desc"> Name, Z to A </option>
                        <option value="price_low"> Price, low to high </option>
                        <option value="price_high"> Price, high to low </option>
                      </select>
                      &nbsp;
                      <div className="sidebar_area" style={{ display: "inline-block" }}>
                        <div className="">
                          <div className="search-element" style={{ position: "relative" }}>
                            <form className="d-flex" action="#">
                              <input
                                type="text"
                                name="search_text"
                                value={searchText}
                                placeholder="Product Search"
                                onChange={(e) => setSearchText(e.target.value)}
                              />
                              <button onClick={searchProductByText}>
                                <i className="fa fa-search"></i>
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                  {/* Right Side end */}
                </div>
                {/* Shop Top Area End */}

                {/*Category List here*/}
                { show_category == "on" && productCategories.length > 0 &&
                  <div>
                    <ProductCategoryList categories={productCategories}/>
                  </div>
                }
                

                <div className="shop-bottom-area mt-35">
                  {/* start Shop bottom Area */}
                  <div className="tab-content jump">
                    <div id="shop-1" className="tab-pane active">
                      {loading ? (
                        <ContentLoader
                          speed={2}
                          width={"100%"}
                          height={"100%"}
                          viewBox="0 0 600 600"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#e8e3e3"
                          {...props}
                        >
                          <rect x="23" y="156" rx="2" ry="2" width="92" height="7" />
                          <rect x="47" y="172" rx="2" ry="2" width="44" height="6" />
                          <rect x="7" y="14" rx="2" ry="2" width="132" height="132" />
                          <rect x="170" y="158" rx="2" ry="2" width="92" height="7" />
                          <rect x="194" y="174" rx="2" ry="2" width="44" height="6" />
                          <rect x="154" y="16" rx="2" ry="2" width="132" height="132" />
                          <rect x="316" y="158" rx="2" ry="2" width="92" height="7" />
                          <rect x="340" y="174" rx="2" ry="2" width="44" height="6" />
                          <rect x="300" y="16" rx="2" ry="2" width="132" height="132" />
                          <rect x="463" y="160" rx="2" ry="2" width="92" height="7" />
                          <rect x="487" y="176" rx="2" ry="2" width="44" height="6" />
                          <rect x="447" y="18" rx="2" ry="2" width="132" height="132" />
                        </ContentLoader>
                      ) : (
                        // <div className="loading">
                        //     <ReactImageFallback
                        //         src={require('../../../assets/img/loading.gif').default}
                        //         fallbackImage={require('../../../assets/img/no-image.png').default}
                        //         initialImage={require('../../../assets/img/loading.gif').default}
                        //         alt="no image"
                        //         style={{ width: '30px' }}
                        //         className="" />
                        // </div>
                        <Fragment>
                          {!products || products.length == 0 ? (
                            <div className="no-data-found text-center">{ productCategories.length > 0 ? '' : 'No Data Found' } </div>
                          ) : (
                            <Fragment>
                              <div className="row responsive-md-class responsive-xl-class responsive-lg-class">
                                {products.map((products_single, products_single_index) => {
                                  return (
                                    <ProductListSingle
                                      productsSingle={products_single}
                                      key={products_single_index.uuid}
                                    />
                                  );
                                })}
                              </div>

                              <ReactPaginate
                                forcePage={pageNo - 1}
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                  {/* end Shop bottom Area */}
                </div>
              </div>
              {/* end product list part */}
            </div>
          </div>
        </section>

        {/* NewsLetter Section */}
        {/* <NewsletterArea /> */}
        {/* End news Letter Section */}
      </Layout>
    </Suspense>
  );
};

export default Products;
