import React, { Suspense, useState, useEffect, useReducer, useRef } from "react";
import { useStore, useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory, NavLink } from 'react-router-dom';
import axios from 'axios';

import Layout from '../../../utils/templates/layout/default';
import { APIS } from '../config/constant';
import { API_STATUS, FORMAT_FORM_DATA } from '../../../config/constant';
import { initUserAuthentication } from '../../../redux/actions/auth'
import ReactImageFallback from "react-image-fallback";
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";


const VerifyRegister = (props) => {
    const history = useHistory()
    // const store = useStore()
    const [loading, setLoading] = useState(true);
    const [is_submitted, setIs_submitted] = useState(false);
    const url = new URL(window.location.href);
    const redirect = url.searchParams.get('redirect');
    let redirect_url = false;
    if (redirect) {
        redirect_url = '/' + redirect;
    }

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const { register, errors, handleSubmit, watch, setValue } = useForm();
    const password = useRef({});
    password.current = watch("password", "");

    useEffect(() => {
        if (auth && auth.token) {
            toast.error('You are already logged in');
            history.push('/');
        }
        axios.post(APIS.REGISTERVERIFY, FORMAT_FORM_DATA({ 'token': props.match.params.token }))
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        toast.success(response.data.response.message)
                        history.push('/auth/login')
                    } else {
                        toast.error(response.data.response.message)
                        history.push('/')
                    }
                } else {
                    toast.error("Invalid token or token expired")
                    history.push('/')
                }
                setIs_submitted(false)
            });
    }, []);

    return (
        <Suspense>
            <Layout>
                <section className="login-page">
                    <div className="container">
                        <div className="all-page-title">
                            {/* <h2>Reset Password</h2> */}
                        </div>
                        {
                            loading ?
                                <div className="loading">
                                    <ReactImageFallback
                                        src={require('../../../assets/img/loading.gif').default}
                                        fallbackImage={require('../../../assets/img/no-image.png').default}
                                        initialImage={require('../../../assets/img/loading.gif').default}
                                        alt="no image"
                                        style={{ width: '30px' }}
                                        className="" />
                                </div>
                                :
                                <div className="row">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6 text-center">
                                        Verified
                                    </div>
                                    <div className="col-md-3"></div>
                                </div>
                        }
                    </div>
                    <br />
                </section>
            </Layout>
        </Suspense>
    )
};

export default withRouter(VerifyRegister);