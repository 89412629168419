import React, { Fragment, Suspense, useState, useEffect } from 'react';
import Layout from '../../../utils/templates/layout/default';
import ReactImageFallback from 'react-image-fallback'
import { useHistory } from 'react-router-dom'
import Parser from 'html-react-parser';
import { toast } from 'react-toastify';
import axios from 'axios';
import { APIS } from '../config/constant';
import { CONFIG } from '../../../config/settings';
import { API_STATUS, TITLE } from '../../../config/constant';
import PageNotFound from '../../../utils/common/PageNotFound'
import ContactUsPage from '../component/ContactUsPage'
import { Helmet } from "react-helmet";

import GalleryPhotos from '../component/GalleryPhotos';
import SimpleReactLightbox from 'simple-react-lightbox';

import Lightbox from 'react-image-lightbox';

const Gallery = (props) => {
    const [meta_title, setMeta_title] = useState(TITLE);
    const [meta_description, setMeta_description] = useState(TITLE);
    const [loading, setLoading] = useState(true);
    const [pageData, setPageData] = useState(false);
    const [bulk_photos, setBulkPhotos] = useState([]);
    const history = useHistory();




    useEffect(() => {
        setLoading(true)
        setPageData(false)
        axios.get(APIS.PAGES + '?slug=gallery')
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        setPageData(response.data.response.data)
                        setLoading(false)
                        //seo
                        if (response.data.response.data.others) {
                            setMeta_title(response.data.response.data.others.meta_title)
                            setMeta_description(response.data.response.data.others.meta_description)
                            if (response.data.response.data.others.bulk_photos) {
                                setBulkPhotos(response.data.response.data.others.bulk_photos)
                            }
                        }
                    } else {
                        setLoading(false)
                    }
                } else {
                    setLoading(false)
                }
            });
    }, [props.match.params])


    return (
        <Suspense>
            <Layout>
                <section className="pages-details">
                    <Helmet>
                        <title>{meta_title}</title>
                        <meta name="description" content={meta_description} />
                        <link rel="canonical" href={`${CONFIG.BASE_URL}/gallery`} />
                    </Helmet>

                    {
                        loading ?
                            <div className="loading">
                                <ReactImageFallback
                                    src={require('../../../assets/img/loading.gif').default}
                                    fallbackImage={require('../../../assets/img/no-image.png').default}
                                    initialImage={require('../../../assets/img/loading.gif').default}
                                    alt="no image"
                                    style={{ width: '30px' }}
                                    className="" />
                            </div>
                            :

                            <div className="">
                                <div className="container-fluid title-inner-card">
                                    <div className="all-page-title">
                                        <h2>Gallery</h2>
                                    </div>
                                </div>
                                <div className="py-md-80 py-50">
                                <SimpleReactLightbox>
                                    <GalleryPhotos bulk_photos={bulk_photos} />
                                </SimpleReactLightbox>
                                </div>
                            </div>
                    }
                </section>

            </Layout>

        </Suspense>

    )
}

export default Gallery

