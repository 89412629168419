let ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const PUBLIC_URL = process.env.PUBLIC_URL;
export const VERSION = "1.3.7";

// Tokens
const API_TOKEN_LIVE =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IkxJVkUi.FPCKiT3oLsG_LN03a4ea8ZGSO3Drxe6jK70skLWaddM";
const API_TOKEN_TEST =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IkxJVkUi.FPCKiT3oLsG_LN03a4ea8ZGSO3Drxe6jK70skLWaddM";

const production = {
  API_BASE_URL: "https://admin.moodyyc.com/api",
  API_TOKEN: API_TOKEN_LIVE,
  BASE_URL: "https://moodyyc.com",
  CUSTOMIZE_URL: "https://moodyyc.com/customize/",
  BASENAME: "/",
  IMAGE_BASE_URL: "https://admin.moodyyc.com",
};

const staging = {
  API_BASE_URL: "https://demo7.softalive.com/admin.moodyyc.com/src/api",
  API_TOKEN: API_TOKEN_TEST,
  BASE_URL: "https://demo7.softalive.com/moodyyc.com/demo/build",
  CUSTOMIZE_URL: "https://demo7.softalive.com/moodyyc.com/demo/build",
  BASENAME: "/moodyyc.com/demo/build/",
  IMAGE_BASE_URL: "https://demo7.softalive.com/admin.moodyyc.com/src",
};

const local = {
  API_BASE_URL: "http://localhost/applications/admin.moodyyc.com/src/api",
  API_TOKEN: API_TOKEN_TEST,
  BASE_URL: "http://localhost:3000",
  CUSTOMIZE_URL: "http://localhost/applications/moodyyc.com/demo/customize/",
  IMAGE_BASE_URL: "http://localhost/applications/admin.moodyyc.com/src",
};

export const CONFIG =
  ENVIRONMENT === "production"
    ? production
    : ENVIRONMENT === "staging"
    ? staging
    : local;
