import React, { Component, Suspense, useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import * as RBT from 'react-bootstrap';
import Layout from '../../../utils/templates/layout/default';
import { APIS } from '../config/constant';
import { API_STATUS } from '../../../config/constant';
import { logout } from '../../../redux/actions/auth'

import MyOrderList from '../component/MyOrderList';
import MyAccountInfo from '../component/MyAccountInfo';
import ChangePassword from '../component/ChangePassword';
import UpdateBillingAddress from '../component/UpdateBillingAddress';
import UpdateShippingAddress from '../component/UpdateShippingAddress';

const MyAccount = (props) => {
    const [pageTitle, setPageTitle] = useState('My Orders');
    const [profileData, setProfileData] = useState({});
    const [updateProfileData, setUpdateProfileData] = useState(true);
    const [countries, setCountries] = useState({});
    const [billingAddress, setBillingAddress] = useState([]);
    const [shippingAddress, setShippingAddress] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();

    const logout_user = (e) => {
        e.preventDefault();
        dispatch(logout())
        history.push('/auth/login')
    }

    useEffect(() => {
        axios.post(APIS.GETPROFILEINFO)
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        setProfileData(response.data.response.data)
                        if (response.data.response.data.customer) {
                            setBillingAddress(response.data.response.data.customer.billing_address)
                            setShippingAddress(response.data.response.data.customer.shipping_address)
                        }
                    }
                }
            });

        axios.get(APIS.COUNTRYLIST)
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        setCountries(response.data.response.data)
                    }
                }
            });
    }, [updateProfileData]);


    return (
        <Layout>
            <Suspense>
                <section className="myaccount">
                <div className="container-fluid title-inner-card">
                <div className="all-page-title">
                    <h2>{pageTitle}</h2>
                </div>
            </div>
                    <div className="container py-md-80 py-50">
                        <div className="row">
                            <div className="col-md-12">
                                <RBT.Tab.Container id="myaccount-nav" defaultActiveKey="my_orders">
                                    <RBT.Row>
                                        <RBT.Col sm={3} className="mood-sidebar p-0">
                                            <RBT.Nav variant="pills" className="flex-column myaccount-leftnav">
                                                <RBT.Nav.Item>
                                                    <RBT.Nav.Link onClick={() => setPageTitle('My Orders')} eventKey="my_orders">My Orders</RBT.Nav.Link>
                                                </RBT.Nav.Item>
                                                <RBT.Nav.Item>
                                                    <RBT.Nav.Link onClick={() => setPageTitle('My Account')} eventKey="account_info">Account Info</RBT.Nav.Link>
                                                </RBT.Nav.Item>
                                                <RBT.Nav.Item>
                                                    <RBT.Nav.Link onClick={() => setPageTitle('Change Password')} eventKey="change_password">Change Password</RBT.Nav.Link>
                                                </RBT.Nav.Item>
                                                <RBT.Nav.Item>
                                                    <RBT.Nav.Link onClick={() => setPageTitle('Billing Address')} eventKey="billing_address">Billing Address</RBT.Nav.Link>
                                                </RBT.Nav.Item>
                                                <RBT.Nav.Item>
                                                    <RBT.Nav.Link onClick={() => setPageTitle('Shipping Address')} eventKey="shipping_address">Shipping Address</RBT.Nav.Link>
                                                </RBT.Nav.Item>
                                                <RBT.Nav.Item>
                                                    <a href="#" className="nav-link" onClick={logout_user}> Logout</a>
                                                </RBT.Nav.Item>
                                            </RBT.Nav>
                                        </RBT.Col>
                                        <RBT.Col sm={9}>
                                            <RBT.Tab.Content>
                                                <RBT.Tab.Pane eventKey="my_orders">
                                                    <MyOrderList />
                                                </RBT.Tab.Pane>
                                                <RBT.Tab.Pane eventKey="account_info">
                                                    <MyAccountInfo profileData={profileData} updateProfileData={updateProfileData} setUpdateProfileData={setUpdateProfileData} />
                                                </RBT.Tab.Pane>
                                                <RBT.Tab.Pane eventKey="change_password">
                                                    <ChangePassword />
                                                </RBT.Tab.Pane>
                                                <RBT.Tab.Pane eventKey="billing_address">
                                                    <UpdateBillingAddress type="billing" address={billingAddress} countries={countries} updateProfileData={updateProfileData} setUpdateProfileData={setUpdateProfileData} />
                                                </RBT.Tab.Pane>
                                                <RBT.Tab.Pane eventKey="shipping_address">
                                                    <UpdateShippingAddress type="shipping" address={shippingAddress} countries={countries} updateProfileData={updateProfileData} setUpdateProfileData={setUpdateProfileData} />
                                                </RBT.Tab.Pane>
                                            </RBT.Tab.Content>
                                        </RBT.Col>
                                    </RBT.Row>
                                </RBT.Tab.Container>
                            </div>
                        </div>
                    </div>
                </section>
                <br />
                <br />
            </Suspense>
        </Layout>
    );

}

export default MyAccount