import React, { Fragment, Suspense, useState, useEffect } from "react";
import Layout from "../../../utils/templates/layout/default";
import ReactImageFallback from "react-image-fallback";
import { useHistory } from "react-router-dom";
import Parser from "html-react-parser";
import { toast } from "react-toastify";
import axios from "axios";
import { APIS } from "../config/constant";
import { CONFIG } from "../../../config/settings";
import { API_STATUS, TITLE } from "../../../config/constant";
import PageNotFound from "../../../utils/common/PageNotFound";
import ContactUsPage from "../component/ContactUsPage";
import { Helmet } from "react-helmet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

const about_slider_settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3500,
  speed: 1300,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
};

const AboutUs = (props) => {
  const [meta_title, setMeta_title] = useState(TITLE);
  const [meta_description, setMeta_description] = useState(TITLE);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoading(false);
    setPageData(false);
    axios.get(APIS.PAGES + "?slug=about-us").then((response) => {
      if (response.data.status == API_STATUS.OK) {
        if (response.data.response.result) {
          setPageData(response.data.response.data);
          setLoading(false);
          //seo
          if (response.data.response.data.others) {
            setMeta_title(response.data.response.data.others.meta_title);
            setMeta_description(
              response.data.response.data.others.meta_description
            );
          }
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });
  }, []);

  return (
    <Suspense>
      <Layout>
        <section className="pages-details">
          <Helmet>
            <title>{meta_title}</title>
            <meta name="description" content={meta_description} />
            <link rel="canonical" href={`${CONFIG.BASE_URL}/gallery`} />
          </Helmet>

          {loading ? (
            <div className="loading">
              <ReactImageFallback
                src={require("../../../assets/img/loading.gif").default}
                fallbackImage={
                  require("../../../assets/img/no-image.png").default
                }
                initialImage={
                  require("../../../assets/img/loading.gif").default
                }
                alt="no image"
                style={{ width: "30px" }}
                className=""
              />
            </div>
          ) : (
            <Fragment>
              <div className="container-fluid title-inner-card">
                <div className="all-page-title">
                  <h2>About Us</h2>
                </div>
              </div>
              {/* <div className="page-title">
                                    <h2>About Us</h2>
                                </div> */}

              <div className="container py-md-80 py-50 container-maxWidth">
                <div className="row">
                  <div className="col-lg-4 col-md-6 about-image-slider">
                    <div className="about-sliders">
                      {pageData.others &&
                      pageData.others.bulk_photos &&
                      pageData.others.bulk_photos.length > 0 ? (
                        <Slider {...about_slider_settings}>
                          {pageData.others.bulk_photos.map((photo, index) => (
                            <div key={index} className="">
                              <img src={photo} alt={`About Us ${index}`} />
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div
                    className="col-lg-8 col-md-6 about-page-text"
                    dangerouslySetInnerHTML={{
                      __html: pageData.description,
                    }}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </section>
      </Layout>
    </Suspense>
  );
};

export default AboutUs;
