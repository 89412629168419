import React, { Component } from 'react';
import Layout from './default';
import PageNotFound from '../../common/PageNotFound'

export default class Home extends Component {
    render() {
        return (
            <Layout>
                <PageNotFound />
            </Layout>
        );
    }
}