import React, { Fragment, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import * as RBT from "react-bootstrap";
import { useSelector } from "react-redux";
import { CONFIG } from "../../../config/settings";
import { GET_STORAGE, SETTINGS } from "../../../config/constant";

const Header = () => {
  const cart = useSelector((state) => state.cart[0]);
  // const wishlist = useSelector((state) => state.wishlist[0]);
  const [disable_shopping_cart, setDisable_shopping_cart] = useState(false);
  // const history = useHistory();
  // const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  // const logout_user = (e) => {
  //   e.preventDefault();
  //   dispatch(logout());
  //   history.push("/auth/login");
  // };

  useEffect(() => {
    const settings = GET_STORAGE(SETTINGS);
    if (settings) {
      const settingsParsed = JSON.parse(settings);
      if (
        settingsParsed.shopSettingData &&
        settingsParsed.shopSettingData.theme_options &&
        settingsParsed.shopSettingData.theme_options.disable_shopping_cart
      ) {
        setDisable_shopping_cart(true);
      }
    }
  }, []);

  // console.log(cart);
  let cartData = Object.values(cart);
  let totalQty = 0;
  cartData.forEach((c) => {
    totalQty += c.c_quantity;
  });

  return (
    <Fragment>
      <header className="top-header">
        <ul className="nav-social">
          <li>
            <a
              href="https://www.facebook.com/moodboutiqueyyc"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-facebook" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/moodboutiqueyyc"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/YYCMOOD"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-twitter" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
        <ul className="nav-icons userNav">
          {/* <li><form className="search-expand"><input type="search" placeholder="Search" /></form></li> */}
          {auth.token ? (
            // <li className="pr-50"><a href="#" onClick={logout_user}><img src={require('../../../assets/img/Icon3.png').default} /> Logout</a></li>
            <li className="auth-login-container">
              <NavLink to="/myaccount">
                <i className="fa fa-user" aria-hidden="true"></i>
                <span>My Account</span>
              </NavLink>
            </li>
          ) : (
            <li className="">
              <NavLink to="/auth/login">
                <span>Login</span>
              </NavLink>
            </li>
          )}

          {!disable_shopping_cart && (
            <li className="shopping-cart">
              <NavLink to="/cart">
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
              </NavLink>
              {cart ? <span>{totalQty}</span> : ""}
            </li>
          )}
        </ul>
      </header>
      <header className="header d-md-block">
        <RBT.Navbar expand="lg" className="mood-nav" variant="dark">
          <div className="mob-logo-container">
            <NavLink to="/" className="navbar-brand">
              <img
                alt="Logo"
                src={require("../../../assets/img/logo.png").default}
                id="navbar-logo"
              />
            </NavLink>
          </div>
          <RBT.Navbar.Toggle aria-controls="mainNavigation" />
          <RBT.Navbar.Collapse id="mainNavigation">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  to="/"
                  className="nav-link"
                  activeclassname="active"
                  exact
                >
                  Home <span className="sr-only">(current)</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/about-us"
                  className="nav-link"
                  activeclassname="active"
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  href={`${CONFIG.BASE_URL}/products`}
                  className="nav-link"
                  activeclassname="active"
                >
                  Store
                </a>
              </li>
              <li className="nav-item logo-nav-item">
                <div className="logo-container">
                  <NavLink to="/" className="navbar-brand">
                    <img
                      alt="Logo"
                      src={require("../../../assets/img/logo.png").default}
                      id="navbar-logo"
                    />
                  </NavLink>
                </div>
              </li>
              <li className="nav-item mood-dropdown">
                <NavLink
                  to="/services"
                  className="nav-link"
                  activeclassname="active"
                >
                  Services
                </NavLink>
                <ul className="dropdown-menu-container">
                  <li>
                    <NavLink to="/services/essential-hair-spa-beauty-salon">
                      Essential Hair and Spa Beauty Salon
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/services/mood-beauty-academy">
                      Mood Beauty Academy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/products">Mood Beauty Shop</NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/gallery"
                  className="nav-link"
                  activeclassname="active"
                >
                  Gallery
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/contact-us"
                  className="nav-link"
                  activeclassname="active"
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </RBT.Navbar.Collapse>
        </RBT.Navbar>

        {/* <nav className="navbar navbar-expand-lg mood-nav">

                    <a className="navbar-brand" href="index.html">
                        <img alt="Image placeholder" src={require('../../../assets/img/mood-logo.png').default} id="navbar-logo" />
                    </a>

                    <div>
                        <ul className="nav-icons mobile">
                            <li><form className="search-expand"><input type="search" placeholder="Search" /></form></li>
                            <li className="shopping-cart"><a href="#"><i className="fa fa-shopping-cart" aria-hidden="true"></i></a><span>2</span></li>
                        </ul>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">

                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link active" href="#">Home <span className="sr-only">(current)</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Products</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Services</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Contact</a>
                            </li>

                        </ul>
                        <div className="align-items-lg-center d-flex ml-auto icon-wrapper">
                            <ul className="nav-icons">
                                <li><form className="search-expand"><input type="search" placeholder="Search" /></form></li>
                                <li className="shopping-cart"><a href="#"><i className="fa fa-shopping-cart" aria-hidden="true"></i></a><span>2</span></li>
                            </ul>
                            <ul className="nav-social">
                                <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </nav> */}
      </header>
    </Fragment>
  );
};

export default Header;
