import React from 'react'

const PageNotFound = () => {
    return (
        <div>
            <section className="page-not-found">
                <div className="container">
                    <div className="all-page-title">
                        <h2>Page Not Found</h2>
                    </div>
                    <div className="row align-items-center justify-content-around">
                        <p className="text-center">The page you are requesting is under construction or not found. </p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PageNotFound
