import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

import GET_IN_TOUCH from "../../../assets/img/getintouch.png";

const Footer = () => {
  const [showGetInTouch, setShowGetInTouch] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      setShowGetInTouch(false);
    });
  }, []);

  const goToTop = () => {
    document.querySelector("body").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Fragment>
      <footer className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="footer-content">
                  <div className="contact-container">
                    <div className="address">
                      <i>
                        <img
                          alt=""
                          src={
                            require("../../../assets/img/footer-icon/location.png")
                              .default
                          }
                          id="navbar-logo"
                        />
                      </i>
                      <div>
                        <span> Essential Hair & Spa Salon</span>
                        2515, 90 Ave SW, Calgary T2V 0L8
                      </div>
                    </div>
                    <div className="address">
                      <i>
                        <img
                          alt=""
                          src={
                            require("../../../assets/img/footer-icon/phone.png")
                              .default
                          }
                          id="navbar-logo"
                        />
                      </i>
                      <div>+1 (403) 475 - 8299</div>
                    </div>
                    <div className="address">
                      <i>
                        <img
                          alt=""
                          src={
                            require("../../../assets/img/footer-icon/mail.png")
                              .default
                          }
                          id="navbar-logo"
                        />
                      </i>
                      <div>info@moodyyc.com</div>
                    </div>
                  </div>
                  <div className="logo-container">
                    <NavLink to="/" className="navbar-brand">
                      <img
                        alt="Image placeholder"
                        src={
                          require("../../../assets/img/footer-icon/logo.png")
                            .default
                        }
                        id="navbar-logo"
                      />
                    </NavLink>
                  </div>
                  <div className="office-time">
                    <div className="time">
                      <i>
                        <img
                          alt="Image placeholder"
                          src={
                            require("../../../assets/img/footer-icon/clock.png")
                              .default
                          }
                          id="navbar-logo"
                        />
                      </i>
                      <div>Tues - Fri: 10:00am - 5:00pm</div>
                    </div>
                    <div className="time">
                      <i>
                        <img
                          alt="Image placeholder"
                          src={
                            require("../../../assets/img/footer-icon/clock.png")
                              .default
                          }
                          id="navbar-logo"
                        />
                      </i>
                      <div>Saturday: 11:00am - 5:00pm</div>
                    </div>
                    <div className="time">
                      <i>
                        <img
                          alt="Image placeholder"
                          src={
                            require("../../../assets/img/footer-icon/clock.png")
                              .default
                          }
                          id="navbar-logo"
                        />
                      </i>
                      <div>Sun & Mon: Closed</div>
                    </div>
                    <div className="time">
                      <i>
                        <img
                          alt="Image placeholder"
                          src={
                            require("../../../assets/img/footer-icon/clock.png")
                              .default
                          }
                          id="navbar-logo"
                        />
                      </i>
                      <div>We are closed for all long weekends</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <ul className="nav-social">
                  <li>
                    <a
                      href="https://www.facebook.com/moodboutiqueyyc"
                      target="_blank"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/moodboutiqueyyc"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/YYCMOOD" target="_blank">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-top py-30 py-md-60  d-none">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12 text-left mt-20 mt-md-0 br-rt-2">
                <div className="footer-col-1 footer-logo-part-wrapper">
                  <div className="footer-logo-part ">
                    <div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/img/mood-logo-footer.png")
                            .default
                        }
                        alt="mood"
                      />
                    </div>
                    <div>
                      <ul className="nav-social">
                        <li>
                          <a
                            href="https://www.facebook.com/moodboutiqueyyc"
                            target="_blank"
                          >
                            <i
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/moodboutiqueyyc"
                            target="_blank"
                          >
                            <i
                              className="fa fa-instagram"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/YYCMOOD" target="_blank">
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p>
                    Mood Nails & Beauty Boutique is a full-service nail salon
                    and educational center committed to consistently providing
                    high customer satisfaction. We will grant excellent
                    services, quality products, and always ensure an enjoyable
                    atmosphere for an exceptional price-value relationship.
                  </p>
                </div>
              </div>

              <div className="col-lg-8 col-md-12 col-sm-12 widgets">
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-12 br-rt-2">
                    <div className="footer-info-wrapper">
                      <h3>Get In Touch</h3>
                      <p>2515, 90 Ave SW, Calgary T2V 0L8</p>
                      <p>
                        <span>Phone</span>+1 (403) 475-8299
                        <br />
                        <span>Email</span>
                        <a href="mailto:info@moodyyc.com" className="email">
                          info@moodyyc.com{" "}
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6 col-12 br-rt-2">
                    <div className="quick-links-wrapper">
                      <h3 className="">Products</h3>
                      <ul>
                        <li>
                          <NavLink to="/products">Albi</NavLink>
                        </li>
                        <li>
                          <NavLink to="/products">Chic</NavLink>
                        </li>
                        <li>
                          <NavLink to="/products">Staleks</NavLink>
                        </li>
                        <li>
                          <NavLink to="/products">Ulka</NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-5 col-12">
                    <div className="quick-links-wrapper">
                      <h3 className="">Services</h3>
                      <ul className="links-wrapper-2">
                        <li>
                          <NavLink to="/services" onClick={goToTop}>
                            Bride Services
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/services/Brow-Lamination"
                            onClick={goToTop}
                          >
                            Brow Lamination
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/services/Teaching-Center"
                            onClick={goToTop}
                          >
                            Teaching Center
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/services/Nail-Extension"
                            onClick={goToTop}
                          >
                            Nail Extension
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/services/Tinting" onClick={goToTop}>
                            Lash Tinting
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/services/Manicure" onClick={goToTop}>
                            Manicure
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/services/Pedicure" onClick={goToTop}>
                            Pedicure
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/services/Eyelash-Extension"
                            onClick={goToTop}
                          >
                            Eyelash Extension
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/services/Tinting" onClick={goToTop}>
                            Tinting
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/services/Waxing" onClick={goToTop}>
                            Waxing
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row d-sm-flex align-items-center justify-content-between">
              <div className="text-center text-md-left mb-10 mb-md-0 pl-15 pr-15 powered-by">
                Powered by{" "}
                <a href="https://www.instalogic.com/">Instalogic Inc.</a>
              </div>
              <div className="text-md-right text-center pl-15 pr-15 copy-right">
                &copy; 2023 MOOD Beauty Boutique
              </div>
            </div>
          </div>
        </div>

        <div className="totop">
          <ScrollToTop
            smooth
            color="#7d0633"
            component={
              <i className="fa fa-angle-double-up" aria-hidden="true"></i>
            }
          />
        </div>

        {/* Add Get in Touch Part */}

        <button
          className={`getinTouch-btn ${showGetInTouch ? "Vanish" : ""}`}
          onClick={() => setShowGetInTouch(true)}
        >
          <img src={GET_IN_TOUCH} alt="Logo" />
        </button>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className={`bottom-navigation ${
                  showGetInTouch ? "" : "Collapse"
                }`}
                id="breezetaskContainer"
              >
                <div id="scrollContainer" className="scroll-container">
                  <div className="icons-block" tabIndex="-1">
                    <span>Facebook</span>
                    <a
                      href="https://www.facebook.com/moodboutiqueyyc"
                      target="_blank"
                    >
                      <div className="icon-content" tabIndex="-1">
                        <div id="icon_phone" className="tactIcons_Icon__2q0_0">
                          <i className="fa fa-facebook"></i>
                        </div>
                        <div className="icons-reflection"></div>
                      </div>
                    </a>
                  </div>

                  <div className="icons-block" tabIndex="-1">
                    <span>Instagram</span>
                    <a
                      href="https://www.instagram.com/moodboutiqueyyc"
                      target="_blank"
                    >
                      <div className="icon-content" tabIndex="-1">
                        <div id="icon_phone" className="tactIcons_Icon__2q0_0">
                          <i className="fa fa-instagram"></i>
                        </div>
                        <div className="icons-reflection"></div>
                      </div>
                    </a>
                  </div>

                  <div className="icons-block" tabIndex="-1">
                    <span>Email</span>
                    <a href="mailto:info@moodyyc.com">
                      <div className="icon-content" tabIndex="-1">
                        <div id="icon_phone" className="tactIcons_Icon__2q0_0">
                          <i className="fa fa-envelope-o"></i>
                        </div>
                        <div className="icons-reflection"></div>
                      </div>
                    </a>
                  </div>

                  <div className="icons-block" tabIndex="-1">
                    <span>Call</span>
                    <a
                      href="tel:4034758299"
                      target="_self"
                      rel="noopener noreferrer"
                    >
                      <span className="number" style={{ display: "none" }}>
                        4034758299
                      </span>
                      <div className="icon-content" tabIndex="-1">
                        <div id="icon_phone" className="tactIcons_Icon__2q0_0">
                          <i className="fa fa-phone"></i>
                        </div>
                        <div className="icons-reflection"></div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div
                id="bmw_dockBase"
                className={`bottom-plate ${showGetInTouch ? "" : "Collapse"}`}
              ></div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
