import React, { Fragment, Suspense, useEffect, useState } from "react";
import { CART, GET_STORAGE, SET_STORAGE, SET_SESSION, GET_SESSION, QUANTITY_UPDATE } from "../../../config/constant";
import { CONFIG } from '../../../config/settings';
import ReactImageFallback from "react-image-fallback";
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { ManageCart, RemoveFromCart } from '../../../redux/actions/cart';
import { RemoveFromWishlist } from '../../../redux/actions/wishlist';

const WishlistSingle = (props) => {
    const cart = useSelector(state => state.cart[0]);
    const [quantity, setQuantity] = useState(1);
    const dispatch = useDispatch();

    useEffect(() => {
        setQuantity(props.wishlist_single ? props.wishlist_single.c_quantity : 1);
    }, [props]);

    const handleQuantity = (e, type) => {
        e.preventDefault();
        const current_qty = parseInt(quantity)
        if (type == 'plus') {
            const updated_qty = current_qty + 1;
            setQuantity(updated_qty)
        } else {
            if (current_qty > 1) {
                const updated_qty = current_qty - 1;
                setQuantity(updated_qty)
            }
        }
    }

    const handleRemove = (e, wishlist_single) => {
        if (window.confirm('Are you sure!')) {
            dispatch(RemoveFromWishlist(e, wishlist_single))
        }
    }

    const AddWishlistToCart = (e, wishlist_single, quantity) => {
        dispatch(ManageCart(e, wishlist_single, quantity))
        dispatch(RemoveFromWishlist(e, wishlist_single, false))
    }

    const updateQty = (e) => {
        const qty = e.target.value;
        setQuantity(qty)
    }

    const updateQtyOnBlur = (e) => {
        if (!quantity || parseInt(quantity) < 1) {
            setQuantity(1)
        }
    }

    return (
        <tr>
            <td>
                <NavLink to={`/products/${props.wishlist_single.slug}`} className="thumbnail">
                    <ReactImageFallback
                        src={`${CONFIG.API_BASE_URL}/../files/docs/Products/${props.wishlist_single.image}`}
                        fallbackImage={require('../../../assets/img/no-image.png').default}
                        initialImage={require('../../../assets/img/loading_img.png').default}
                        alt={props.wishlist_single.name}
                        style={{ width: '100px' }}
                        className="first-img" />
                </NavLink>
            </td>
            <td>
                <NavLink to={`/products/${props.wishlist_single.slug}`} className="thumbnail">{props.wishlist_single.name}</NavLink>
                {
                    props.wishlist_single.c_options && Object.keys(props.wishlist_single.c_options).length > 0 ?
                        <Fragment>
                            <br />
                            {
                                Object.keys(props.wishlist_single.c_options).map((c_options_single, index) => {
                                    return (
                                        <span className="cart-product-option"> {props.wishlist_single.c_options[c_options_single].option_value}</span>
                                    )
                                })
                            }
                        </Fragment>
                        : ''
                }
            </td>
            {/* <td>
                <button className="btn btn-warning coyote-btn wishlist-minus" onClick={(e) => handleQuantity(e, 'minus')}>-</button> &nbsp;
                <input type="number" name="quantity" min="1" max="1000000" onChange={updateQty} onBlur={updateQtyOnBlur} className="form-control text-center" style={{ width: '150px', display: 'inline-block' }} value={quantity} /> &nbsp;
                <button className="btn btn-warning coyote-btn wishlist-plus" onClick={(e) => handleQuantity(e, 'plus')}>+</button> &nbsp;
                {
                    cart && cart[props.wishlist_single.uuid] ?
                        <a className="btn btn-warning coyote-btn wishlist-plus" title="Remove From Cart" href="#" onClick={(e) => dispatch(RemoveFromCart(e, props.wishlist_single))}><i className="fa fa-shopping-cart"></i></a>
                        :
                        <a className="btn btn-warning coyote-btn wishlist-plus" title="Add To Cart" href="#" onClick={(e) => AddWishlistToCart(e, props.wishlist_single, quantity)}><i className="fa fa-shopping-cart"></i></a>
                }
            </td> */}
            <td>$ {parseFloat(parseFloat(props.wishlist_single.price_with_variation).toFixed(2) * parseInt(quantity ? quantity : 1)).toFixed(2)}</td>
            <td>
                {/* {
                    cart && cart[props.wishlist_single.uuid] ?
                        <a className="btn btn-warning" title="Remove From Cart" href="#" onClick={(e) => dispatch(RemoveFromCart(e, props.wishlist_single))}><i className="fa fa-shopping-cart"></i></a>
                        :
                        <a className="btn btn-success" title="Add To Cart" href="#" onClick={(e) => dispatch(ManageCart(e, props.wishlist_single, quantity))}><i className="fa fa-shopping-cart"></i></a>
                }
                &nbsp; */}

                {
                    cart && cart[props.wishlist_single.uuid] ?
                        <a className="btn btn-warning coyote-btn add-to-cart" title="Remove From Cart" href="#" onClick={(e) => dispatch(RemoveFromCart(e, props.wishlist_single))}><i className="fa fa-shopping-cart"></i></a>
                        :
                        // <a className="btn btn-success" title="Add To Cart" href="#" onClick={(e) => dispatch(ManageCart(e, props.wishlist_single, quantity))}><i className="fa fa-shopping-cart"></i></a>
                        <a className="btn btn-warning coyote-btn add-to-cart" title="Add To Cart" href="#" onClick={(e) => AddWishlistToCart(e, props.wishlist_single, quantity)}><i className="fa fa-shopping-cart"></i></a>
                }
                &nbsp;
                <button className="btn btn-danger" title="Remove From Wishlist" onClick={(e) => handleRemove(e, props.wishlist_single)}><i className="fa fa-remove"></i></button>
            </td>
        </tr>
    )
}

export default WishlistSingle;