export const APIS = {
    LOGIN: '/login',
    REGISTER: '/register',
    REGISTERVERIFY: '/register-verify',
    COUNTRYLIST: '/countries',
    PROVINCELIST: '/states',
    FORGOTPASSWORD: '/forgot-password',
    FORGOTPASSWORDVERIFY: '/reset-password/token-verify',
    RESETPASSWORD: '/reset-password',
    PAGES: '/page-content',
}