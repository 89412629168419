import React, { Fragment, Suspense, useEffect, useState } from "react";
import { CART, GET_STORAGE } from "../../../config/constant";
import { CONFIG } from "../../../config/settings";
import ReactImageFallback from "react-image-fallback";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ManageCart, RemoveFromCart } from "../../../redux/actions/cart";
import {
  AddToWishlist,
  RemoveFromWishlist,
} from "../../../redux/actions/wishlist";

const ProductListSingle = (props) => {
  const [productsSingle, setProductsSingle] = useState({});
  // const [totalOrderPrice, setTotalOrderPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0.0);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart[0]);
  const wishlist = useSelector((state) => state.wishlist[0]);

  useEffect(() => {
    setProductsSingle(props.productsSingle);
    if (auth && auth.user && auth.user.type == "WH") {
      setTotalPrice(
        props.productsSingle.wholesale_price
          ? parseFloat(props.productsSingle.wholesale_price).toFixed(2)
          : props.productsSingle.price
          ? parseFloat(props.productsSingle.price).toFixed(2)
          : 0.0
      );
    } else {
      setTotalPrice(
        props.productsSingle.price
          ? parseFloat(props.productsSingle.price).toFixed(2)
          : 0.0
      );
    }
  }, [props]);

  // const calculateAndUpdateTotalPrice = function () {
  //     var total = 0;
  //     Object.keys(props.cartProducts).map((index) => {
  //         total += props.cartProducts[index].total_price;
  //     });
  //     setTotalOrderPrice(total + props.shipmentInfo?.shippingCharge + props.shipmentInfo?.tax);
  //     setTotalPrice(total)
  // }

  const others_data = JSON.parse(props.productsSingle.others);

  const feature_image =
    others_data &&
    others_data.feature_image_full_url &&
    others_data.feature_image_full_url.medium_img
      ? others_data.feature_image_full_url.medium_img
      : require("../../../assets/img/no-image.png").default;

  const second_img =
    others_data &&
    others_data.other_picture_full_url &&
    others_data.other_picture_full_url.length > 0
      ? others_data.other_picture_full_url[0].medium_img
      : feature_image;

  let single_price = props.productsSingle.price
    ? parseFloat(props.productsSingle.price).toFixed(2)
    : 0.0;

  let salePrice = props.productsSingle.sale_price
    ? parseFloat(props.productsSingle.sale_price).toFixed(2)
    : 0.0;

  // if (auth && auth.user && auth.user.type == "WH") {
  //     single_price = props.productsSingle.wholesale_price ? parseFloat(props.productsSingle.wholesale_price).toFixed(2) : 0.00;
  // } else {
  //     single_price = props.productsSingle.price ? parseFloat(props.productsSingle.price).toFixed(2) : 0.00;
  // }

  return (
    <div className="col-xl-3 col-md-4 col-sm-12 clos_gap">
      <article className="list-product">
        <div className="img-block  position-relative me-5">
          {props.productsSingle.new_arrival == 1 ? (
            <span className="position-absolute tag-new">
              <img
                src={require("../../../assets/img/Sticker_new.png").default}
              />
            </span>
          ) : (
            ""
          )}
          <NavLink
            to={`/products/${props.productsSingle.slug}`}
            className="thumbnail"
          >
            <ReactImageFallback
              src={`${feature_image}`}
              fallbackImage={
                require("../../../assets/img/no-image.png").default
              }
              initialImage={
                require("../../../assets/img/loading_img.png").default
              }
              alt={props.productsSingle.name}
              // style={{ width: '100%' }}
              className="first-img"
            />

            {/* <ReactImageFallback
                            src={`${second_img}`}
                            fallbackImage={require('../../../assets/img/no-image.png').default}
                            initialImage={require('../../../assets/img/loading_img.png').default}
                            alt={props.productsSingle.name}
                            style={{ width: '100%' }}
                            className="second-img" /> */}
          </NavLink>
          {props.productsSingle.stock_status == 0 ? (
            <span className="soldout-Tag">Sold out</span>
          ) : (
            ""
          )}
        </div>
        {/* <ul className="product-flag">
                    <li className="new">New</li>
                </ul> */}
        <div className="product-decs">
          <NavLink
            to={`/products/${props.productsSingle.slug}`}
            className="inner-link"
          >
            <span>{props.productsSingle.name}</span>
          </NavLink>

          {/* <h2><NavLink to={`/products/${props.productsSingle.slug}`} className="product-link">
                                                                                        <span>{props.productsSingle.name}</span>
                                                                                    </NavLink></h2> */}
          <div className="pricing-meta">
            <ul>
              {salePrice > 0 ? (
                <>
                  <li className="old-price off-price">CA$ {totalPrice}</li>
                  <li className="current-price">CA$ {salePrice}</li>
                </>
              ) : (
                <li className="current-price">CA$ {totalPrice}</li>
              )}
              {/* <li className="discount-price"> -5%</li> */}
            </ul>
          </div>
        </div>

        {/* <div className="add-to-link">
                    <ul>
                        <li className="cart">
                            <NavLink to={`/products/${props.productsSingle.slug}`} className="cart-btn">
                                View
                            </NavLink>
                        </li>
                        {
                            props.productsSingle.base_product_id ?
                                <li className="cart">
                                    <a className="cart-btn" href={`${CONFIG.CUSTOMIZE_URL}editor.php?product_base=${props.productsSingle.base_product_id}&ref=${window.location.href}`}>Customize</a>
                                </li>
                                : ''
                        }
                    </ul>
                </div> */}

        {/* <div className="add-to-link">
                    <ul>
                        <li className="cart">
                            {
                                cart && cart[props.productsSingle.uuid] ?
                                    <a className="cart-btn active" href="#" onClick={(e) => dispatch(RemoveFromCart(e, props.productsSingle))}>Remove</a>
                                    :
                                    <a className="cart-btn" href="#" onClick={(e) => dispatch(ManageCart(e, props.productsSingle))}>Add To Cart</a>
                            }
                        </li>
                        <li className="cart">
                            {
                                wishlist && wishlist[props.productsSingle.uuid] ?
                                    <a className="cart-btn active" href="#" onClick={(e) => dispatch(RemoveFromWishlist(e, props.productsSingle))}><i className="fa fa-heart-o"></i></a>
                                    :
                                    <a className="cart-btn" href="#" onClick={(e) => dispatch(AddToWishlist(e, props.productsSingle))}><i className="fa fa-heart-o"></i></a>
                            }

                        </li>
                    </ul>
                </div> */}
      </article>
    </div>
  );
};

export default ProductListSingle;
