import React, { useState, useEffect, useMemo } from "react";
import PageInnerContent from "../component/PageInnerContent";
import ServiceCategoryContent from "../component/ServiceCategoryContent";

const PagesComponent = (props) => {
  return (
    <>
      <PageInnerContent matchParams={props.match.params} />
    </>
  );
};

export default PagesComponent;
