import React, { Fragment, Suspense, useEffect, useState } from "react";
import { CART, GET_STORAGE, SET_STORAGE, SET_SESSION, GET_SESSION, QUANTITY_UPDATE } from "../../../config/constant";
import { CONFIG } from '../../../config/settings';
import ReactImageFallback from "react-image-fallback";
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { ManageCart, RemoveFromCart } from '../../../redux/actions/cart';
import axios from 'axios';
import moment from 'moment';
import { APIS } from '../config/constant';
import { API_STATUS, FIND_ORDER_STATUS } from '../../../config/constant';

const MyOrderList = (props) => {
    const [loading, setLoading] = useState(true);
    const [orderList, setOrderList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        axios.post(APIS.ORDERLIST)
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        setOrderList(response.data.response.data)
                    }
                }
                setLoading(false)
            });
    }, []);

    return (
        <Fragment>
{/*             
            <div className="all-page-title">
                <h2>My Orders</h2>
            </div> */}
            <div>
                {
                    loading ?
                        <div className="loading">
                            <ReactImageFallback
                                src={require('../../../assets/img/loading.gif').default}
                                fallbackImage={require('../../../assets/img/no-image.png').default}
                                initialImage={require('../../../assets/img/loading.gif').default}
                                alt="no image"
                                style={{ width: '30px' }}
                                className="" />
                        </div>
                        :
                        <table className="table product-table">
                            <thead>
                                <tr>
                                    <th scope="col">Order#</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Transaction ID</th>
                                    <th scope="col">Order Status</th>
                                    <th scope="col">Order Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orderList.length > 0 ?
                                        <Fragment>
                                            {
                                                orderList.map((orderList_single, orderList_index) => {
                                                    return (
                                                        <tr key={orderList_single.order_number}>
                                                            <td><NavLink to={`/myaccount/order/${orderList_single.id}`} target="_blank">{orderList_single.order_number}</NavLink></td>
                                                            <td>{moment(orderList_single.created_at).format('MMMM Do, YYYY, h:mm a')}</td>
                                                            <td>{orderList_single.transaction_id}</td>
                                                            <td><span className={`badge badge-${FIND_ORDER_STATUS(orderList_single.status).class}`}>{FIND_ORDER_STATUS(orderList_single.status).name}</span></td>
                                                            <td>CA$ {parseFloat(orderList_single.amount).toFixed(2)}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </Fragment>
                                        : <tr><td className="text-center" colSpan="5"> No order Found</td></tr>
                                }
                            </tbody>
                        </table>
                }
            </div>
        </Fragment>
    )
}

export default MyOrderList;