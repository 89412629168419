import Home from "../pages/home";
import Products from "../pages/products";
import ProductsDetails from "../pages/productDetails";
import AboutUs from "../pages/AboutUs";
import Services from "../pages/Services";
import Gallery from "../pages/Gallery";
import ServicesDetails from "../pages/ServicesDetails";
import Cart from "../pages/cart";
import CheckOut from "../pages/check_out";
import CartPage from "../pages/CartPage";
import Wishlist from "../pages/Wishlist";
import CheckoutPage from "../pages/CheckoutPage";
import OrderDetails from "../pages/order_details";
import PagesComponent from "../pages/PagesComponent";
import ProcessCart from "../pages/ProcessCart";

const routes = [
  //route props
  { path: "", component: Home },
  { path: "about-us", component: AboutUs },
  { path: "products", component: Products },
  { path: "products/:slug", component: ProductsDetails },
  { path: "services", component: Services },
  { path: "gallery", component: Gallery },
  { path: "services/:slug", component: ServicesDetails },
  { path: "view-cart", component: Cart },
  { path: "check-out", component: CheckOut, isProtected: true },
  { path: "cart", component: CartPage },
  { path: "wishlist", component: Wishlist },
  { path: "checkout", component: CheckoutPage },
  { path: "order-details", component: OrderDetails, isProtected: true },
  { path: "process-cart", component: ProcessCart },
  { path: ":slug", component: PagesComponent },
];

export default routes;
