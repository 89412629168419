import React, { Fragment, Suspense, useEffect, useState } from "react";
import { APIS } from '../config/constant';
import { API_STATUS, FORMAT_FORM_DATA } from '../../../config/constant';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import ReactImageFallback from "react-image-fallback";

const UpdateShippingAddress = (props) => {
    const [mounted, setMounted] = useState(true);
    const [is_submitted, setIs_submitted] = useState(false);
    const [province_list, setProvince_list] = useState({});
    const [loading, setLoading] = useState(true);
    const { register, errors, handleSubmit, setValue, setError } = useForm();

    useEffect(() => {
        setMounted(!mounted)
    }, [])

    useEffect(() => {
        if (props.address.country) {
            setValue('country', props.address.country)
            getProvinceData(props.address.country, props.address.province)
        } else {
            setLoading(false)
        }

        if (props.address.country_text) {
            setValue('country_text', props.address.country_text)
        }
        if (props.address.province_text) {
            setValue('province_text', props.address.province_text)
        }
        if (props.address.international_province) {
            setValue('international_province', props.address.international_province)
        }

        // setValue('city', '')
        // setValue('postcode', '')
        // setValue('country_text', country_text)
        // setValue('province_text', '')
        // setValue('international_province', '')
    }, [props])

    const getProvinceData = (country_id, default_value = false) => {
        axios.get(APIS.PROVINCELIST + '/' + country_id)
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        setProvince_list(response.data.response.data)
                        if (default_value) {
                            setTimeout(() => {
                                setValue('province', default_value)
                            }, 500)
                        }
                        setLoading(false)
                    }
                }
            });
    }

    const updateProvince = (e) => {
        const country_text = e.target.options[e.target.selectedIndex].text
        setProvince_list({})
        setValue('city', '')
        setValue('postal_code', '')
        setValue('country_text', country_text)
        setValue('province_text', '')
        setValue('international_province', '')
        console.log(e.target.value)
        getProvinceData(e.target.value);
    }



    const provinceHandler = (e) => {
        const province_text = e.target.options[e.target.selectedIndex].text
        setValue('province_text', province_text)
        setValue('international_province', province_text)
    }


    const submitForm = (formdata) => {
        setIs_submitted(true)
        axios.post(APIS.UPDATEADDRESSES, FORMAT_FORM_DATA(formdata))
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        toast.success(response.data.response.message)
                        props.setUpdateProfileData(!props.updateProfileData)
                    } else {
                        toast.error(response.data.response.message)
                        if (response.data.response.data && response.data.response.data.errors && Object.keys(response.data.response.data.errors) && Object.keys(response.data.response.data.errors).length > 0) {
                            Object.keys(response.data.response.data.errors).map((error_index) => {
                                let errors = '';
                                Object.keys(response.data.response.data.errors[error_index]).map((error_single_index) => {
                                    errors += response.data.response.data.errors[error_index][error_single_index] + ' ';
                                })
                                setError(error_index, {
                                    type: "manual",
                                    message: errors
                                });
                            })
                        }
                    }
                }
                setIs_submitted(false)
            });
    }

    return (
        <Fragment>
            
            <div className="account-info-form">
            <div>
                <h4 className="title34">{props.type == 'billing' ? 'Billing' : 'Shipping'}  Address</h4>
            </div>
                {
                    loading ?
                        <div className="loading">
                            <ReactImageFallback
                                src={require('../../../assets/img/loading.gif').default}
                                fallbackImage={require('../../../assets/img/no-image.png').default}
                                initialImage={require('../../../assets/img/loading.gif').default}
                                alt="no image"
                                style={{ width: '30px' }}
                                className="" />
                        </div>
                        :
                        <form method="POST" onSubmit={handleSubmit(submitForm)}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <input type="text" name="first_name" className="form-control" id="first_name" placeholder="First Name" ref={register({
                                        required: true
                                    })} defaultValue={props.address.first_name ? props.address.first_name : ''} />
                                    {errors.first_name && <label className="text-danger">* This field is required</label>}
                                </div>
                                <div className="form-group col-md-6">
                                    <input type="text" name="last_name" className="form-control" id="last_name" placeholder="Last Name" ref={register({
                                        required: true
                                    })} defaultValue={props.address.last_name ? props.address.last_name : ''} />
                                    {errors.last_name && <label className="text-danger">* This field is required</label>}
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="email" name="email" placeholder="Email" className="form-control" ref={register({
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "invalid email address"
                                    }
                                })} defaultValue={props.address.email ? props.address.email : ''} />
                                {errors.email && <label className="text-danger">* {errors.email.message ? errors.email.message : 'This field is required'}</label>}
                            </div>
                            <div className="form-group">
                                <input type="text" name="address1" className="form-control" id="address1" placeholder="Address 1" ref={register({
                                    required: true
                                })} defaultValue={props.address.address1 ? props.address.address1 : ''} />
                                {errors.address1 && <label className="text-danger">* This field is required</label>}
                            </div>
                            <div className="form-group">
                                <input type="text" name="address2" className="form-control" id="address2" placeholder="Address 2" ref={register()} defaultValue={props.address.address2 ? props.address.address2 : ''} />
                                {errors.address2 && <label className="text-danger">* This field is required</label>}
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <select id="country" className="form-control" name="country" ref={register({
                                        required: true
                                    })} onChange={updateProvince}>
                                        <option value=''>Country</option>
                                        {
                                            Object.keys(props.countries).length > 0 &&
                                            <Fragment>
                                                {
                                                    Object.keys(props.countries).map((countries_index) => {
                                                        return (<option key={`country_id_${countries_index}`} value={countries_index}>{props.countries[countries_index]}</option>)
                                                    })
                                                }
                                            </Fragment>
                                        }
                                    </select>
                                    <input type="hidden" id="address_type" name="address_type" value="shipping_address" ref={register({ required: true })} />
                                    <input type="hidden" id="country_text" name="country_text" ref={register()} />
                                    {errors.country && <label className="text-danger">* This field is required</label>}
                                </div>
                                <div className="form-group col-md-6">
                                    <select id="province" className="form-control" name="province" ref={register({
                                        required: true
                                    })} onChange={provinceHandler}>
                                        <option value=''>State/Province</option>
                                        {
                                            Object.keys(province_list).length > 0 &&
                                            <Fragment>
                                                {
                                                    Object.keys(province_list).map((province_list_index) => {
                                                        return (<option key={`province_list_id_${province_list_index}`} value={province_list_index}>{province_list[province_list_index]}</option>)
                                                    })
                                                }
                                            </Fragment>
                                        }
                                    </select>
                                    <input type="hidden" id="province_text" name="province_text" ref={register()} />
                                    <input type="hidden" id="international_province" name="international_province" ref={register()} />
                                    {errors.province && <label className="text-danger">* This field is required</label>}
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <input type="text" name="city" className="form-control" id="city" placeholder="City" ref={register({
                                        required: true
                                    })} defaultValue={props.address.city ? props.address.city : ''} />
                                    {errors.city && <label className="text-danger">* This field is required</label>}
                                </div>
                                <div className="form-group col-md-4">
                                    <input type="text" name="postal_code" className="form-control" id="postal_code" placeholder="Postal Code / Zip" ref={register({
                                        required: true
                                    })} defaultValue={props.address.postal_code ? props.address.postal_code : ''} />
                                    {errors.postal_code && <label className="text-danger">* This field is required</label>}
                                </div>
                                <div className="form-group col-md-4">
                                    <input type="text" name="telephone" className="form-control" id="telephone" placeholder="Phone" ref={register({
                                        required: true
                                    })} defaultValue={props.address.telephone ? props.address.telephone : ''} />
                                    {errors.telephone && <label className="text-danger">* This field is required</label>}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    <div className="text-center">
                                        <input className="btn btn-warning coyote-btn form-control" type="submit" name="submit" disabled={is_submitted} defaultValue={is_submitted ? 'Please Wait ...' : 'Update'} />
                                    </div>
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                        </form>
                }
            </div>
        </Fragment>
    )
}

export default UpdateShippingAddress;