import React, { useState, Fragment } from 'react'
import * as RBT from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { APIS } from '../config/constant';
import { API_STATUS, FORMAT_FORM_DATA } from '../../../config/constant';
import { useForm } from "react-hook-form";

function NewsletterArea(props) {
    const [modalShow, setModalShow] = useState(false);
    const [is_submitted, setIs_submitted] = useState(false);
    const { register, errors, handleSubmit, setValue, setError } = useForm();

    const submitForm = (formdata) => {
        formdata.is_subscriptions = 1;
        formdata = FORMAT_FORM_DATA(formdata);
        setIs_submitted(true)
        axios.post(APIS.CONTACTSUBMIT, formdata)
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        setModalShow(false)
                        toast.success(response.data.response.message)
                        setValue('name', '')
                        setValue('email', '')
                    } else {
                        toast.error(response.data.response.message)
                        if (response.data.response.data && response.data.response.data.errors && Object.keys(response.data.response.data.errors) && Object.keys(response.data.response.data.errors).length > 0) {
                            Object.keys(response.data.response.data.errors).map((error_index) => {
                                let errors = '';
                                Object.keys(response.data.response.data.errors[error_index]).map((error_single_index) => {
                                    errors += response.data.response.data.errors[error_index][error_single_index] + ' ';
                                })
                                setError(error_index, {
                                    type: "manual",
                                    message: errors
                                });
                            })
                        }
                    }
                } else {
                    setModalShow(false)
                    toast.error(response.data.response && response.data.response.message ? response.data.response.message : "Unknown Error Occured")
                }
                setIs_submitted(false)
            });
    }

    return (
        <Fragment>
            <section className="newsletter-area">
                <div className="container position-relative">
                    <div className="row newsletter-wrap justify-content-between align-items-center">
                        <div className="newsletter-title">Newsletter</div>
                        <div className="newsletter-text">
                            Stay connected! Subscribe to our newsletter to stay up to date with our latest products and designs.
                        </div>
                        <div className="signup-btn-box">
                            <button className="btn-signup" onClick={() => setModalShow(true)}>Sign Up</button>
                        </div>
                    </div>
                </div>
            </section>

            <RBT.Modal
                show={modalShow}
                size="md"
                aria-labelledby="newsletter_modal"
                centered
            >
                <form method="POST" onSubmit={handleSubmit(submitForm)}>
                    <RBT.Modal.Header className="custom_modal_centered_elm">
                        <RBT.Modal.Title id="newsletter_modal">
                            <img alt="Image placeholder" src={require('../../../assets/img/mood-logo.png').default} />
                        </RBT.Modal.Title>
                    </RBT.Modal.Header>
                    <RBT.Modal.Body>
                        <div className="container">
                            <div className="form-group row">
                                <label for="name" className="col-sm-2 col-form-label">Name</label>
                                <div className="col-sm-10">
                                    <input type="text" name="name" className="form-control" id="name" ref={register({
                                        required: true
                                    })} />
                                    {errors.name && <label className="text-danger">* This field is required</label>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="email" className="col-sm-2 col-form-label">Email</label>
                                <div className="col-sm-10">
                                    <input type="email" name="email" className="form-control" id="email" ref={register({
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "invalid email address"
                                        }
                                    })} />
                                    {errors.email && <label className="text-danger">* {errors.email.message ? errors.email.message : 'This field is required'}</label>}
                                </div>
                            </div>
                        </div>
                    </RBT.Modal.Body>
                    <RBT.Modal.Footer className="custom_modal_centered_elm">
                        <button type="button" className="btn btn-warning coyote-btn" onClick={() => setModalShow(false)}>Close</button>
                        <button type="submit" className="btn btn-warning coyote-btn" disabled={is_submitted} >{is_submitted ? 'Please Wait ...' : 'Subscribe'}</button>
                    </RBT.Modal.Footer>
                </form>
            </RBT.Modal>
        </Fragment>
    )
}

export default NewsletterArea
