import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useForm } from "react-hook-form";
import CheckoutContext from "../contexts/CheckoutServiceProvider";
import CreditCardInput from "react-credit-card-input";
import PayPal from "./PayPal";
import Clover from "./Clover";
import LOCK from "../../../assets/img/lock.png";
import CLOVER_iCON from "../../../assets/img/clovericon.png";
import axios from "axios";
import { APIS } from "../config/constant";
import {
  API_STATUS,
  FORMAT_FORM_DATA,
  GET_STORAGE,
  SETTINGS,
} from "../../../config/constant";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import ReactImageFallback from "react-image-fallback";

function CheckoutPayment() {
  const { register, errors, handleSubmit, setValue, trigger, formState } =
    useForm();
  const cart = useSelector((state) => state.cart[0]);
  const {
    setStep,
    payment_gateway,
    setPayment_gateway,
    needToUpdate,
    SetNeedToUpdate,
    showCartError,
    card_holder_name,
    setCard_holder_name,
    cardNumber,
    setCardNumber,
    expiry,
    setExpiry,
    cvc,
    setCvc,
    handleCardNumberChange,
    handleCardExpiryChange,
    handleCardCVCChange,
    is_submitted,
    placeOrder,
    setIs_submitted,
    billing_shipping_data,
    setBilling_Shipping_data,
    disableLastStep,
    alertMessageClass,
    messageAfterPlaceOrder,
    promoCode,
    setPromoCode,
    promoCodeInfo,
    setPromoCodeInfo,
  } = useContext(CheckoutContext);

  const [tax_data, setTax_data] = useState({});
  const [shipping_data, setShipping_data] = useState({});
  const [disable_tax_flag, setDisable_tax_flag] = useState(false);
  const [disable_shipping_flag, setDisable_shipping_flag] = useState(false);
  const [disablePaymentOption, setDisablePaymentOption] = useState(false);
  const [hasFetchTaxData, setHasFetchTaxData] = useState(false);
  const auth = useSelector((state) => state.auth);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [isUpdatePromo, setIsUpdatePromo] = useState(false);
  const [promoCodeMessage, setPromoCodeMessage] = useState("");
  const [promoDiscountPrice, setPromoDiscountPrice] = useState(0);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  let taxType = "";
  let taxAmount = 0;

  const fetchTaxData = async () => {
    try {
      const response = await axios.post(
        `${APIS.PROVINCE_TAX_INFO}/`,
        FORMAT_FORM_DATA(billing_shipping_data)
      );
      if (
        response.data.status === API_STATUS.OK &&
        response.data.response.result
      ) {
        setTax_data(response.data.response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    document.querySelector("body").scrollIntoView();
    const settings = GET_STORAGE(SETTINGS);
    if (settings) {
      const settingsParsed = JSON.parse(settings);
      if (
        settingsParsed.shopSettingData &&
        settingsParsed.shopSettingData.tax_options &&
        settingsParsed.shopSettingData.tax_options.active
      ) {
        setDisable_tax_flag(true);
      }

      if (
        settingsParsed.shopSettingData &&
        settingsParsed.shopSettingData.shipping_options &&
        settingsParsed.shopSettingData.shipping_options.active
      ) {
        let sData = {
          type: "F",
          rate: parseFloat(
            settingsParsed.shopSettingData.shipping_options.default_rate
          ),
        };
        setShipping_data(sData);
        setDisable_shipping_flag(true);
      }

      if (
        settingsParsed.shopSettingData &&
        settingsParsed.shopSettingData.payment_options &&
        settingsParsed.shopSettingData.payment_options.payment_enabled
      ) {
        setDisablePaymentOption(true);
      }

      if (
        settingsParsed.shopSettingData &&
        settingsParsed.shopSettingData.product_options &&
        settingsParsed.shopSettingData.product_options.default_currency
      ) {
        setDefaultCurrency(
          settingsParsed.shopSettingData.product_options.default_currency
        );
      }
    }

    if (!hasFetchTaxData) {
      fetchTaxData();
      setHasFetchTaxData(true);
    }
  }, [billing_shipping_data]);

  const backFunctionHandler = () => {
    setStep(2);
  };

  const outputHandler = (message, clear = false) => {};

  const extractTAXText = (text) => {
    const match = text.match(/\((.*?)\)/);
    return match ? match[1] : "";
  };

  const getTaxText = () => {
    let taxText = "";
    if (Object.keys(tax_data).length > 0) {
      taxText = extractTAXText(tax_data.name);
      if (tax_data.type === "P") {
        taxText += "(" + parseFloat(tax_data.rate).toFixed(2) + "%)";
      } else {
        taxText +=
          "(" +
          defaultCurrency +
          " " +
          parseFloat(tax_data.rate).toFixed(2) +
          ")";
      }
      taxType = tax_data.type;
      taxAmount = tax_data.rate;
      billing_shipping_data.taxData = JSON.stringify(tax_data);
    }

    return taxText;
  };

  const calculateSubTotalPrice = useMemo(() => {
    let price = 0;
    Object.keys(cart).forEach((cartSingle) => {
      price +=
        parseFloat(cart[cartSingle].price_with_variation) *
        parseInt(cart[cartSingle].c_quantity);
    });

    if (promoDiscountPrice < price) {
      // setPromoCodeMessage("");
    } else {
      setPromoCodeMessage(
        '<small class="text-danger mb-0">You cannot use that promo code because the discount price is greater than the subtotal price!</small>'
      );
      setPromoCode("");
      setPromoCodeInfo({});
    }

    return price;
  }, [cart, promoDiscountPrice]);

  useEffect(() => {
    setSubTotalPrice(calculateSubTotalPrice);
  }, [calculateSubTotalPrice]);

  const calculateTaxPrice = () => {
    let tax = parseFloat(taxAmount);
    if (taxType === "P") {
      tax = (parseFloat(subTotalPrice) * parseFloat(taxAmount)) / 100;
    }
    billing_shipping_data.tax = tax;
    billing_shipping_data.taxText = getTaxText();
    return tax;
  };

  const calculateShippingPrice = () => {
    let sAmount = parseFloat(shipping_data.rate);
    if (shipping_data.type === "P") {
      sAmount =
        (parseFloat(subTotalPrice) * parseFloat(shipping_data.rate)) / 100;
    }
    billing_shipping_data.shippingAmont = sAmount;
    billing_shipping_data.shippingType = shipping_data.type;
    billing_shipping_data.shippingMethod = JSON.stringify(shipping_data);
    return sAmount;
  };

  const calculateTotalPrice = () => {
    let tax = parseFloat(taxAmount);
    if (taxType === "P") {
      tax = (parseFloat(subTotalPrice) * parseFloat(taxAmount)) / 100;
    }
    let sAmount = parseFloat(shipping_data.rate);
    if (shipping_data.type === "P") {
      sAmount =
        (parseFloat(subTotalPrice) * parseFloat(shipping_data.rate)) / 100;
    }

    return (
      parseFloat(tax) +
      parseFloat(subTotalPrice) +
      parseFloat(sAmount) -
      parseFloat(promoDiscountPrice)
    );
  };

  const handleChangePromoCode = (e) => {
    let val = e.target.value;
    setPromoCode(val);
  };

  const handleClickPromoCode = async () => {
    setPromoCodeMessage("");
    setIsUpdatePromo(true);
    if (!promoCode) {
      setPromoCodeMessage(
        '<small class="text-danger mb-0" >This field is required!</small>'
      );
      setIsUpdatePromo(false);
      setPromoCode("");
      setPromoCodeInfo({});

      return;
    }

    if (!auth.token) {
      setPromoCodeMessage(
        '<small class="text-danger mb-0" >Please login first!</small>'
      );
      setIsUpdatePromo(false);
      setPromoCode("");
      setPromoCodeInfo({});

      return;
    }

    try {
      const response = await axios.post(
        `${APIS.PROMO_CODE_INFO}`,
        FORMAT_FORM_DATA({
          promo_code: promoCode,
        })
      );
      if (
        response.data.status === API_STATUS.OK &&
        response.data.response.result
      ) {
        setPromoCodeMessage(
          '<small class="text-success mb-0">' +
            response.data.response.message +
            "</small>"
        );
        let promoInfo = response.data.response.data;
        setPromoCodeInfo(response.data.response.data);
        if (promoInfo.type === "FA") {
          let dis = promoInfo.rate;
          setPromoDiscountPrice(dis);
        } else if (promoInfo.type === "P") {
          let dis = (subTotalPrice * promoInfo.rate) / 100;
          setPromoDiscountPrice(dis);
        }
      } else {
        setPromoCodeMessage(
          '<small class="text-danger mb-0">' +
            response.data.response.message +
            "</small>"
        );

        setPromoCode("");
        setPromoCodeInfo({});
      }

      setIsUpdatePromo(false);
    } catch (error) {
      setPromoCodeMessage(
        '<small class="text-danger mb-0">Failed to apply promo code</small>'
      );
      setIsUpdatePromo(false);
      setPromoCode("");
      setPromoCodeInfo({});
    }
  };

  const handlePlaceOrder = () => {
    setIs_submitted(true);
    const result = { status: "PENDING" };
    placeOrder(result);
  };

  return (
    <Fragment>
      {disableLastStep ? (
        <div className="payment-gateway-block">
          <div className="col-md-12 d-flex justify-content-center">
            <div className={`alert ${alertMessageClass}`} role="alert">
              <p>{messageAfterPlaceOrder}</p>
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-center">
            {alertMessageClass === "alert-success" ? (
              <NavLink to="/products" className="btn btn-warning coyote-btn">
                <span>Continue Shopping</span>
              </NavLink>
            ) : (
              <NavLink to="/checkout" className="btn btn-warning coyote-btn">
                <span>Checkout</span>
              </NavLink>
            )}
          </div>
        </div>
      ) : (
        <>
          <h3 className="inner-page-sub-title">Payment</h3>
          <div className="payment-gateway-block">
            <div className="table-responsive py-30">
              <table className="table">
                <colgroup>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "45%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "15%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">Image</th>
                    <th scope="col">Name</th>
                    <th className="text-center" scope="col">
                      Quantity
                    </th>
                    <th className="text-center" scope="col">
                      Price
                    </th>
                    <th className="text-right" scope="col">
                      Subtotal
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cart && Object.keys(cart).length > 0 ? (
                    <Fragment>
                      {Object.keys(cart).map((cart_single, index) => (
                        <tr key={index}>
                          <td>
                            {cart[cart_single].type &&
                            cart[cart_single].type === "custom" ? (
                              <Fragment>
                                <a
                                  href={`${cart[cart_single].stages.front.print_file}`}
                                  className="thumbnail"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <ReactImageFallback
                                    src={`${cart[cart_single].stages.front.screenshot}`}
                                    fallbackImage={
                                      require("../../../assets/img/no-image.png")
                                        .default
                                    }
                                    initialImage={
                                      require("../../../assets/img/loading_img.png")
                                        .default
                                    }
                                    alt={cart[cart_single].name}
                                    style={{ width: "25px" }}
                                    className="first-img"
                                  />
                                </a>
                                {cart[cart_single].stages.back.print_file ? (
                                  <a
                                    href={`${cart[cart_single].stages.back.print_file}`}
                                    className="thumbnail"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <ReactImageFallback
                                      src={`${cart[cart_single].stages.back.screenshot}`}
                                      fallbackImage={
                                        require("../../../assets/img/no-image.png")
                                          .default
                                      }
                                      initialImage={
                                        require("../../../assets/img/loading_img.png")
                                          .default
                                      }
                                      alt={cart[cart_single].name}
                                      style={{ width: "25px" }}
                                      className="first-img"
                                    />
                                  </a>
                                ) : (
                                  ""
                                )}
                              </Fragment>
                            ) : (
                              <NavLink
                                to={`/products/${cart[cart_single].slug}`}
                                className="thumbnail"
                              >
                                <ReactImageFallback
                                  src={`${cart[cart_single].image}`}
                                  fallbackImage={
                                    require("../../../assets/img/no-image.png")
                                      .default
                                  }
                                  initialImage={
                                    require("../../../assets/img/loading_img.png")
                                      .default
                                  }
                                  alt={cart[cart_single].name}
                                  style={{ width: "50px" }}
                                  className="first-img"
                                />
                              </NavLink>
                            )}
                          </td>
                          <td>
                            <NavLink
                              to={`/products/${cart[cart_single].slug}`}
                              className="thumbnail"
                            >
                              {cart[cart_single].name}
                            </NavLink>
                            {cart[cart_single].c_options &&
                            Object.keys(cart[cart_single].c_options).length >
                              0 ? (
                              <Fragment>
                                <br />
                                {Object.keys(cart[cart_single].c_options).map(
                                  (c_options_single, index) => (
                                    <span
                                      key={`option_value_span_${cart[cart_single].c_options[c_options_single].option_value}`}
                                      className="cart-product-option"
                                    >
                                      {
                                        cart[cart_single].c_options[
                                          c_options_single
                                        ].option_value
                                      }
                                    </span>
                                  )
                                )}
                              </Fragment>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td className="text-center">
                            {cart[cart_single].c_quantity}
                          </td>
                          <td className="text-center">
                            {defaultCurrency}{" "}
                            {parseFloat(
                              cart[cart_single].price_with_variation
                            ).toFixed(2)}
                          </td>
                          <td className="text-right">
                            {defaultCurrency}{" "}
                            {parseFloat(
                              parseFloat(
                                cart[cart_single].price_with_variation
                              ).toFixed(2) *
                                parseInt(cart[cart_single].c_quantity)
                            ).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        Cart is empty
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="2" className="text-right">
                      <b>Promo Code:</b>
                    </td>
                    <td colSpan="3" className="text-right">
                      <div className="d-flex">
                        <input
                          type="text"
                          className="form-control mr-1"
                          id="promo_code"
                          name="promoCode"
                          ref={register()}
                          placeholder="Promo"
                          value={promoCode}
                          onChange={handleChangePromoCode}
                        />
                        <button
                          type="button"
                          disabled={isUpdatePromo}
                          className="btn btn-secondary btn-sm"
                          onClick={() => handleClickPromoCode()}
                        >
                          {isUpdatePromo ? "Please Wait ..." : "Update"}
                        </button>
                      </div>
                      <div
                        className="d-flex"
                        dangerouslySetInnerHTML={{
                          __html: promoCodeMessage,
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-right">
                      <b>Subtotal:</b>
                    </td>
                    <td className="text-right">
                      {defaultCurrency} {subTotalPrice.toFixed(2)}
                    </td>
                  </tr>
                  {promoDiscountPrice > 0 && (
                    <tr>
                      <td colSpan="4" className="text-right">
                        <b>Discount(-):</b>
                      </td>
                      <td className="text-right">
                        {defaultCurrency} {promoDiscountPrice.toFixed(2)}
                      </td>
                    </tr>
                  )}
                  {disable_tax_flag && (
                    <tr>
                      <td colSpan="4" className="text-right">
                        <b>{getTaxText()}:</b>
                      </td>
                      <td className="text-right">
                        {defaultCurrency} {calculateTaxPrice().toFixed(2)}
                      </td>
                    </tr>
                  )}
                  {disable_shipping_flag && (
                    <tr>
                      <td colSpan="4" className="text-right">
                        <b>Shipping:</b>
                      </td>
                      <td className="text-right">
                        {defaultCurrency} {calculateShippingPrice().toFixed(2)}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="4" className="text-right">
                      <b>Total:</b>
                    </td>
                    <td className="text-right">
                      {defaultCurrency} {calculateTotalPrice().toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {disablePaymentOption ? (
            <>
              <form
                method="post"
                onSubmit={handleSubmit(placeOrder)}
                className="clover-check"
              >
                {errors.payment_gateway && (
                  <label className="text-danger">
                    * This field is required
                  </label>
                )}
                <div className="form-radio">
                  <input
                    className="form-radio-input"
                    type="radio"
                    id="clover"
                    name="payment_gateway"
                    ref={register({ required: true })}
                    value="CL"
                    onChange={(e) => setPayment_gateway(e.target.value)}
                  />
                  &nbsp;
                  <label className="form-radio-label" htmlFor="clover">
                    Card Payment (Powered by Clover)
                  </label>
                </div>
              </form>

              <div className="payment-gateway-block">
                {payment_gateway === "PP" ? (
                  <PayPal />
                ) : (
                  <Fragment>
                    {payment_gateway === "CL" && (
                      <Clover
                        backHandler={backFunctionHandler}
                        outputHandler={outputHandler}
                        is_submitted={is_submitted}
                        setIs_submitted={setIs_submitted}
                        billing_shipping_data={billing_shipping_data}
                        placeOrder={placeOrder}
                      />
                    )}
                  </Fragment>
                )}
              </div>
            </>
          ) : (
            <div className="checkout-next-prev text-right py-md-30 py-20">
              <button
                type="button"
                className="btn btn-warning coyote-btn btn-gap"
                onClick={() => backFunctionHandler()}
              >
                Back
              </button>
              <button
                type="submit"
                disabled={is_submitted}
                className="btn btn-warning coyote-btn"
                onClick={() => handlePlaceOrder()}
              >
                {is_submitted ? "Please Wait ..." : "Place Order"}
              </button>
            </div>
          )}
        </>
      )}
    </Fragment>
  );
}

export default CheckoutPayment;
