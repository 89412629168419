import React, { Fragment, Suspense, useEffect, useState } from "react";
import Layout from "../../../utils/templates/layout/default";
import { useSelector } from "react-redux";
import { UpdateCartFromSession } from "../../../redux/actions/cart";
import { useHistory } from "react-router-dom";
import CartListSingle from "../component/CartListSingle";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_STORAGE, SETTINGS } from "../../../config/constant";

const CartPage = () => {
  const cart = useSelector((state) => state.cart[0]);
  const [needToUpdate, SetNeedToUpdate] = useState(false);
  const [disable_shopping_cart, setDisable_shopping_cart] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const history = useHistory();
  let total_price = 0;

  const updateCart = () => {
    SetNeedToUpdate(false);
    UpdateCartFromSession();
  };

  useEffect(() => {
    const settings = GET_STORAGE(SETTINGS);
    if (settings) {
      const settingsParsed = JSON.parse(settings);
      if (
        settingsParsed.shopSettingData &&
        settingsParsed.shopSettingData.theme_options &&
        settingsParsed.shopSettingData.theme_options.disable_shopping_cart
      ) {
        setDisable_shopping_cart(true);
        toast.error(
          "Currently Shopping cart & checkout functionality is disabled"
        );
        history.push("/");
      }

      if (
        settingsParsed.shopSettingData &&
        settingsParsed.shopSettingData.product_options &&
        settingsParsed.shopSettingData.product_options.default_currency
      ) {
        setDefaultCurrency(
          settingsParsed.shopSettingData.product_options.default_currency
        );
      }
    }
  }, []);

  return (
    <Layout>
      <Suspense>
        <section className="cart-page">
          <div className="container-fluid title-inner-card">
            <div className="all-page-title">
              <h2>Cart</h2>
            </div>
          </div>
          <div className="container py-md-80 py-50 cartlist-view">
            <div className="row">
              <div className="col-lg-8 mbl-view-table">
                <table className="table new-cart-table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Image</th>
                      <th scope="col">Name</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Price</th>
                      <th scope="col">Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart && Object.keys(cart).length > 0 ? (
                      <Fragment>
                        {Object.keys(cart).map((cart_single, index) => {
                          total_price =
                            total_price +
                            parseFloat(cart[cart_single].price_with_variation) *
                              parseInt(cart[cart_single].c_quantity);
                          return (
                            <CartListSingle
                              cart_single={cart[cart_single]}
                              key={cart_single}
                              SetNeedToUpdate={SetNeedToUpdate}
                            />
                          );
                        })}
                      </Fragment>
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          Cart is empty
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-lg-4">
                <div className="preceed-checkout">
                  <div className="title">Cart totals</div>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td>Subtotal</td>
                          <td className="">
                            {defaultCurrency}{" "}
                            {parseFloat(total_price).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <th className="py-20">Total</th>
                          <th className="price-total">
                            {defaultCurrency}{" "}
                            {parseFloat(total_price).toFixed(2)}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    {cart && Object.keys(cart).length > 0 ? (
                      <Fragment>
                        <NavLink
                          to="/checkout"
                          className="btn btn-warning coyote-btn"
                        >
                          Processed To Checkout
                        </NavLink>
                      </Fragment>
                    ) : (
                      ""
                    )}
                    {needToUpdate ? (
                      <Fragment>
                        <button
                          type="button"
                          className="btn btn-warning coyote-btn"
                          onClick={updateCart}
                        >
                          Update Cart
                        </button>{" "}
                        &nbsp;
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </Layout>
  );
};

export default CartPage;
