import React, {useState, useEffect, Fragment} from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { ProtectedRoute } from './utils/auth/protected.route';
import ModuleRoutes from './config/routes';
import PageNotFound from './utils/templates/layout/pagenotfound';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactImageFallback from "react-image-fallback";
import axios from "axios";
import { APIS, API_STATUS, GET_STORAGE, SET_STORAGE, SETTINGS } from "./config/constant";


function App() {
	// console.log('ModuleRoutes', ModuleRoutes);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		axios.get(APIS.SETTINGS).then((response) => {
			if (response.data.status == API_STATUS.OK) {
				if (response.data.response.result) {
					SET_STORAGE(SETTINGS, JSON.stringify(response.data.response.data))
					setLoading(false);
				} else {
					setLoading(false);
				}
			} else {
				setLoading(false);
			}
		});
	}, []);

	return (
		<div>
			<ToastContainer />
			<Fragment>
				{loading ?
					<section className="pages-details">
						<div className="loading">
							<ReactImageFallback
								src={require("./assets/img/loading.gif").default}
								fallbackImage={require("./assets/img/no-image.png").default}
								initialImage={require("./assets/img/loading.gif").default}
								alt="no image"
								style={{ width: "30px" }}
								className=""
							/>
						</div>
					</section>
					:
					<Switch>
						{ModuleRoutes.map(modules => {
							return modules.map(module => {
								if (module.isProtected) {
									return (<ProtectedRoute {...module} exact key={module.path} />)
								} else {
									return (<Route {...module} exact key={module.path} />)
								}
							})
						})}
						<Route path="*" component={PageNotFound} />
					</Switch>
				}
			</Fragment>

			{/* <Switch>
				{ModuleRoutes.map(modules => {
					return modules.map(module => {
						if (module.isProtected) {
							return (<ProtectedRoute {...module} exact key={module.path} />)
						} else {
							return (<Route {...module} exact key={module.path} />)
						}
					})
				})}
				<Route path="*" component={PageNotFound} />
			</Switch> */}
		</div>
	);
}

export default withRouter(App);
