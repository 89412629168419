/**
 * Manage Wishlist data on Local Storage and Redux
 *
 * @author Md. Khairullah Nayan <khairullah@instabd.com>
 * @author Muiz Ahmed Khan <muiz@instabd.com>
 */

import { TYPES } from '../types/index';
import auth from '../../utils/auth/auth';
import { toast } from 'react-toastify';
import { SET_STORAGE, GET_STORAGE, WISHLIST, REMOVE_STORAGE, PROCESS_PRODUCT_FOR_CART } from '../../config/constant'

const initialState = {
    wishlist: [{}]
}

export const AddToWishlist = (e, product, c_quantity = 1, c_options = {}) => {
    e.preventDefault();
    let wishlist = {};
    let isOutOfStock = false;

    if (!GET_STORAGE(WISHLIST)) {
        SET_STORAGE(WISHLIST, JSON.stringify(wishlist));
    }
    wishlist = JSON.parse(GET_STORAGE(WISHLIST));
    let newItem = product;
    newItem.c_quantity = c_quantity;
    newItem.c_options = c_options;
    newItem = PROCESS_PRODUCT_FOR_CART(newItem);

    if (wishlist[newItem.uuid]) {
        wishlist[newItem.uuid].c_quantity = Number(wishlist[newItem.uuid].c_quantity) + Number(newItem.c_quantity);
        wishlist[newItem.uuid].c_options = newItem.c_options;
    } else {
        wishlist[newItem.uuid] = newItem;
    }

    toast.success('Item added to wishlist');
    SET_STORAGE(WISHLIST, JSON.stringify(wishlist));
    return {
        type: TYPES.UPDATE_WISHLIST,
        payload: JSON.parse(GET_STORAGE(WISHLIST))
    }
}

export const RemoveFromWishlist = (e, product, flash_msg = true) => {
    e.preventDefault();
    var wishlist = JSON.parse(GET_STORAGE(WISHLIST));
    if (wishlist && wishlist[product.uuid]) {
        delete (wishlist[product.uuid])
        SET_STORAGE(WISHLIST, JSON.stringify(wishlist));
        if (flash_msg) {
            toast.success('Item removed from wishlist');
        }
    } else {
        toast.error('No such item on wishlist');
    }
    return {
        type: TYPES.REMOVE_FROM_WISHLIST,
        payload: JSON.parse(GET_STORAGE(WISHLIST))
    }
}

export const deleteWishlist = () => {
    REMOVE_STORAGE(WISHLIST)
    return {
        type: TYPES.DELETE_WISHLIST,
        payload: initialState
    }
}