export const APIS = {
    DASHBOARD: '/dashboard',
    ORDERLIST: '/order-history',
    ORDERDETAILS: '/order-details',
    GETPROFILEINFO: '/profile',
    EIDTPROFILE: '/edit-profile',
    CHANGEPASSWORD: '/change-password',
    COUNTRYLIST: '/countries',
    PROVINCELIST: '/states',
    UPDATEADDRESSES: '/edit-address',
}