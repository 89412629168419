import React, {
  Suspense,
  useContext,
  useState,
  useEffect,
  Fragment,
} from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactImageFallback from "react-image-fallback";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import Layout from "../../../utils/templates/layout/default";
import { Helmet } from "react-helmet";
import { APIS } from "../config/constant";
import { API_STATUS, TITLE } from "../../../config/constant";
import { CONFIG } from "../../../config/settings";
import { WOW } from "wowjs";
import LatestProductList from "../component/LatestProductList";

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <h2 className="mb-0">
      <button
        className="d-flex align-items-center justify-content-between btn btn-link"
        onClick={decoratedOnClick}
      >
        {children}
        <div className="fa-stack fa-2x">
          <i
            className={
              isCurrentEventKey
                ? "fa fa-stack-1x fa-inverse fa-minus"
                : "fa fa-plus fa-stack-1x fa-inverse"
            }
          ></i>
        </div>
      </button>
    </h2>
  );
}

const wow = new WOW({
  mobile: false,
  live: true,
});

const Home = () => {
  const auth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [meta_title, setMeta_title] = useState(TITLE);
  const [meta_description, setMeta_description] = useState(TITLE);
  const [homepageProducts, setHomepageProducts] = useState(false);
  const [pageData, setPageData] = useState({});

  const slider_settings = {
    autoplay: true,
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1500,
    cssEase: "linear",
    lazyLoad: "ondemand",
    controls: false,
    fade: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  document.querySelector("body").scrollIntoView();

  useEffect(() => {
    setLoading(true);
    axios.get(APIS.PAGES + "?slug=home").then((response) => {
      if (response.data.status === API_STATUS.OK) {
        if (response.data.response.result) {
          if (response.data.response.data.others) {
            setMeta_title(response.data.response.data.others.meta_title);
            setMeta_description(
              response.data.response.data.others.meta_description
            );
          }
          setPageData(response.data.response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
      setTimeout(function () {
        wow.init();
      }, 1000);
    });

    axios.get(APIS.HOMEPAGEDATA + "?limit=4").then((response) => {
      if (response.data.status === API_STATUS.OK) {
        if (response.data.response.result) {
          setHomepageProducts(response.data.response.data);
        }
        setTimeout(function () {
          wow.init();
        }, 1000);
      }
    });
  }, []);

  return (
    <Suspense>
      <Layout>
        {loading ? (
          <section className="pages-details">
            <div className="loading">
              <ReactImageFallback
                src={require("../../../assets/img/loading.gif").default}
                fallbackImage={
                  require("../../../assets/img/no-image.png").default
                }
                initialImage={
                  require("../../../assets/img/loading.gif").default
                }
                alt="no image"
                style={{ width: "30px" }}
                className=""
              />
            </div>
          </section>
        ) : (
          <Fragment>
            <Helmet>
              <title>{meta_title}</title>
              <meta name="description" content={meta_description} />
              <link rel="canonical" href={CONFIG.BASE_URL} />
            </Helmet>

            <section className="home-banner-container">
              <div className="home-banner-content">
                <div className="content-list">
                  <div className="item">
                    <div className="signature-title">
                      <img
                        alt="Beauty Academy"
                        src={
                          require("../../../assets/img/slider/beauti-acedemy.png")
                            .default
                        }
                      />
                      <div className="title-container">
                        <div className="title-content">
                          <img
                            alt="Beauty Academy"
                            src={
                              require("../../../assets/img/slider/beauty.png")
                                .default
                            }
                          />
                          <NavLink
                            to="/services/mood-beauty-academy"
                            className="nav-link"
                          ></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="signature-title">
                      <img
                        alt="Hair & Spa Salon"
                        src={
                          require("../../../assets/img/slider/hair-spa.png")
                            .default
                        }
                      />
                      <div className="title-container">
                        <div className="title-content">
                          <img
                            alt="Hair & Spa Salon"
                            src={
                              require("../../../assets/img/slider/hair.png")
                                .default
                            }
                          />
                          <NavLink
                            to="/services/essential-hair-spa-beauty-salon"
                            className="nav-link"
                          ></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="signature-title">
                      <img
                        alt="Beauty Shop"
                        src={
                          require("../../../assets/img/slider/beauti-shop.png")
                            .default
                        }
                      />
                      <div className="title-container">
                        <div className="title-content">
                          <img
                            alt="Beauty Shop"
                            src={
                              require("../../../assets/img/slider/shop.png")
                                .default
                            }
                          />
                          <NavLink
                            to="/products"
                            className="nav-link"
                          ></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="welcome-content">
              <h3 className="title">
                <img
                  alt="Welcome Text"
                  src={require("../../../assets/img/welcomt-text.png").default}
                />
              </h3>
              <h4>Nothing Is Impossible</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.description,
                }}
              />
            </section>
            <LatestProductList />
          </Fragment>
        )}
      </Layout>
    </Suspense>
  );
};

export default Home;
