export const APIS = {
  HOME: "/",
  HOMEPAGEDATA: "/get-homepage-data",
  PRODUCTS: "/get-all-products",
  CATEGORY_PRODUCTS: "/get-all-category-products",
  RELATEDPRODUCTS: "/related-products",
  CHECKPRODUCTBASE: "/check-product-base",
  PAGES: "/page-content",
  CATEGORIES: "/get-categories",
  BRANDS: "/get-products-brands",
  PRODUCTDETAILS: "/product-details",
  COUNTRYLIST: "/countries",
  PROVINCELIST: "/states",
  STATELIST: "/states",
  PLACEORDER: "/payment/place-order",
  CONTACTSUBMIT: "/contact-us-submit",
  GETPROFILEINFO: "/profile",
  LATEST_PRODUCTS: "/recent-products",
  TAXLIST: "/shipping-method",
  PROVINCE_TAX_INFO: "/province-tax-info",
  PROMO_CODE_INFO: "/promo-code-info",
};
