import React, { Suspense, useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { CONFIG } from "../../../config/settings";
import ReactImageFallback from "react-image-fallback";
import { Helmet } from "react-helmet";
import Layout from "../../../utils/templates/layout/default";
import { API_STATUS, TITLE } from "../../../config/constant";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { APIS } from "../config/constant";
import axios from "axios";

const ServicesPage = (props) => {
  const [meta_title, setMeta_title] = useState(TITLE);
  const [meta_description, setMeta_description] = useState(TITLE);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState(false);

  useEffect(() => {
    setLoading(false);
    setPageData(false);
    axios.get(APIS.PAGES + "?slug=services").then((response) => {
      if (response.data.status == API_STATUS.OK) {
        if (response.data.response.result) {
          setPageData(response.data.response.data);
          setLoading(false);
          //seo
          if (response.data.response.data.others) {
            setMeta_title(response.data.response.data.others.meta_title);
            setMeta_description(
              response.data.response.data.others.meta_description
            );
          }
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });
  }, []);

  return (
    <Suspense>
      <Layout>
        <section className="services">
          <Helmet>
            <title>{meta_title}</title>
            <meta name="description" content={meta_description} />
            <link
              rel="canonical"
              href={`${CONFIG.BASE_URL}/services/${props.match.params.slug}`}
            />
          </Helmet>
          {loading ? (
            <section className="pages-details">
              <div className="loading">
                <ReactImageFallback
                  src={require("../../../assets/img/loading.gif").default}
                  fallbackImage={
                    require("../../../assets/img/no-image.png").default
                  }
                  initialImage={
                    require("../../../assets/img/loading.gif").default
                  }
                  alt="no image"
                  style={{ width: "30px" }}
                  className=""
                />
              </div>
            </section>
          ) : (
            <Fragment>
              <section className="home-banner-container">
                <div className="home-banner-content">
                  <div className="content-list">
                    <div className="item">
                      <div className="signature-title">
                        <img
                          alt="beauti-acedemy"
                          src={
                            require("../../../assets/img/slider/beauti-acedemy.png")
                              .default
                          }
                        />
                        <div className="title-container">
                          <div className="title-content">
                            <img
                              alt="beauty"
                              src={
                                require("../../../assets/img/slider/beauty.png")
                                  .default
                              }
                            />
                            <NavLink
                              to="/services/mood-beauty-academy"
                              className="nav-link"
                            ></NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="signature-title">
                        <img
                          alt="hair-spa"
                          src={
                            require("../../../assets/img/slider/hair-spa.png")
                              .default
                          }
                        />
                        <div className="title-container">
                          <div className="title-content">
                            <img
                              alt="hair"
                              src={
                                require("../../../assets/img/slider/hair.png")
                                  .default
                              }
                            />
                            <NavLink
                              to="/services/essential-hair-spa-beauty-salon"
                              className="nav-link"
                            ></NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="signature-title">
                        <img
                          alt="beauti-shop"
                          src={
                            require("../../../assets/img/slider/beauti-shop.png")
                              .default
                          }
                        />
                        <div className="title-container">
                          <div className="title-content">
                            <img
                              alt="shop"
                              src={
                                require("../../../assets/img/slider/shop.png")
                                  .default
                              }
                            />
                            <NavLink
                              to="/products"
                              className="nav-link"
                            ></NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div className="md-services-promo-text-area">
                <div className="container">
                  <div
                    className="md-services-promo-text text-center"
                    dangerouslySetInnerHTML={{
                      __html: pageData.description,
                    }}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </section>
      </Layout>
    </Suspense>
  );
};

export default ServicesPage;
