import React, { Fragment, Suspense, useEffect, useState } from "react";
import {
  CART,
  GET_STORAGE,
  SET_STORAGE,
  SET_SESSION,
  GET_SESSION,
  QUANTITY_UPDATE,
  SETTINGS,
} from "../../../config/constant";
import { CONFIG } from "../../../config/settings";
import ReactImageFallback from "react-image-fallback";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ManageCart, RemoveFromCart } from "../../../redux/actions/cart";

const CartListSingle = (props) => {
  const [quantity, setQuantity] = useState(1);
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setQuantity(props.cart_single ? props.cart_single.c_quantity : 1);
    const settings = GET_STORAGE(SETTINGS);
    if (settings) {
      const settingsParsed = JSON.parse(settings);
      if (
        settingsParsed.shopSettingData &&
        settingsParsed.shopSettingData.product_options &&
        settingsParsed.shopSettingData.product_options.default_currency
      ) {
        setDefaultCurrency(
          settingsParsed.shopSettingData.product_options.default_currency
        );
      }
    }
  }, [props]);

  const handleQuantity = (e, type) => {
    e.preventDefault();
    const current_qty = parseInt(quantity);
    if (type == "plus") {
      const updated_qty = current_qty + 1;
      setQuantity(updated_qty);
      dispatch(
        ManageCart(e, props.cart_single, 1, props.cart_single.c_options)
      );
    } else {
      if (current_qty > 1) {
        const updated_qty = current_qty - 1;
        setQuantity(updated_qty);
        dispatch(
          ManageCart(e, props.cart_single, -1, props.cart_single.c_options)
        );
      }
    }
  };

  const handleRemove = (e, cart_single) => {
    if (window.confirm("Are you sure!")) {
      dispatch(RemoveFromCart(e, cart_single));
    }
  };

  const updateQty = (e) => {
    const qty = e.target.value;
    console.log("qty", qty);
    setQuantity(qty);
    if (qty && qty != "" && parseInt(qty) > 0) {
      let cart = {};
      if (!GET_STORAGE(CART)) {
        SET_STORAGE(CART, JSON.stringify(cart));
      }

      let cart_update_qty = {};
      if (!GET_SESSION(QUANTITY_UPDATE)) {
        SET_SESSION(QUANTITY_UPDATE, GET_STORAGE(CART));
      }

      cart_update_qty = JSON.parse(GET_SESSION(QUANTITY_UPDATE));
      let newItem = props.cart_single;
      newItem.c_quantity = qty;

      if (cart_update_qty[newItem.id]) {
        cart_update_qty[newItem.id].c_quantity = Number(newItem.c_quantity);
      } else {
        cart_update_qty[newItem.id] = newItem;
      }
      SET_SESSION(QUANTITY_UPDATE, JSON.stringify(cart_update_qty));
      props.SetNeedToUpdate(true);
    }
  };

  return (
    <tr>
      <td className="pr-0">
        <button
          className="btn product-remove"
          onClick={(e) => handleRemove(e, props.cart_single)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
      <td>
        {props.cart_single.type && props.cart_single.type == "custom" ? (
          <Fragment>
            <a
              href={`${props.cart_single.stages.front.print_file}`}
              className="thumbnail"
              target="_blank"
              rel="noreferrer"
            >
              <ReactImageFallback
                src={`${props.cart_single.stages.front.screenshot}`}
                fallbackImage={
                  require("../../../assets/img/no-image.png").default
                }
                initialImage={
                  require("../../../assets/img/loading_img.png").default
                }
                alt={props.cart_single.name}
                style={{ width: "100px" }}
                className="first-img"
              />
            </a>
            {props.cart_single.stages.back.print_file ? (
              <a
                href={`${props.cart_single.stages.back.print_file}`}
                className="thumbnail"
                target="_blank"
                rel="noreferrer"
              >
                <ReactImageFallback
                  src={`${props.cart_single.stages.back.screenshot}`}
                  fallbackImage={
                    require("../../../assets/img/no-image.png").default
                  }
                  initialImage={
                    require("../../../assets/img/loading_img.png").default
                  }
                  alt={props.cart_single.name}
                  style={{ width: "100px" }}
                  className="first-img"
                />
              </a>
            ) : (
              ""
            )}
          </Fragment>
        ) : (
          <NavLink
            to={`/products/${props.cart_single.slug}`}
            className="thumbnail"
          >
            <ReactImageFallback
              src={`${props.cart_single.image}`}
              fallbackImage={
                require("../../../assets/img/no-image.png").default
              }
              initialImage={
                require("../../../assets/img/loading_img.png").default
              }
              alt={props.cart_single.name}
              style={{ width: "100px" }}
              className="first-img"
            />
          </NavLink>
        )}
      </td>
      <td>
        <NavLink
          to={`/products/${props.cart_single.slug}`}
          className="thumbnail product-name"
        >
          {props.cart_single.name}
        </NavLink>
        {props.cart_single.c_options &&
        Object.keys(props.cart_single.c_options).length > 0 ? (
          <Fragment>
            <br />
            {Object.keys(props.cart_single.c_options).map(
              (c_options_single, index) => {
                return (
                  <span className="cart-product-option">
                    {" "}
                    {props.cart_single.c_options[c_options_single].option_value}
                  </span>
                );
              }
            )}
          </Fragment>
        ) : (
          ""
        )}
      </td>
      <td>
        <div className="quantity-block">
          <button
            className="cart-minus"
            onClick={(e) => handleQuantity(e, "minus")}
          >
            -
          </button>
          <input
            type="number"
            name="quantity"
            min="1"
            max="1000000"
            onChange={updateQty}
            className="form-control text-center quantity"
            value={quantity}
          />
          <button
            className="cart-plus"
            onClick={(e) => handleQuantity(e, "plus")}
          >
            +
          </button>
        </div>
      </td>
      <td className="table-price">
        {defaultCurrency}{" "}
        {parseFloat(props.cart_single.price_with_variation).toFixed(2)}
      </td>
      <td className="table-price">
        {defaultCurrency}{" "}
        {parseFloat(
          parseFloat(props.cart_single.price_with_variation).toFixed(2) *
            parseInt(props.cart_single.c_quantity)
        ).toFixed(2)}
      </td>
    </tr>
  );
};

export default CartListSingle;
