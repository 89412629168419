import React, { Fragment, Suspense, useEffect, useState } from "react";
import Layout from "../../../utils/templates/layout/default";
import { useSelector } from "react-redux";
import { UpdateCartFromSession } from '../../../redux/actions/cart';

import WishlistSingle from '../component/WishlistSingle';
import { NavLink } from "react-router-dom";

const Wishlist = () => {
    const wishlist = useSelector(state => state.wishlist[0]);

    return (
        <Layout>
            <Suspense>
                <section className="wishlist-page">
                    <div className="container">
                        <div className="all-page-title">
                            <h2>Wishlist</h2>
                        </div>
                        <div className="row">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Image</th>
                                            <th scope="col">Name</th>
                                            {/* <th scope="col">Quantity</th> */}
                                            <th scope="col">Price</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            wishlist && Object.keys(wishlist).length > 0 ?
                                                <Fragment>
                                                    {
                                                        Object.keys(wishlist).map((wishlist_single, index) => {
                                                            return (
                                                                <WishlistSingle wishlist_single={wishlist[wishlist_single]} key={wishlist_single} />
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                                :
                                                <tr><td colSpan="4" className="text-center">Wishlist is empty</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </Layout>
    )
}

export default Wishlist;