/**
 * Manage Cart data on Local Storage and Redux
 *
 * @author Md. Khairullah Nayan <khairullah@instabd.com>
 * @author Muiz Ahmed Khan <muiz@instabd.com>
 */

import { TYPES } from "../types/index";
import auth from "../../utils/auth/auth";
import { toast } from "react-toastify";
import {
  SET_STORAGE,
  GET_STORAGE,
  CART,
  REMOVE_STORAGE,
  PROCESS_PRODUCT_FOR_CART,
  GET_SESSION,
  QUANTITY_UPDATE,
  REMOVE_SESSION,
} from "../../config/constant";
import { CONFIG, VERSION } from "../../config/settings";

let initialState = [];

export const ManageCart = (e, product, c_quantity = 1, c_options = {}) => {
  if (e) {
    e.preventDefault();
  }

  let cart = {};
  let isOutOfStock = false;

  if (!GET_STORAGE(CART)) {
    SET_STORAGE(CART, JSON.stringify(cart));
  }
  cart = JSON.parse(GET_STORAGE(CART));
  let newItem = product;
  newItem.c_quantity = c_quantity;
  newItem.c_options = c_options;
  newItem = PROCESS_PRODUCT_FOR_CART(newItem);

  if (cart[newItem.id]) {
    cart[newItem.id].c_quantity =
      Number(cart[newItem.id].c_quantity) + Number(newItem.c_quantity);
    cart[newItem.id].c_options = newItem.c_options;
  } else {
    cart[newItem.id] = newItem;
  }

  let cartData = Object.values(cart);
  let totalQty = 0;
  cartData.forEach((c) => {
    totalQty += c.c_quantity;
  });

  //toast.success('Item added to cart sss');

  let cartUrl = CONFIG.BASENAME + "cart";
  if (newItem.c_quantity > 0) {
    toast.success(
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<span>Item added to cart</span><div class="toast-content"><a href="' +
            cartUrl +
            '"><i class="fa fa-shopping-cart" aria-hidden="true"></i> View Cart <span>(' +
            totalQty +
            ")</span></a></div>",
        }}
      />
    );
  } else {
    toast.success(
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<span>Item removed from cart</span><div class="toast-content"><a href="' +
            cartUrl +
            '"><i class="fa fa-shopping-cart" aria-hidden="true"></i> View Cart <span>(' +
            totalQty +
            ")</span></a></div>",
        }}
      />
    );
  }

  SET_STORAGE(CART, JSON.stringify(cart));
  return {
    type: TYPES.UPDATE_CART,
    payload: JSON.parse(GET_STORAGE(CART)),
  };
};

export const UpdateCartFromSession = () => {
  SET_STORAGE(CART, GET_SESSION(QUANTITY_UPDATE));
  REMOVE_SESSION(QUANTITY_UPDATE);
  toast.success("Cart Updated");
  return {
    type: TYPES.UPDATE_CART,
    payload: JSON.parse(GET_STORAGE(CART)),
  };
};

export const RemoveFromCart = (e, product) => {
  e.preventDefault();
  var cart = JSON.parse(GET_STORAGE(CART));
  if (cart && cart[product.id]) {
    delete cart[product.id];
    SET_STORAGE(CART, JSON.stringify(cart));

    let cartData = Object.values(cart);
    let totalQty = 0;
    cartData.forEach((c) => {
      totalQty += c.c_quantity;
    });
    // toast.success("Item removed from cart");
    toast.success(
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<span>Item removed from cart</span><div class="toast-content"><a href="/cart"><i class="fa fa-shopping-cart" aria-hidden="true"></i> View Cart <span>(' +
            totalQty +
            ")</span></a></div>",
        }}
      />
    );
  } else {
    toast.error("No such item on cart");
  }
  return {
    type: TYPES.REMOVE_FROM_CART,
    payload: JSON.parse(GET_STORAGE(CART)),
  };
};

export const deleteCart = () => {
  REMOVE_STORAGE(CART);
  return {
    type: TYPES.DELETE_CART,
    payload: initialState,
  };
};
