import React, { Suspense, useState, useEffect, useReducer, useRef } from "react";
import { useStore, useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory, NavLink } from 'react-router-dom';
import axios from 'axios';

import Layout from '../../../utils/templates/layout/default';
import { APIS } from '../config/constant';
import { API_STATUS, FORMAT_FORM_DATA } from '../../../config/constant';
import { initUserAuthentication } from '../../../redux/actions/auth'
import ReactImageFallback from "react-image-fallback";
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";


const ResetPassword = (props) => {
    const history = useHistory()
    // const store = useStore()
    const [loading, setLoading] = useState(true);
    const [is_submitted, setIs_submitted] = useState(false);
    const url = new URL(window.location.href);
    const redirect = url.searchParams.get('redirect');
    let redirect_url = false;
    if (redirect) {
        redirect_url = '/' + redirect;
    }

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const { register, errors, handleSubmit, watch, setValue } = useForm();
    const password = useRef({});
    password.current = watch("password", "");

    useEffect(() => {
        if (auth && auth.token) {
            toast.error('You are already logged in');
            history.push('/');
        }
        axios.post(APIS.FORGOTPASSWORDVERIFY, FORMAT_FORM_DATA({ 'token': props.match.params.token }))
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        // toast.success(response.data.response.message)
                        setLoading(false)
                    } else {
                        toast.error(response.data.response.message)
                        history.push('/auth/forgot-password')
                    }
                } else {
                    toast.error("Invalid token or token expired")
                    history.push('/auth/forgot-password')
                }
                setIs_submitted(false)
            });
    }, []);

    const submitForm = (formdata) => {
        setIs_submitted(true)
        axios.post(APIS.RESETPASSWORD, FORMAT_FORM_DATA(formdata))
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        toast.success(response.data.response.message)
                        history.push('/auth/login');
                    } else {
                        toast.error(response.data.response.message)
                    }
                }
                setIs_submitted(false)
            });
    }

    return (
        <Suspense>
            <Layout>
                <section className="login-page">
                <div className="container-fluid title-inner-card">
                                    <div className="all-page-title">
                                        <h2>Reset Password</h2>
                                    </div>
                                </div>
                    <div className="container py-md-80 py-50 container-maxWidth">
                        {
                            loading ?
                                <div className="loading">
                                    <ReactImageFallback
                                        src={require('../../../assets/img/loading.gif').default}
                                        fallbackImage={require('../../../assets/img/no-image.png').default}
                                        initialImage={require('../../../assets/img/loading.gif').default}
                                        alt="no image"
                                        style={{ width: '30px' }}
                                        className="" />
                                </div>
                                :
                                <div className="row">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6">
                                        <form method="POST" onSubmit={handleSubmit(submitForm)}>
                                            <input type="hidden" name="token" ref={register({
                                                required: true
                                            })} value={props.match.params.token} />
                                            <div className="form-group">
                                                <input type="password" name="password" className="form-control" id="password" placeholder="Password" ref={register({
                                                    required: true
                                                })} />
                                                {errors.password && <label className="text-danger">* This field is required</label>}
                                            </div>
                                            <div className="form-group">
                                                <input type="password" name="confirm_password" className="form-control" id="confirm_password" placeholder="Confirm Password" ref={register({
                                                    required: true,
                                                    validate: value =>
                                                        value === password.current || "Confirm Password did not match"
                                                })} />
                                                {errors.confirm_password && <label className="text-danger">* {errors.confirm_password.message ? errors.confirm_password.message : 'This field is required'}</label>}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="text-center"><input className="btn btn-warning coyote-btn form-control" type="submit" name="submit" disabled={is_submitted} value={is_submitted ? 'Please Wait ...' : 'Submit'} /></div>
                                                </div>
                                                <div className="col-md-6 d-flex justify-content-end align-items-center"><NavLink to="/auth/login" className="pr-10">Login</NavLink> <a href="#">|</a> <NavLink to="/auth/register" className="pl-10"> Register</NavLink></div>
                                            </div>

                                        </form>
                                    </div>
                                    <div className="col-md-3"></div>
                                </div>
                        }
                    </div>
                    <br />
                </section>
            </Layout>
        </Suspense>
    )
};

export default withRouter(ResetPassword);