import React, {
  Component,
  Suspense,
  useState,
  useEffect,
  Fragment,
} from "react";
import * as RBT from "react-bootstrap";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_STATUS } from "../../../config/constant";
import { APIS } from "../config/constant";
import LatestProductSingle from "../component/LatestProductSingle";
import ContentLoader from "react-content-loader";

const LatestProductList = (props) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const product_settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    document.querySelector("body").scrollIntoView();
    setLoading(true);
    axios.get(APIS.LATEST_PRODUCTS).then((response) => {
      if (response.data.status == API_STATUS.OK) {
        if (response.data.response.result) {
          setLoading(false);
          setProducts(
            response.data.response.data ? response.data.response.data : []
          );
        } else {
          setLoading(false);
          setProducts([]);
        }
      }
    });
  }, []);

  console.log(products);

  return (
    <section className="product-container">
      <div className="product-container-slider">
        {loading ? (
          <ContentLoader
            speed={2}
            width={"100%"}
            height={"100%"}
            viewBox="0 0 600 600"
            backgroundColor="#f3f3f3"
            foregroundColor="#e8e3e3"
            {...props}
          >
            <rect x="23" y="156" rx="2" ry="2" width="92" height="7" />
            <rect x="47" y="172" rx="2" ry="2" width="44" height="6" />
            <rect x="7" y="14" rx="2" ry="2" width="132" height="132" />
            <rect x="170" y="158" rx="2" ry="2" width="92" height="7" />
            <rect x="194" y="174" rx="2" ry="2" width="44" height="6" />
            <rect x="154" y="16" rx="2" ry="2" width="132" height="132" />
            <rect x="316" y="158" rx="2" ry="2" width="92" height="7" />
            <rect x="340" y="174" rx="2" ry="2" width="44" height="6" />
            <rect x="300" y="16" rx="2" ry="2" width="132" height="132" />
            <rect x="463" y="160" rx="2" ry="2" width="92" height="7" />
            <rect x="487" y="176" rx="2" ry="2" width="44" height="6" />
            <rect x="447" y="18" rx="2" ry="2" width="132" height="132" />
          </ContentLoader>
        ) : (
          <Fragment>
            {!products || products.length == 0 ? (
              <div className="no-data-found text-center">
                {products.length > 0 ? "" : "No Data Found"}{" "}
              </div>
            ) : (
              <Fragment>
                <Slider {...product_settings}>
                  {products.map((products_single, products_single_index) => {
                    return (
                      <LatestProductSingle
                        productsSingle={products_single}
                        key={products_single.id}
                      />
                    );
                  })}
                </Slider>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </section>
  );
};

export default LatestProductList;
