import React, { Fragment, useEffect, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { APIS } from "../config/constant";
import { API_STATUS, FORMAT_FORM_DATA } from "../../../config/constant";
import CheckoutContext from "../contexts/CheckoutServiceProvider";

function CheckoutBillingShipping() {
  const { register, errors, handleSubmit, setValue } = useForm();
  const {
    setStep,
    billing_shipping_data,
    setBilling_Shipping_data,
    countries,
    shipping_same_as_billing,
    setShipping_same_as_billing,
    billing_province_list,
    setBilling_province_list,
    shipping_province_list,
    setShipping_province_list,
    user_billing_address,
    user_shipping_address,
  } = useContext(CheckoutContext);

  useEffect(() => {
    document.querySelector("body").scrollIntoView();
  }, []);

  useEffect(() => {
    const setBillingValues = () => {
      setValue(
        "billing_first_name",
        billing_shipping_data.billing_first_name ??
          user_billing_address.first_name ??
          ""
      );
      setValue(
        "billing_last_name",
        billing_shipping_data.billing_last_name ??
          user_billing_address.last_name ??
          ""
      );
      setValue(
        "billing_email",
        billing_shipping_data.billing_email ?? user_billing_address.email ?? ""
      );
      setValue(
        "billing_phone",
        billing_shipping_data.billing_phone ??
          user_billing_address.telephone ??
          ""
      );
      setValue(
        "billing_address_1",
        billing_shipping_data.billing_address_1 ??
          user_billing_address.address1 ??
          ""
      );
      setValue(
        "billing_address_2",
        billing_shipping_data.billing_address_2 ??
          user_billing_address.address2 ??
          ""
      );
      setValue(
        "billing_country",
        billing_shipping_data.billing_country ??
          user_billing_address.country ??
          "173"
      );
      setValue(
        "billing_country_text",
        billing_shipping_data.billing_country_text ??
          user_billing_address.country_text ??
          "Canada"
      );

      setTimeout(() => {
        setValue(
          "billing_province",
          billing_shipping_data.billing_province ??
            user_billing_address.province ??
            ""
        );
        setValue(
          "billing_province_text",
          billing_shipping_data.billing_province_text ??
            user_billing_address.province_text ??
            ""
        );
        setValue(
          "billing_international_province",
          billing_shipping_data.billing_province_text ??
            user_billing_address.province_text ??
            ""
        );
      }, 500);

      setValue(
        "billing_city",
        billing_shipping_data.billing_city ?? user_billing_address.city ?? ""
      );
      setValue(
        "billing_postcode",
        billing_shipping_data.billing_postcode ??
          user_billing_address.postal_code ??
          ""
      );
    };

    const setShippingValues = () => {
      setValue(
        "shipping_first_name",
        billing_shipping_data.shipping_first_name ??
          user_shipping_address.first_name ??
          ""
      );
      setValue(
        "shipping_last_name",
        billing_shipping_data.shipping_last_name ??
          user_shipping_address.last_name ??
          ""
      );
      setValue(
        "shipping_email",
        billing_shipping_data.shipping_email ??
          user_shipping_address.email ??
          ""
      );
      setValue(
        "shipping_phone",
        billing_shipping_data.shipping_phone ??
          user_shipping_address.telephone ??
          ""
      );
      setValue(
        "shipping_address_1",
        billing_shipping_data.shipping_address_1 ??
          user_shipping_address.address1 ??
          ""
      );
      setValue(
        "shipping_address_2",
        billing_shipping_data.shipping_address_2 ??
          user_shipping_address.address2 ??
          ""
      );
      setValue(
        "shipping_country",
        billing_shipping_data.shipping_country ??
          user_shipping_address.country ??
          "173"
      );
      setValue(
        "shipping_country_text",
        billing_shipping_data.shipping_country_text ??
          user_shipping_address.country_text ??
          "Canada"
      );

      setTimeout(() => {
        setValue(
          "shipping_province",
          billing_shipping_data.shipping_province ??
            user_shipping_address.province ??
            ""
        );
        setValue(
          "shipping_province_text",
          billing_shipping_data.shipping_province_text ??
            user_shipping_address.province_text ??
            ""
        );
        setValue(
          "shipping_international_province",
          billing_shipping_data.shipping_province_text ??
            user_shipping_address.province_text ??
            ""
        );
      }, 500);

      setValue(
        "shipping_city",
        billing_shipping_data.shipping_city ?? user_shipping_address.city ?? ""
      );
      setValue(
        "shipping_postcode",
        billing_shipping_data.shipping_postcode ??
          user_shipping_address.postal_code ??
          ""
      );
    };

    setBillingValues();
    setShippingValues();

    const billingCountry = document.getElementById("billing_country");
    const shippingSameAsBilling = document.getElementById(
      "shipping_same_as_billing"
    );
    const changeEvent = new Event("change", {
      bubbles: true,
      cancelable: true,
    });
    billingCountry.dispatchEvent(changeEvent);

    shippingSameAsBilling.addEventListener("change", function () {
      if (!shippingSameAsBilling.checked) {
        const shippingCountry = document.getElementById("shipping_country");
        const changeEvent1 = new Event("change", {
          bubbles: true,
          cancelable: true,
        });
        setValue(
          "shipping_country",
          billing_shipping_data.shipping_country ??
            user_shipping_address.country ??
            "173"
        );
        setValue(
          "shipping_country_text",
          billing_shipping_data.shipping_country_text ??
            user_shipping_address.country_text ??
            "Canada"
        );
        shippingCountry.dispatchEvent(changeEvent1);
      }
    });
  }, [
    user_billing_address,
    user_shipping_address,
    billing_shipping_data,
    setValue,
  ]);

  const updateProvince = async (type = "billing", country_id) => {
    if (type === "billing") {
      setBilling_province_list({});
    } else {
      setShipping_province_list({});
    }

    try {
      const response = await axios.get(`${APIS.PROVINCELIST}/${country_id}`);
      if (
        response.data.status === API_STATUS.OK &&
        response.data.response.result
      ) {
        if (type === "billing") {
          setBilling_province_list(response.data.response.data);
          if (country_id === "173") {
            setValue("billing_province", "187");
            setValue("billing_province_text", "Alberta");
            setValue("billing_international_province", "Alberta");
          }
        } else {
          setShipping_province_list(response.data.response.data);
          if (country_id === "173") {
            setValue("shipping_province", "187");
            setValue("shipping_province_text", "Alberta");
            setValue("shipping_international_province", "Alberta");
          }
        }
      }
    } catch (error) {
      console.error("Error fetching province list:", error);
    }
  };

  const provinceHandler = (e, type = "billing") => {
    const province_text = e.target.options[e.target.selectedIndex].text;
    setValue(`${type}_province_text`, province_text);
    setValue(`${type}_international_province`, province_text);
  };

  const countryAction = (e, type) => {
    const country_text = e.target.options[e.target.selectedIndex].text;
    setValue(`${type}_country_text`, country_text);
    updateProvince(type, e.target.value);
  };

  const goToNext = (formdata) => {
    setBilling_Shipping_data(formdata);
    setStep(3);
  };

  return (
    <Fragment>
      <h3 className="inner-page-sub-title font-2">Billing Details</h3>
      <form
        method="post"
        onSubmit={handleSubmit(goToNext)}
        className="billing-details-form"
      >
        <div className="form-row">
          <div className="form-group col-md-6 columnGap">
            <input
              type="text"
              className="form-control"
              id="billing_first_name"
              name="billing_first_name"
              ref={register({ required: true })}
              placeholder="First Name"
            />
            {errors.billing_first_name && (
              <label className="text-danger">* This field is required</label>
            )}
          </div>
          <div className="form-group col-md-6">
            <input
              type="text"
              className="form-control"
              id="billing_last_name"
              name="billing_last_name"
              ref={register({ required: true })}
              placeholder="Last Name"
            />
            {errors.billing_last_name && (
              <label className="text-danger">* This field is required</label>
            )}
          </div>
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            id="billing_email"
            name="billing_email"
            ref={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address",
              },
            })}
            placeholder="Email"
          />
          {errors.billing_email && (
            <label className="text-danger">
              *{" "}
              {errors.billing_email.message
                ? errors.billing_email.message
                : "This field is required"}
            </label>
          )}
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            id="billing_phone"
            name="billing_phone"
            ref={register({ required: true })}
            placeholder="Phone"
          />
          {errors.billing_phone && (
            <label className="text-danger">* This field is required</label>
          )}
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            id="billing_address_1"
            name="billing_address_1"
            ref={register({ required: true })}
            placeholder="Address Line 1"
          />
          {errors.billing_address_1 && (
            <label className="text-danger">* This field is required</label>
          )}
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            id="billing_address_2"
            name="billing_address_2"
            ref={register()}
            placeholder="Address Line 2"
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 columnGap">
            <select
              id="billing_country"
              className="form-control"
              name="billing_country"
              ref={register({ required: true })}
              onChange={(e) => countryAction(e, "billing")}
            >
              <option value="">Country</option>
              {countries &&
                Object.keys(countries).map((countries_index) => (
                  <option
                    key={`country_id_${countries_index}`}
                    value={countries_index}
                  >
                    {countries[countries_index]}
                  </option>
                ))}
            </select>
            {errors.billing_country && (
              <label className="text-danger">* This field is required</label>
            )}
            <input
              type="hidden"
              id="billing_country_text"
              name="billing_country_text"
              ref={register()}
            />
          </div>
          <div className="form-group col-md-6">
            <select
              onChange={(e) => provinceHandler(e, "billing")}
              id="billing_province"
              className="form-control"
              name="billing_province"
              ref={register({
                required: Object.keys(billing_province_list).length > 0,
              })}
            >
              <option value="">State/Province</option>
              {Object.keys(billing_province_list).map(
                (billing_province_list_index) => (
                  <option
                    key={`billing_province_list_id_${billing_province_list_index}`}
                    value={billing_province_list_index}
                  >
                    {billing_province_list[billing_province_list_index]}
                  </option>
                )
              )}
            </select>
            {errors.billing_province && (
              <label className="text-danger">* This field is required</label>
            )}
            <input
              type="hidden"
              id="billing_province_text"
              name="billing_province_text"
              ref={register()}
            />
            <input
              type="hidden"
              id="billing_international_province"
              name="billing_international_province"
              ref={register()}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 columnGap">
            <input
              type="text"
              className="form-control"
              id="billing_city"
              name="billing_city"
              ref={register({ required: true })}
              placeholder="City"
            />
            {errors.billing_city && (
              <label className="text-danger">* This field is required</label>
            )}
          </div>
          <div className="form-group col-md-6">
            <input
              type="text"
              className="form-control"
              id="billing_postcode"
              name="billing_postcode"
              ref={register({ required: true })}
              placeholder="Postcode"
            />
            {errors.billing_postcode && (
              <label className="text-danger">* This field is required</label>
            )}
          </div>
        </div>
        <hr className="form-gap" />

        <h3 className="inner-page-sub-title font-2">Shipping Details</h3>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="shipping_same_as_billing"
            name="shipping_same_as_billing"
            checked={shipping_same_as_billing}
            onChange={(e) => setShipping_same_as_billing(e.target.checked)}
          />
          <label
            className="form-check-label"
            htmlFor="shipping_same_as_billing"
          >
            Shipping same as Billing
          </label>
        </div>
        <br />
        {!shipping_same_as_billing && (
          <Fragment>
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="shipping_first_name"
                  name="shipping_first_name"
                  ref={register({ required: true })}
                  placeholder="First Name"
                />
                {errors.shipping_first_name && (
                  <label className="text-danger">
                    * This field is required
                  </label>
                )}
              </div>
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="shipping_last_name"
                  name="shipping_last_name"
                  ref={register({ required: true })}
                  placeholder="Last Name"
                />
                {errors.shipping_last_name && (
                  <label className="text-danger">
                    * This field is required
                  </label>
                )}
              </div>
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="shipping_email"
                name="shipping_email"
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
                placeholder="Email"
              />
              {errors.shipping_email && (
                <label className="text-danger">
                  *{" "}
                  {errors.shipping_email.message
                    ? errors.shipping_email.message
                    : "This field is required"}
                </label>
              )}
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="shipping_phone"
                name="shipping_phone"
                ref={register({ required: true })}
                placeholder="Phone"
              />
              {errors.shipping_phone && (
                <label className="text-danger">* This field is required</label>
              )}
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="shipping_address_1"
                name="shipping_address_1"
                ref={register({ required: true })}
                placeholder="Address Line 1"
              />
              {errors.shipping_address_1 && (
                <label className="text-danger">* This field is required</label>
              )}
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="shipping_address_2"
                name="shipping_address_2"
                ref={register()}
                placeholder="Address Line 2"
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <select
                  id="shipping_country"
                  className="form-control"
                  name="shipping_country"
                  ref={register({ required: true })}
                  onChange={(e) => countryAction(e, "shipping")}
                >
                  <option value="">Country</option>
                  {countries &&
                    Object.keys(countries).map((countries_index) => (
                      <option
                        key={`country_id_${countries_index}`}
                        value={countries_index}
                      >
                        {countries[countries_index]}
                      </option>
                    ))}
                </select>
                {errors.shipping_country && (
                  <label className="text-danger">
                    * This field is required
                  </label>
                )}
                <input
                  type="hidden"
                  id="shipping_country_text"
                  name="shipping_country_text"
                  ref={register()}
                />
              </div>
              <div className="form-group col-md-6">
                <select
                  onChange={(e) => provinceHandler(e, "shipping")}
                  id="shipping_province"
                  className="form-control"
                  name="shipping_province"
                  ref={register({ required: true })}
                >
                  <option value="">State/Province</option>
                  {Object.keys(shipping_province_list).map(
                    (shipping_province_list_index) => (
                      <option
                        key={`shipping_province_list_id_${shipping_province_list_index}`}
                        value={shipping_province_list_index}
                      >
                        {shipping_province_list[shipping_province_list_index]}
                      </option>
                    )
                  )}
                </select>
                {errors.shipping_province && (
                  <label className="text-danger">
                    * This field is required
                  </label>
                )}
                <input
                  type="hidden"
                  id="shipping_province_text"
                  name="shipping_province_text"
                  ref={register()}
                />
                <input
                  type="hidden"
                  id="shipping_international_province"
                  name="shipping_international_province"
                  ref={register()}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="shipping_city"
                  name="shipping_city"
                  ref={register({ required: true })}
                  placeholder="City"
                />
                {errors.shipping_city && (
                  <label className="text-danger">
                    * This field is required
                  </label>
                )}
              </div>
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="shipping_postcode"
                  name="shipping_postcode"
                  ref={register({ required: true })}
                  placeholder="Postcode"
                />
                {errors.shipping_postcode && (
                  <label className="text-danger">
                    * This field is required
                  </label>
                )}
              </div>
            </div>
          </Fragment>
        )}
        <div className="checkout-next-prev text-right py-md-30 py-20">
          <button
            type="button"
            className="btn btn-warning coyote-btn btn-gap"
            onClick={() => setStep(1)}
          >
            Previous
          </button>
          <button type="submit" className="btn btn-warning coyote-btn">
            Next
          </button>
        </div>
      </form>
    </Fragment>
  );
}

export default CheckoutBillingShipping;
