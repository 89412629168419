import React, { Fragment, Suspense, useEffect, useState } from "react";
import ReactImageFallback from "react-image-fallback";
import { NavLink } from 'react-router-dom';
import { CONFIG } from "../../../config/settings";

const ProductCategoryList = (props) => {
    console.log( "Categories: " , props.categories);
    const [productsSingle, setProductsSingle] = useState({});

    const [ has_category ] = useState('1');

    return (
        <div>
            { !has_category || has_category==0 ? ( "" ) : (

                <div className="row">
                    { props.categories &&  props.categories.length > 0 &&
                    <Fragment>
                        {props.categories.map( (  cat_single, cat_index ) => {

                            let cat_image = require('../../../assets/img/category-bg.jpg').default;
                            if (cat_single.others && cat_single.others.feature_image &&  cat_single.others.feature_image.medium_img && cat_single.others.feature_image.medium_img != '' ) {
                                cat_image = cat_single.others.feature_image.medium_img;
                                return(
                                    <div key={'cat_' + cat_single.id} className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 cat-link-box">
                                        <a href={CONFIG.BASE_URL + "/products?cat_id=" + cat_single.id} className="thumbnail">
                                            <img className="img-fluid" src={cat_image} alt="image" />
                                        </a>
                                    </div>
                                )
                            }else{
                                return(
                                    <div key={'cat_' + cat_single.id} className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 cat-link-box">
                                        <a href={CONFIG.BASE_URL + "/products?cat_id=" + cat_single.id} className="thumbnail">
                                            <p>{cat_single.name}</p>
                                            <img className="img-fluid" src={cat_image} alt="image" />
                                        </a>
                                    </div>
                                )
                            }
                            

                        } )
                        }
                    </Fragment>
                    }

                </div>

            )  }
        </div>
    )
}

export default ProductCategoryList;