export const TYPES = {
    /**
     * Define types of redux here
     * This is to maintain default types all over the project e.g.
     * { SAMPLE_TYPE: "SAMPLE_TYPE" }
     *
     * @author Fahad Bin Wahid <fahad@instabd.com>
     */

    // Customer data
    INIT_USER_AUTHENTICATION: 'INIT_USER_AUTHENTICATION',
    UPDATE_CART: 'UPDATE_CART',
    REMOVE_FROM_CART: 'REMOVE_FROM_CART',
    DELETE_CART: 'DELETE_CART',
    UPDATE_WISHLIST: 'UPDATE_WISHLIST',
    REMOVE_FROM_WISHLIST: 'REMOVE_FROM_WISHLIST',
    DELETE_WISHLIST: 'DELETE_WISHLIST',
}
