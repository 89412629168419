import React, { Fragment, useContext, useEffect, useState } from "react";
import CheckoutContext from "../contexts/CheckoutServiceProvider";
import { useSelector } from "react-redux";
import CartListSingleMini from "./CartListSingleMini";
import { NavLink, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_STORAGE, SETTINGS } from "../../../config/constant";

function ChechoutMiniCart() {
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart[0]);
  const history = useHistory();
  const [defaultCurrency, setDefaultCurrency] = useState("");

  useEffect(() => {
    document.querySelector("body").scrollIntoView();

    const settings = GET_STORAGE(SETTINGS);
    if (settings) {
      const settingsParsed = JSON.parse(settings);
      if (
        settingsParsed.shopSettingData &&
        settingsParsed.shopSettingData.product_options &&
        settingsParsed.shopSettingData.product_options.default_currency
      ) {
        setDefaultCurrency(
          settingsParsed.shopSettingData.product_options.default_currency
        );
      }
    }
  }, []);

  const {
    setStep,
    continue_as_guest,
    setContinue_as_guest,
    needToUpdate,
    SetNeedToUpdate,
    updateCart,
  } = useContext(CheckoutContext);

  let total_price = 0;

  const goToNext = () => {
    if (!continue_as_guest) {
      history.push("/auth/login");
    } else if (cart && Object.keys(cart).length <= 0) {
      toast.error("Please add some products on cart");
      history.push("/products");
    } else {
      setStep(2);
    }
  };

  return (
    <Fragment>
      {auth.token ? (
        <Fragment>{/* Curently logged in as {auth.user.name} */}</Fragment>
      ) : (
        <Fragment>
          <div className="checkout-header-step-1">
            <p className="text-center">
              {" "}
              You are not logged in. Please{" "}
              <NavLink to="/auth/login?redirect=checkout">
                login
              </NavLink> Or{" "}
              <NavLink to="/auth/register?redirect=checkout">Register</NavLink>
            </p>
            <div className="form-check text-center">
              <input
                className="form-check-input"
                type="checkbox"
                id="continue_as_guest"
                name="continue_as_guest"
                checked={continue_as_guest ? true : false}
                onChange={(e) =>
                  setContinue_as_guest(e.target.checked ? true : false)
                }
                readOnly
              />
              <label className="form-check-label" htmlFor="continue_as_guest">
                Continue as guest
              </label>
            </div>
          </div>
        </Fragment>
      )}
      <h3 className="inner-page-sub-title">Your Order</h3>
      <div className="table-responsive py-30">
        <table className="table">
          <colgroup>
            <col style={{ width: "10%" }} />
            <col style={{ width: "35%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "10%" }} />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">Image</th>
              <th scope="col">Name</th>
              <th scope="col">Quantity</th>
              <th scope="col">Price</th>
              <th scope="col">SubTotal</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {cart && Object.keys(cart).length > 0 ? (
              <Fragment>
                {Object.keys(cart).map((cart_single, index) => {
                  total_price =
                    total_price +
                    parseFloat(cart[cart_single].price_with_variation) *
                      parseInt(cart[cart_single].c_quantity);
                  return (
                    <CartListSingleMini
                      cart_single={cart[cart_single]}
                      key={cart[cart_single].slug}
                      SetNeedToUpdate={SetNeedToUpdate}
                    />
                  );
                })}
              </Fragment>
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  Cart is empty
                </td>
              </tr>
            )}
            <tr>
              <td colSpan="4" className="text-right">
                {needToUpdate ? (
                  <Fragment>
                    <button
                      type="button"
                      className="btn btn-warning coyote-btn"
                      onClick={updateCart}
                    >
                      Update Cart
                    </button>{" "}
                    &nbsp;
                  </Fragment>
                ) : (
                  ""
                )}

                <b>Total</b>
              </td>
              <td>
                {defaultCurrency} {parseFloat(total_price).toFixed(2)}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="checkout-next-prev text-right py-md-30 py-20">
        <button
          type="button"
          className="btn btn-warning coyote-btn"
          onClick={() => goToNext()}
        >
          Next
        </button>
      </div>
    </Fragment>
  );
}

export default ChechoutMiniCart;
