import React, { Fragment, Suspense, useState, useEffect } from 'react';
import Layout from '../../../utils/templates/layout/default';
import ReactImageFallback from 'react-image-fallback'
import { useHistory } from 'react-router-dom'
import Parser from 'html-react-parser';
import { toast } from 'react-toastify';
import axios from 'axios';
import { APIS } from '../config/constant';
import { CONFIG } from '../../../config/settings';
import { API_STATUS, TITLE, randomIntFromInterval } from '../../../config/constant';
import PageNotFound from '../../../utils/common/PageNotFound'
import ContactUsPage from '../component/ContactUsPage'
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import { ManageCart, RemoveFromCart } from '../../../redux/actions/cart';

const ProcessCart = (props) => {
    const [meta_title, setMeta_title] = useState(TITLE);
    const [meta_description, setMeta_description] = useState(TITLE);
    const [loading, setLoading] = useState(true);
    const [pageData, setPageData] = useState(false);
    const history = useHistory()

    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true)

        const c_params = new URLSearchParams(window.location.search);
        const c_data = c_params.get('data');
        const decoded_data = JSON.parse(decodeURI(atob(c_data)));
        if (decoded_data) {
            decoded_data.base_product_id = decoded_data.id;
            decoded_data.id = randomIntFromInterval(100, 999) + '-' + decoded_data.id;
            decoded_data.type = 'custom';
            dispatch(ManageCart(false, decoded_data, decoded_data.options && decoded_data.options.quantity ? decoded_data.options.quantity : 1, decoded_data.options ? decoded_data.options : {}));
            history.push('/cart')
            // setTimeout(() => {
            //     window.location.href = decoded_data.ref
            // }, 2000)
        } else {
            toast.error("Invalid Cart Data")
            history.push('/')
        }
    }, [])

    return (
        <Suspense>
            <Layout>
                <section className="pages-details">
                    <Helmet>
                        <title>{meta_title}</title>
                        <meta name="description" content={meta_description} />
                        <link rel="canonical" href={`${CONFIG.BASE_URL}/${props.match.params.slug}`} />
                    </Helmet>


                    <div className="loading">
                        <ReactImageFallback
                            src={require('../../../assets/img/loading.gif').default}
                            fallbackImage={require('../../../assets/img/no-image.png').default}
                            initialImage={require('../../../assets/img/loading.gif').default}
                            alt="no image"
                            style={{ width: '30px' }}
                            className="" />
                    </div>

                </section>
            </Layout>
        </Suspense>
    )
}

export default ProcessCart

