import React, { Fragment, Suspense, useState, useEffect } from "react";
import Layout from "../../../utils/templates/layout/default";
import ReactImageFallback from "react-image-fallback";
import Parser from "html-react-parser";
import axios from "axios";
import { APIS } from "../config/constant";
import { CONFIG } from "../../../config/settings";
import { API_STATUS, TITLE } from "../../../config/constant";
import PageNotFound from "../../../utils/common/PageNotFound";
import ContactUsPage from "../component/ContactUsPage";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";

const PageInnerContent = (props) => {
  const [meta_title, setMeta_title] = useState(TITLE);
  const [meta_description, setMeta_description] = useState(TITLE);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState(false);
  const [activeService, setActiveService] = useState({});
  const [imageBasePath, setImageBasePath] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [childPageInfo, setChildPageInfo] = useState([]);
  const [title, setTitle] = useState("");
  const [banner, setBanner] = useState(null);

  const partner_slider_settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 3500,
    speed: 1300,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (props.matchParams.slug) {
      setPageData(false);
      setActiveService(props.matchParams.slug);
      axios
        .get(APIS.PAGES + "?slug=" + props.matchParams.slug)
        .then((response) => {
          if (response.data.status === API_STATUS.OK) {
            if (response.data.response.result) {
              setPageData(response.data.response.data);
              setTitle(response.data.response.data?.title);
              setLoading(false);
              //seo
              if (response.data.response.data.others) {
                setMeta_title(response.data.response.data?.others?.meta_title);
                setMeta_description(
                  response.data.response.data?.others?.meta_description
                );
                setBanner(
                  response.data.response.data?.others?.feature_image?.main_img
                );
                setShortDescription(
                  response.data.response.data?.others?.short_description
                );
              }

              if (response.data.response.data?.parent_id === 33) {
                setChildPageInfo(response.data.response.data?.children);
              }
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        });
    } else {
      toast.error("No Data Found");
    }
  }, [props]);

  const goToMenu = () => {
    document.querySelector("#services-slider").scrollIntoView({
      behavior: "smooth",
    });
    setLoading(true);
  };

  return (
    <Suspense>
      <Layout>
        <section className="pages-details">
          <Helmet>
            <title>{meta_title}</title>
            <meta name="title" content={meta_title} />
            <meta name="description" content={meta_description} />
            <link
              rel="canonical"
              href={`${CONFIG.BASE_URL}/${props.matchParams.slug}`}
            />
          </Helmet>

          {loading ? (
            <div className="loading">
              <ReactImageFallback
                src={require("../../../assets/img/loading.gif").default}
                fallbackImage={
                  require("../../../assets/img/no-image.png").default
                }
                initialImage={
                  require("../../../assets/img/loading.gif").default
                }
                alt="no image"
                style={{ width: "30px" }}
                className=""
              />
            </div>
          ) : (
            <Fragment>
              {pageData ? (
                <Fragment>
                  {childPageInfo.length > 0 ? (
                    <Fragment>
                      <div className="md-services-carousal-area">
                        <div className="container-fluid">
                          <div className="services-slider" id="services-slider">
                            <Slider {...partner_slider_settings}>
                              {childPageInfo.map(
                                (childPage, childPageInfoIndex) => {
                                  return (
                                    <div
                                      className="services-slider-single"
                                      key={childPage.slug}
                                      onClick={goToMenu}
                                    >
                                      <NavLink to={`/${childPage.slug}`}>
                                        <div
                                          className={`object-slider-content ${
                                            childPage.slug === activeService
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <img
                                            src={`${CONFIG.IMAGE_BASE_URL}/files/Pages/${childPage.others?.feature_image?.main_img}`}
                                            alt={childPage.title}
                                          />
                                        </div>
                                        <strong>{childPage.menu_title}</strong>
                                      </NavLink>
                                    </div>
                                  );
                                }
                              )}
                            </Slider>
                          </div>
                        </div>
                      </div>
                      <div className="md-s-feature-c-area">
                        <div className="container">
                          <div class="row text-left align-items-top pb-md-5">
                            <div class="col-md-12 pt-2 text-center font-weight-bold">
                              <h1 class="font-weight-bold">{pageData.title}</h1>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className={`col-md-12 pages-description-wrapper ${pageData.slug}-wrapper`}
                            >
                              {pageData.description &&
                              pageData.description !== "" ? (
                                Parser(pageData.description)
                              ) : (
                                <div className="text-center"></div>
                              )}
                            </div>
                            <div className="col-md-12">
                              <div class="md-s-feature-c-right text-center">
                                <a
                                  class="md-c-btn mt-30 mb-30"
                                  href="https://www.vagaro.com//Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVF6WeNQLIY+Li9dMS+RYHnWtHWWkjafX44xixde8hhv0rTfRGK5m3DzMnHAOUrEYRUVcgHmdrjxGNzc3mGO11XZUACrTaiQkMa3vQqWlSnrzf0sECk0nC1WbIW5y8vk0iJkuywHnd7WBQ1eBYEShu+HD2hNC0D0wuU027Pm5EwBFCkT/g/p0U5YNEYG2h7f1coLzjpt44EpvmXYJTq5pJCx+dQcbHd9x69OxJhCecE6Jd0riC61bB4nyw1aOy2U0/RqJxDCGBfkJ11y0gfY0eMgtdSTYx0co29VREnjunnXQAofO/+I0zyMyfJcoW2aycQ=="
                                  rel="noopener noreferrer"
                                >
                                  Book Now!
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="container-fluid title-inner-card">
                        <div className="all-page-title">
                          <h1 class="font-weight-bold">{pageData.title}</h1>
                        </div>
                      </div>
                      <div className="container py-md-80 py-50 container-maxWidth">
                        {props.matchParams.slug === "contact-us" ? (
                          <ContactUsPage pageData={pageData} />
                        ) : (
                          <div className="row">
                            <div className="col-md-12 pages-description-wrapper">
                              {pageData.description &&
                              pageData.description !== "" ? (
                                Parser(pageData.description)
                              ) : (
                                <div className="text-center"></div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              ) : (
                <PageNotFound />
              )}
            </Fragment>
          )}
        </section>
      </Layout>
    </Suspense>
  );
};

export default PageInnerContent;
