import Login from '../pages/login';
import Register from '../pages/register';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import VerifyRegister from '../pages/VerifyRegister';

export default [
    //route props
    { path: '/login', component: Login },
    { path: '/register', component: Register },
    { path: '/verify/:token', component: VerifyRegister },
    { path: '/forgot-password', component: ForgotPassword },
    { path: '/reset-password/:token', component: ResetPassword },
]