import { combineReducers } from 'redux';
import auth from './auth';
import cart from './cart';
import wishlist from './wishlist';

/**
 * Combine all the reducers from different modules to one
 *
 * @author Fahad Bin Wahid <fahad@instabd.com>
 */
const rootReducer = combineReducers({
    auth,
    cart,
    wishlist
});

export default rootReducer;
