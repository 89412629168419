/**
 * Cart class to add, remove, update cart items
 * This class mimics a singleton pattern
 *
 * @author Muiz Ahmed Khan <muiz@instabd.com>
 */

import { SET_STORAGE, GET_STORAGE, CART } from './../../config/constant';

class CartOperations {

    constructor() {

    }

    saveCart(cart_products) {
        SET_STORAGE(CART, JSON.stringify(cart_products));
    }

    addToCart(product, quantity = 0, options = [], totalPrice = 0) {
        var cart_products = GET_STORAGE(CART) ? JSON.parse(GET_STORAGE(CART)) : {};
        cart_products[product.slug] = {
            "name": product.name,
            "quantity": quantity,
            "price": totalPrice !== 0 ? totalPrice : product.price,
            "total_price": totalPrice !== 0 ? (totalPrice * quantity) : (product.price * quantity),
            "options": options,
            "slug": product.slug
        };

        SET_STORAGE(CART, JSON.stringify(cart_products));
    };

    removeFromCart(product) {
        var cart_products = GET_STORAGE(CART) ? JSON.parse(GET_STORAGE(CART)) : {};
        delete (cart_products[product.slug])
        SET_STORAGE(CART, JSON.stringify(cart_products));
    };

}

export default new CartOperations();