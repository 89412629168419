import React, { Fragment, Suspense, useEffect, useState } from "react";
import Layout from "../../../utils/templates/layout/default";
import { useSelector, useDispatch } from "react-redux";
import { UpdateCartFromSession, deleteCart } from "../../../redux/actions/cart";
import ReactImageFallback from "react-image-fallback";
import { APIS } from "../config/constant";
import {
  API_STATUS,
  FORMAT_FORM_DATA,
  GET_STORAGE,
  SETTINGS,
} from "../../../config/constant";
import axios from "axios";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ChechoutMiniCart from "../component/ChechoutMiniCart";
import CheckoutBillingShipping from "../component/CheckoutBillingShipping";
import CheckoutPayment from "../component/CheckoutPayment";
import { CheckoutServiceProvider } from "../contexts/CheckoutServiceProvider";

const CheckoutPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [is_submitted, setIs_submitted] = useState(false);
  const [countries, setCountries] = useState([]);
  const [step, setStep] = useState(1);
  const [disable_shopping_cart, setDisable_shopping_cart] = useState(false);
  const [continue_as_guest, setContinue_as_guest] = useState(true);
  const [billing_shipping_data, setBilling_Shipping_data] = useState({});
  const [user_billing_address, setUser_billing_address] = useState({});
  const [user_shipping_address, setUser_shipping_address] = useState({});
  const [shipping_same_as_billing, setShipping_same_as_billing] =
    useState(true);
  const [billing_province_list, setBilling_province_list] = useState({});
  const [shipping_province_list, setShipping_province_list] = useState({});
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeInfo, setPromoCodeInfo] = useState({});
  const [payment_gateway, setPayment_gateway] = useState("NONE"); //useState("COD");

  const [needToUpdate, SetNeedToUpdate] = useState(false);

  const updateCart = () => {
    SetNeedToUpdate(false);
    UpdateCartFromSession();
  };

  // cart input field control
  const [card_holder_name, setCard_holder_name] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [showCartError, setShowCartError] = useState(false);

  const cart = useSelector((state) => state.cart[0]);
  const [disableLastStep, setDisableLastStep] = useState(false);
  const [messageAfterPlaceOrder, setMessageAfterPlaceOrder] = useState("");
  const [alertMessageClass, setAlertMessageClass] = useState("");

  useEffect(() => {
    const settings = GET_STORAGE(SETTINGS);
    if (settings) {
      const settingsParsed = JSON.parse(settings);
      if (
        settingsParsed.shopSettingData &&
        settingsParsed.shopSettingData.theme_options &&
        settingsParsed.shopSettingData.theme_options.disable_shopping_cart
      ) {
        setDisable_shopping_cart(true);
        toast.error(
          "Currently Shopping cart & checkout functionality is disabled"
        );
        history.push("/");
      } else {
        if (cart && Object.keys(cart).length <= 0) {
          toast.error("Please add some products on cart");
          history.push("/products");
        }
      }
    }

    axios.get(APIS.COUNTRYLIST).then((response) => {
      if (response.data.status == API_STATUS.OK) {
        if (response.data.response.result) {
          setCountries(response.data.response.data);
        }
      }
    });

    axios.post(APIS.GETPROFILEINFO).then((response) => {
      if (response.data.status == API_STATUS.OK) {
        if (response.data.response.result) {
          const user_billing_address =
            response.data.response.data.customer.billing_address;
          setUser_billing_address(user_billing_address);
          updateProvince(
            "billing",
            user_billing_address.country,
            user_billing_address.province
          );

          setTimeout(() => {
            // setValue('billing_first_name', user_billing_address.first_name ?? '');
            // setValue('billing_last_name', user_billing_address.last_name ?? '');
            // setValue('billing_email', user_billing_address.email ?? '');
            // setValue('billing_phone', user_billing_address.telephone ?? '');
            // setValue('billing_address_1', user_billing_address.address1 ?? '');
            // setValue('billing_address_2', user_billing_address.address2 ?? '');
            // setValue('billing_country', user_billing_address.country ?? '');
            // setValue('billing_city', user_billing_address.city ?? '');
            // setValue('billing_postcode', user_billing_address.postal_code ?? '');
            // setBilling_first_name(user_billing_address.first_name ?? '')
            // setBilling_last_name(user_billing_address.last_name ?? '')
            // setBilling_email(user_billing_address.email ?? '')
            // setBilling_phone(user_billing_address.telephone ?? '')
            // setBilling_address_1(user_billing_address.address1 ?? '')
            // setBilling_address_2(user_billing_address.address2 ?? '')
            // setBilling_country(user_billing_address.country ?? '')
            // setBilling_province(user_billing_address.province ?? '')
            // setBilling_city(user_billing_address.city ?? '')
            // setBilling_postcode(user_billing_address.postal_code ?? '')
          }, 100);

          const user_shipping_address =
            response.data.response.data.customer.shipping_address;
          if (
            user_shipping_address &&
            Object.keys(user_shipping_address).length > 0
          ) {
            setUser_shipping_address(user_shipping_address);
            setShipping_same_as_billing(false);

            updateProvince(
              "shipping",
              user_shipping_address.country,
              user_shipping_address.province
            );

            setTimeout(() => {
              // setValue('shipping_first_name', user_shipping_address.first_name ?? '');
              // setValue('shipping_last_name', user_shipping_address.last_name ?? '');
              // setValue('shipping_email', user_shipping_address.email ?? '');
              // setValue('shipping_phone', user_shipping_address.telephone ?? '');
              // setValue('shipping_address_1', user_shipping_address.address1 ?? '');
              // setValue('shipping_address_2', user_shipping_address.address2 ?? '');
              // setValue('shipping_country', user_shipping_address.country ?? '');
              // setValue('shipping_city', user_shipping_address.city ?? '');
              // setValue('shipping_postcode', user_shipping_address.postal_code ?? '');
              // setShipping_first_name(user_shipping_address.first_name ?? '')
              // setShipping_last_name(user_shipping_address.last_name ?? '')
              // setShipping_email(user_shipping_address.email ?? '')
              // setShipping_phone(user_shipping_address.telephone ?? '')
              // setShipping_address_1(user_shipping_address.address1 ?? '')
              // setShipping_address_2(user_shipping_address.address2 ?? '')
              // setShipping_country(user_shipping_address.country ?? '')
              // setShipping_province(user_shipping_address.province ?? '')
              // setShipping_city(user_shipping_address.city ?? '')
              // setShipping_postcode(user_shipping_address.postal_code ?? '')
            }, 100);
          }
        }
      }
    });
  }, []);

  const updateProvince = async (
    type = "billing",
    country_id,
    default_value = false
  ) => {
    if (type == "billing") {
      setBilling_province_list({});
      // setBilling_city('')
      // setBilling_postcode('')
    } else {
      setShipping_province_list({});
      // setShipping_city('')
      // setShipping_postcode('')
    }
    await axios.get(APIS.PROVINCELIST + "/" + country_id).then((response) => {
      if (response.data.status == API_STATUS.OK) {
        if (response.data.response.result) {
          if (type == "billing") {
            setBilling_province_list(response.data.response.data);
            // if (default_value) {
            //     setValue('billing_province', default_value);
            // }
          } else {
            setShipping_province_list(response.data.response.data);
            // if (default_value) {
            //     setValue('shipping_province', default_value);
            // }
          }
        }
      }
    });
  };

  const handleCardNumberChange = (e) => {
    setCardNumber(e.target.value);
  };
  const handleCardExpiryChange = (e) => {
    setExpiry(e.target.value);
  };
  const handleCardCVCChange = (e) => {
    setCvc(e.target.value);
  };

  const placeOrder = (transaction = false) => {
    const cart_data = JSON.stringify(cart);
    if (cart_data) {
      let formdata = billing_shipping_data;
      formdata.promo_code = promoCode;
      formdata.cart = JSON.stringify(cart);
      formdata.shipping_same_as_billing = shipping_same_as_billing;
      formdata.payment_gateway = payment_gateway;
      formdata.cardNumber = cardNumber;
      formdata.expiry = expiry;
      formdata.cvc = cvc;
      formdata.transaction_id = transaction.id ?? "";
      formdata.transaction_status = transaction.status ?? "";
      formdata.transaction_data = transaction
        ? JSON.stringify(transaction)
        : "";

      // transaction_id: "5WC50110VF565900T"
      // transaction_status: "COMPLETED"

      // console.log("formdata", formdata);

      const newFormData = FORMAT_FORM_DATA(formdata);

      // console.log("newFormData", newFormData);

      if (
        payment_gateway == "MN" &&
        (cardNumber == "" || expiry == "" || cvc == "")
      ) {
        setShowCartError(true);
      } else {
        setIs_submitted(true);
        axios.post(APIS.PLACEORDER, newFormData).then((response) => {
          if (response.data.status == API_STATUS.OK) {
            if (response.data.response.result) {
              setDisableLastStep(true);
              setAlertMessageClass("alert-success");
              setMessageAfterPlaceOrder(response.data.response.message);
              // toast.success("Order placed successfully");
              dispatch(deleteCart());
              // history.push("/");
            } else {
              setDisableLastStep(true);
              setAlertMessageClass("alert-danger");
              setMessageAfterPlaceOrder(response.data.response.message);
            }
          } else {
            if (response.data && response.data.message) {
              // toast.error(response.data.message);
              setDisableLastStep(true);
              setAlertMessageClass("alert-danger");
              setMessageAfterPlaceOrder(response.data.message);
            } else {
              setDisableLastStep(true);
              setAlertMessageClass("alert-danger");
              setMessageAfterPlaceOrder("unknown error occured");
              // toast.error("unknown error occured");
            }
          }
          setIs_submitted(false);
        });
      }
    } else {
      toast.error("Current cart is empty");
      history.push("/products");
    }
  };

  return (
    <Layout>
      <Suspense>
        <section className="checkout-page">
          {is_submitted && (
            <div className="loading_overlay">
              <div className="loading">
                <ReactImageFallback
                  src={require("../../../assets/img/loading.gif").default}
                  fallbackImage={
                    require("../../../assets/img/no-image.png").default
                  }
                  initialImage={
                    require("../../../assets/img/loading.gif").default
                  }
                  alt="no image"
                  style={{ width: "30px" }}
                  className=""
                />
              </div>
            </div>
          )}
          <div className="container-fluid title-inner-card">
            <div className="all-page-title">
              <h2>Checkout</h2>
            </div>
          </div>

          <div className="container py-md-80 py-50">
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-8 payment-steps">
                <div>
                  <CheckoutServiceProvider
                    value={{
                      setStep,
                      countries,
                      continue_as_guest,
                      setContinue_as_guest,
                      needToUpdate,
                      SetNeedToUpdate,
                      updateCart,
                      billing_province_list,
                      setBilling_province_list,
                      shipping_province_list,
                      setShipping_province_list,
                      shipping_same_as_billing,
                      setShipping_same_as_billing,
                      billing_shipping_data,
                      setBilling_Shipping_data,
                      user_billing_address,
                      user_shipping_address,
                      payment_gateway,
                      setPayment_gateway,
                      showCartError,
                      card_holder_name,
                      setCard_holder_name,
                      cardNumber,
                      setCardNumber,
                      expiry,
                      setExpiry,
                      cvc,
                      setCvc,
                      handleCardNumberChange,
                      handleCardExpiryChange,
                      handleCardCVCChange,
                      is_submitted,
                      setIs_submitted,
                      placeOrder,
                      disableLastStep,
                      alertMessageClass,
                      messageAfterPlaceOrder,
                      promoCode,
                      setPromoCode,
                      promoCodeInfo,
                      setPromoCodeInfo,
                    }}
                  >
                    {/* step 1 check login and mini cart */}
                    {step === 1 && <ChechoutMiniCart />}

                    {/* step 2 billing shipping*/}
                    {step === 2 && <CheckoutBillingShipping />}

                    {/* step 3 : payment */}
                    {step === 3 && <CheckoutPayment />}
                  </CheckoutServiceProvider>
                </div>
              </div>
              <div className="col-lg-2"></div>
            </div>
          </div>
        </section>
        <br />
        <br />
      </Suspense>
    </Layout>
  );
};

export default CheckoutPage;
