import React, { Fragment, Suspense, useEffect, useState } from "react";
import { GET_STORAGE, CART } from '../../../config/constant';
import CartOperations from '../../../utils/cart/cart';

const Cart = (props) => {
    const [cartProducts, setCartProducts] = useState({});
    const [totalPrice, setTotalPrice] = useState({});
    const [totalOrderPrice, setTotalOrderPrice] = useState(0);

    useEffect(() => {
        setCartProducts(JSON.parse(GET_STORAGE(CART)));
        calculateAndUpdateTotalPrice();
    }, []);

    const removeFromCart = function (product) {
        CartOperations.removeFromCart(product);
        setCartProducts(JSON.parse(GET_STORAGE(CART)))
    };

    const handleQuantity = function (index, e) {
        cartProducts[index].c_quantity = e.target.value;
        cartProducts[index].price_with_variation = calculatePrice(index);
        setCartProducts(cartProducts);
        setTotalPrice({
            "price_with_variation": cartProducts[index].price_with_variation,
            "index": index
        });
        calculateAndUpdateTotalPrice();
        saveCart();
    }

    const calculateAndUpdateTotalPrice = function () {
        var total = 0;
        Object.keys(cartProducts).map((index) => {
            total += cartProducts[index].price_with_variation;
        });
        setTotalOrderPrice(total)
    }

    const calculatePrice = function (index, e) {
        return cartProducts[index].c_quantity * cartProducts[index].price_with_variation;
    }

    const saveCart = function () {
        CartOperations.saveCart(cartProducts);
    }

    return (
        <div className="cart-component">
            <table width="100%">
                <thead>
                    <th width="30%"> Product Name </th>
                    <th width="10%"> Quantity </th>
                    <th width="30%"> Options </th>
                    <th width="10%"> Price </th>
                    <th width="10%"> Actions </th>
                </thead>
                <tbody>
                    {cartProducts && <>
                        {
                            Object.keys(cartProducts).map((index) => {

                                return (
                                    <tr>
                                        <td>
                                            {cartProducts[index].name}
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                id="c_quantity"
                                                name="c_quantity"
                                                className="form-control"
                                                placeholder="Quantity"
                                                autoComplete="off"
                                                defaultValue={cartProducts[index].c_quantity}
                                                onChange={handleQuantity.bind(this, index)}
                                                min="1"
                                            />
                                        </td>
                                        <td>
                                            {Object.keys(cartProducts[index].c_options).map((data) => {
                                                return (
                                                    <div>
                                                        <strong> {data} :</strong>
                                                        {cartProducts[index].c_options[data].option_value}

                                                        {cartProducts[index].c_options[data].price ?
                                                            '(' + cartProducts[index].c_options[data].price_prefix
                                                            + '$' + cartProducts[index].c_options[data].price + ')'
                                                            : ''}
                                                        <br />
                                                    </div>
                                                );
                                            })}
                                        </td>
                                        <td>
                                            $ {
                                                totalPrice['index'] === index ?
                                                    totalPrice['price_with_variation'] :
                                                    cartProducts[index].price_with_variation
                                            }
                                        </td>
                                        <td>

                                            <button
                                                className="btn btn-block btn-danger"
                                                onClick={removeFromCart.bind(this, cartProducts[index])}
                                            >
                                                Remove
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </>
                    }

                    <tr>
                        <td> </td>
                        <td> </td>
                        <td> <strong> TOTAL: </strong> </td>
                        <td> <strong> {totalOrderPrice} </strong> </td>
                        <td> </td>
                    </tr>
                </tbody>
            </table>

            <div className="row">
                <div className="col-3">
                    <button
                        className="btn btn-block btn-outline-success"
                        onClick={props.action}
                    >
                        {props.button}
                    </button>
                </div>
                <div className="col-3">
                    <button
                        className="btn btn-block btn-outline-secondary"
                        onClick={saveCart}
                    >
                        Save Cart
                </button>
                </div>
            </div>
        </div>
    )
}

export default Cart;