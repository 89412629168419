import React, { Suspense, useState } from "react";
import Layout from "../../../utils/templates/layout/default";
import Cart from "../component/cart";
import { useForm, Controller } from "react-hook-form";
import { CART, GET_STORAGE } from "../../../config/constant";

const CheckOut = (props) => {
  const { handleSubmit, register, errors, control } = useForm();
  const [shipmentInfo, setShipmentInfo] = useState({
    tax: 10,
    shippingCharge: 10,
  });

  const PlaceOrder = function (data) {
    data.tax = shipmentInfo.tax;
    data.shippingCharge = shipmentInfo.shippingCharge;
    setShipmentInfo(data);

    props.history.push({
      pathname: "/order-details",
      state: {
        shipmentInfo: data,
        cartProducts: JSON.parse(GET_STORAGE(CART)),
      },
    });
  };

  return (
    <Suspense>
      <Layout>
        <div className="search-wrapper">
          <div className="row align-items-center justify-content-around">
            <div className="col-6 shipping-info-section">
              <div className="form-group shipping-info">
                <div className="row">
                  <h4> Checkout </h4>
                </div>

                <div className="row">
                  Shipping Method *
                  {errors.shipping_method && (
                    <div className="alert-danger">
                      {" "}
                      select a shipping method{" "}
                    </div>
                  )}
                </div>

                <Controller
                  render={() => (
                    <>
                      <div className="row">
                        <input
                          type="radio"
                          name="shipping_method"
                          value="ship_to_store"
                        />
                        <label>
                          {" "}
                          A store for pickup selection will be made on next page
                        </label>
                      </div>

                      <div className="row">
                        <input
                          type="radio"
                          name="shipping_method"
                          value="ship_to_address"
                        />
                        <label> Ship to address </label>
                      </div>
                    </>
                  )}
                  rules={{ required: true }}
                  name="shipping_method"
                  control={control}
                />

                <div className="row">
                  <div className="col-4">
                    <label> Select Shipping Services: </label>
                  </div>

                  <div className="col-3">
                    <select
                      name="shipping_services"
                      ref={register({
                        required: true,
                      })}
                    >
                      <option value="" key="null">
                        {" "}
                        --select--{" "}
                      </option>
                      <option> Flat Rate </option>
                      <option> Flightcom </option>
                    </select>
                  </div>
                  {errors.shipping_services && (
                    <div className="alert-danger">
                      {" "}
                      select a shipping service{" "}
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-6">
                    <label> First Name * </label>
                    <input
                      type="text"
                      name="first_name"
                      ref={register({
                        required: true,
                      })}
                    />
                    {errors.first_name && (
                      <div className="alert-danger">
                        {" "}
                        first name is required{" "}
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    <label> Last Name * </label>
                    <input
                      type="text"
                      name="last_name"
                      ref={register({
                        required: true,
                      })}
                    />
                    {errors.last_name && (
                      <div className="alert-danger">
                        {" "}
                        last name is required{" "}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <label> Email Address * </label>
                    <input
                      type="text"
                      name="email_address"
                      ref={register({
                        required: true,
                      })}
                    />
                    {errors.email_address && (
                      <div className="alert-danger">
                        {" "}
                        email address is required{" "}
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    <label> Phone * </label>
                    <input
                      type="text"
                      name="phone"
                      ref={register({
                        required: true,
                      })}
                    />
                    {errors.phone && (
                      <div className="alert-danger">
                        {" "}
                        phone number is required{" "}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <h4> Shipping Address</h4>
                  <hr />
                </div>

                <div className="row">
                  <label> Country * </label>
                  <input
                    type="text"
                    name="address.country"
                    ref={register({
                      required: true,
                    })}
                  />
                  {errors.address?.country && (
                    <div className="alert-danger"> country is required </div>
                  )}
                </div>

                <div className="row">
                  <label> Address1 * </label>
                  <input
                    type="text"
                    name="address.address1"
                    ref={register({
                      required: true,
                    })}
                  />
                  {errors.address?.address1 && (
                    <div className="alert-danger"> address is required </div>
                  )}
                </div>

                <div className="row">
                  <label> Address2 </label>
                  <input type="text" name="address.address2" ref={register()} />
                </div>

                <div className="row">
                  <label> City * </label>
                  <input
                    type="text"
                    name="address.city"
                    ref={register({
                      required: true,
                    })}
                  />
                  {errors.address?.city && (
                    <div className="alert-danger"> city is required </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-6">
                    <label> Province / State * </label>
                    <input
                      type="text"
                      name="address.province"
                      ref={register({
                        required: true,
                      })}
                    />
                    {errors.address?.province && (
                      <div className="alert-danger">
                        {" "}
                        province/state is required{" "}
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    <label> Postcode / ZIP * </label>
                    <input
                      type="text"
                      name="address.postcode"
                      ref={register({
                        required: true,
                      })}
                    />
                    {errors.address?.postcode && (
                      <div className="alert-danger">
                        {" "}
                        postal/Zip code is required{" "}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <input
                    type="checkbox"
                    name="terms_and_conditions"
                    value="1"
                    ref={register({
                      required: true,
                    })}
                  />
                  <label>
                    <strong>
                      {" "}
                      I’ve read and accept the terms & conditions *{" "}
                    </strong>
                  </label>
                  {errors.terms_and_conditions && (
                    <div className="alert-danger">
                      {" "}
                      you must agree with our terms & conditions{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-6 cart-section">
              <h3> Your Order </h3>
              <Cart action={handleSubmit(PlaceOrder)} button={"Place Order"} />
            </div>
          </div>
        </div>
      </Layout>
    </Suspense>
  );
};

export default CheckOut;
