/**
 * Clover Iframe Payment Gateway
 *
 * @author MD. KHAIRULLAH NAYAN <khairullah@codersbucket.com>
 *
 */

export const initClover = () => {
  let clover_src = "https://checkout.sandbox.dev.clover.com/sdk.js";
  let clover_token = "13aa868f9146f40fc4e497c53dff0243";

  if (process.env.REACT_APP_ENVIRONMENT == "production") {
    clover_src = "https://checkout.clover.com/sdk.js";
    clover_token = "11500129f5e93ebef9e264a52b6e8e52";
  }

  const cloverScript = document.createElement("script");
  cloverScript.async = true;
  cloverScript.src = clover_src;
  document.head.appendChild(cloverScript);

  if (cloverScript.readyState) {
    // only required for IE <9
    cloverScript.onreadystatechange = function () {
      if (
        cloverScript.readyState === "loaded" ||
        cloverScript.readyState === "complete"
      ) {
        cloverScript.onreadystatechange = null;
        loadCloverGlabalVar();
      }
    };
  } else {
    //Others
    cloverScript.onload = function () {
      loadCloverGlabalVar();
    };
  }

  function loadCloverGlabalVar() {
    const cloverGlobalVar = document.createElement("script");
    cloverGlobalVar.async = true;
    cloverGlobalVar.innerHTML = `window.clover = new Clover("${clover_token}");`;
    document.head.appendChild(cloverGlobalVar);
  }
};
