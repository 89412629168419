import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import { SET_STORAGE, GET_STORAGE, REMOVE_STORAGE, USER, CART, WISHLIST } from './config/constant'
import { initUserAuthentication } from './redux/actions/auth'

import App from './App';
import store from './redux/store';
import auth from './utils/auth/auth';
import { initGoogleAnalytics } from './utils/common/analytics';
import { initClover } from './utils/common/clover';
import { CONFIG, VERSION } from './config/settings';
import * as serviceWorker from './serviceWorker';
import { TYPES } from './redux/types/index';
import {WOW} from 'wowjs';

/*componentDidMount() {
	new WOW.WOW({
	  live: false
  }).init();
}; */

//version track
console.log('App Version: ' + VERSION);

// initialize clover payment gateway
initClover();

if (process.env.REACT_APP_ENVIRONMENT == "production") {
	// Add Google Tag Manager to app
	initGoogleAnalytics();

	//turn off console log
	console.log = () => { };
}

/**
 * Default setup for axios - you can include default api-keys, base_url etc.
 */
axios.defaults.baseURL = CONFIG.API_BASE_URL;
// Add a request interceptor
axios.interceptors.request.use(function (config) {
	// get authorized token before sending the request else send default token
	const token = auth.getToken();
	config.headers.common['Authorization'] = `Bearer ${token}`;
	return config;
}, function (error) { return Promise.reject(error); });

//local storage to store
const user = JSON.parse(GET_STORAGE(USER));
if (user) {
	if (user.token) {
		store.dispatch(initUserAuthentication(user));
	} else {
		REMOVE_STORAGE(USER);
	}
}
const cart = JSON.parse(GET_STORAGE(CART));
if (cart) {
	store.dispatch({ type: TYPES.UPDATE_CART, payload: cart })
}
const wishlist = JSON.parse(GET_STORAGE(WISHLIST));
if (wishlist) {
	store.dispatch({ type: TYPES.UPDATE_WISHLIST, payload: wishlist })
}



ReactDOM.render(
	<Provider store={store}>
		<Router basename={CONFIG.BASENAME}>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</Router>
	</Provider>,
	document.getElementById('root')
);
serviceWorker.unregister();
