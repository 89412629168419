import React, {Fragment, Suspense, useEffect, useState} from "react";
import {CART, GET_STORAGE} from "../../../config/constant";

const Order = (props) => {
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalOrderPrice, setTotalOrderPrice] = useState(0);

    useEffect(() => {
        calculateAndUpdateTotalPrice();
        console.log(props.shipmentInfo)
    }, []);

    const calculateAndUpdateTotalPrice = function (){
        var total = 0;
        Object.keys(props.cartProducts).map((index) => {
            total += props.cartProducts[index].total_price;
        });
        setTotalOrderPrice(total + props.shipmentInfo?.shippingCharge + props.shipmentInfo?.tax);
        setTotalPrice(total)
    }

    return (
        <div className="order-component">

            <div className="row">
                <table width="100%">
                    <thead>
                        <th width="30%"> Product </th>
                        <th width="10%"> Quantity </th>
                        <th width="10%"> Total </th>
                    </thead>
                    <tbody>
                        { props.cartProducts && <>
                            {
                                Object.keys(props.cartProducts).map((index) => {

                                    return (
                                        <tr>
                                            <td>
                                                {props.cartProducts[index].name}
                                            </td>
                                            <td>
                                                x {props.cartProducts[index].quantity}
                                            </td>
                                            <td>
                                                $ {props.cartProducts[index].total_price}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </>
                        }

                        <tr>
                            <td colSpan="3">
                                <hr/>
                            </td>
                        </tr>
                        <tr>
                            <td> </td>
                            <td> <strong> Sub Total: </strong> </td>
                            <td> $ {totalPrice} </td>
                        </tr>

                        <tr>
                            <td> </td>
                            <td> <strong> Shipping: </strong> </td>
                            <td> $ {props.shipmentInfo?.shippingCharge} </td>
                        </tr>

                        <tr>
                            <td> </td>
                            <td> <strong> Tax: </strong> </td>
                            <td> $ {props.shipmentInfo?.tax} </td>
                        </tr>

                        <tr>
                            <td colSpan="3">
                                <hr/>
                            </td>
                        </tr>

                        <tr>
                            <td> </td>
                            <td> <strong> Total: </strong> </td>
                            <td> $ {totalOrderPrice} </td>
                        </tr>

                        <tr>
                            <td> <strong> </strong> </td>
                            <td> </td>
                            <td> </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br/>
            <br/>
            <br/>
            <div className="row">
                <h4> Shipping Information </h4>
            </div>

            <div className="row">
                <div className="col-4">
                    <strong> First Name: </strong> {props.shipmentInfo?.first_name}
                </div>
                <div className="col-4">
                    <strong> Last Name: </strong> {props.shipmentInfo?.last_name}
                </div>
            </div>

            <div className="row">
                <div className="col-4">
                    <strong> Email: </strong> {props.shipmentInfo?.email_address}
                </div>
                <div className="col-4">
                    <strong> Phone Number: </strong> {props.shipmentInfo?.phone}
                </div>
            </div>

            <div className="row">
                <div className="col-4">
                    <strong> Shipping Method: </strong> {props.shipmentInfo?.shipping_method}
                </div>
                <div className="col-4">
                    <strong> Shipping Service: </strong> {props.shipmentInfo?.shipping_services}
                </div>
            </div>

            <div className="row">
                <h4> Shipping Address </h4>
            </div>

            <div className="row">
                <div className="col-4">
                    <strong> Address 1: </strong> {props.shipmentInfo?.address.address1}
                </div>
                <div className="col-4">
                    <strong> Address 2: </strong> {props.shipmentInfo?.address.address2}
                </div>
            </div>

            <div className="row">
                <div className="col-3">
                    <strong> Postalcode/Zip: </strong> {props.shipmentInfo?.address.postalcode}
                </div>

                <div className="col-3">
                    <strong> City: </strong> {props.shipmentInfo?.address.city}
                </div>

                <div className="col-3">
                    <strong> Province/State: </strong> {props.shipmentInfo?.address.province}
                </div>

                <div className="col-3">
                    <strong> Country : </strong> {props.shipmentInfo?.address.country}
                </div>
            </div>

            <div className="row">
                <div className="col-5">
                    <button
                        className="btn btn-block btn-outline-success"
                        onClick={props.action}>
                        {props.button}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Order;