import React, { Suspense, useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { CONFIG } from "../../../config/settings";
import ReactImageFallback from "react-image-fallback";
import { Helmet } from "react-helmet";
import Layout from "../../../utils/templates/layout/default";
import { API_STATUS, TITLE } from "../../../config/constant";
import { toast } from "react-toastify";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { APIS } from "../config/constant";
import axios from "axios";

const ServicesDetailsPage = (props) => {
  const [meta_title, setMeta_title] = useState(TITLE);
  const [meta_description, setMeta_description] = useState(TITLE);
  const [activeService, setActiveService] = useState({});
  const [title, setTitle] = useState("");
  const [banner, setBanner] = useState(null);
  const [shortDescription, setShortDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState(false);
  const [childPageInfo, setChildPageInfo] = useState([]);

  // const history = useHistory();

  const partner_slider_settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 3500,
    speed: 1300,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (props.match.params.slug) {
      setLoading(false);
      setPageData(false);
      setActiveService(props.match.params.slug);
      axios
        .get(APIS.PAGES + "?slug=" + props.match.params.slug)
        .then((response) => {
          if (response.data.status === API_STATUS.OK) {
            if (response.data.response.result) {
              setPageData(response.data.response.data);
              setTitle(response.data.response.data?.title);
              setLoading(false);
              //seo
              if (response.data.response.data.others) {
                setMeta_title(response.data.response.data?.others?.meta_title);
                setMeta_description(
                  response.data.response.data?.others?.meta_description
                );
                setBanner(
                  response.data.response.data?.others?.feature_image?.main_img
                );
                setShortDescription(
                  response.data.response.data?.others?.short_description
                );
              }

              if (
                props.match.params.slug === "essential-hair-spa-beauty-salon"
              ) {
                setChildPageInfo(response.data.response.data?.children);
              }
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        });
    } else {
      toast.error("No Data Found");
    }
  }, [props.match.params]);

  const goToMenu = () => {
    document.querySelector("#services-slider").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Suspense>
      <Layout>
        <section className="services">
          <Helmet>
            <title>{meta_title}</title>
            <meta name="title" content={meta_title} />
            <meta name="description" content={meta_description} />
            <link
              rel="canonical"
              href={`${CONFIG.BASE_URL}/services/${props.match.params.slug}`}
            />
          </Helmet>
          {loading ? (
            <section className="pages-details">
              <div className="loading">
                <ReactImageFallback
                  src={require("../../../assets/img/loading.gif").default}
                  fallbackImage={
                    require("../../../assets/img/no-image.png").default
                  }
                  initialImage={
                    require("../../../assets/img/loading.gif").default
                  }
                  alt="no image"
                  style={{ width: "30px" }}
                  className=""
                />
              </div>
            </section>
          ) : (
            <Fragment>
              <div
                className="md-inner-banner-area serviceInnerTitleBg"
                style={{
                  backgroundImage: `url(${CONFIG.IMAGE_BASE_URL}/files/Pages/${banner})`,
                }}
              >
                <div className="md-inner-banner-title">
                  <h1 className="title">{title}</h1>
                </div>
              </div>

              <div className="md-services-promo-text-area">
                <div className="container">
                  <div
                    className="md-services-promo-text text-center"
                    dangerouslySetInnerHTML={{
                      __html: pageData.description,
                    }}
                  />
                </div>
              </div>

              {props.match.params.slug ===
                "essential-hair-spa-beauty-salon" && (
                <div className="md-services-carousal-area">
                  <div className="container-fluid">
                    <div className="services-slider" id="services-slider">
                      {childPageInfo.length > 0 ? (
                        <Slider {...partner_slider_settings}>
                          {childPageInfo.map(
                            (childPage, childPageInfoIndex) => {
                              return (
                                <div
                                  className="services-slider-single"
                                  key={childPage.slug}
                                  onClick={goToMenu}
                                >
                                  <NavLink to={`/${childPage.slug}`}>
                                    <div
                                      className={`object-slider-content ${
                                        childPage.slug === activeService.slug
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      <img
                                        src={`${CONFIG.IMAGE_BASE_URL}/files/Pages/${childPage.others?.feature_image?.main_img}`}
                                        alt={childPage.title}
                                      />
                                    </div>
                                    <strong>{childPage.menu_title}</strong>
                                  </NavLink>
                                </div>
                              );
                            }
                          )}
                        </Slider>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </section>
      </Layout>
    </Suspense>
  );
};

export default ServicesDetailsPage;
