import React, {
  Component,
  Suspense,
  useState,
  useEffect,
  Fragment,
} from "react";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import { CONFIG } from "../../../config/settings";
import ReactImageFallback from "react-image-fallback";
import { Helmet } from "react-helmet";
import Layout from "../../../utils/templates/layout/default";
import { APIS } from "../config/constant";
import RelatedProductListSingle from "../component/RelatedProductListSingle";
import {
  API_STATUS,
  GET_STORAGE,
  SET_STORAGE,
  REMOVE_STORAGE,
  TITLE,
  OPTION_PRICE,
  SELECTED_OPTION_PRICE,
  CALCULATE_DISCOUNT,
  CALCULATE_SPECIAL_PRICE,
  CALCULATE_PRODUCT_PRICE_NEW,
  FORMAT_FORM_DATA,
  SETTINGS,
} from "../../../config/constant";
import { toast } from "react-toastify";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import parser from "html-react-parser";
import CartOperations from "../../../utils/cart/cart";
import { useDispatch, useSelector } from "react-redux";
import { ManageCart, RemoveFromCart } from "../../../redux/actions/cart";
import {
  AddToWishlist,
  RemoveFromWishlist,
} from "../../../redux/actions/wishlist";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SIZE_CHART from "../../../assets/img/size_chart.png";
import ContentLoader from "react-content-loader";

import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers";

const ProductDetails = (props) => {
  const [meta_title, setMeta_title] = useState(TITLE);
  const [meta_description, setMeta_description] = useState(TITLE);
  const [disable_shopping_cart, setDisable_shopping_cart] = useState(false);
  const [products, setProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [options, setOptions] = useState({});
  const [inCart, setInCart] = useState(false);
  const [addedPrice, setAddedPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [final_price, setFinal_price] = useState(0);
  const [productOptions, setProductOptions] = useState([]);
  const [productRating, setProductRating] = useState(5);
  const [product_images, setProduct_images] = useState([]);
  const [active_img, setActive_img] = useState(
    require("../../../assets/img/no-image.png").default
  );
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [stock_product, setStockProduct] = useState(1);

  const [customizeLoading, setCustomizeLoading] = useState(false);
  const [base_product_id, setBase_product_id] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart[0]);
  const wishlist = useSelector((state) => state.wishlist[0]);
  const [relatedProducts, setRelatedProducts] = useState([]);

  //react-image-magnifier side by side
  const [alwaysInPlace, setalwaysInPlace] = useState(false);
  const [overlayOpacity, setoverlayOpacity] = useState(0.6);
  const [switchSides, setswitchSides] = useState(false);
  const [fillAvailableSpace, setfillAvailableSpace] = useState(true);
  const [fillAlignTop, setfillAlignTop] = useState(true);
  const [fillGapLeft, setfillGapLeft] = useState(10);
  const [fillGapRight, setfillGapRight] = useState(10);
  const [fillGapTop, setfillGapTop] = useState(10);
  const [fillGapBottom, setfillGapBottom] = useState(10);

  // let sliderRef = React.createRef();
  let sliderRef = null;

  function next() {
    sliderRef.slickNext();
  }

  function previous() {
    sliderRef.slickPrev();
  }

  useEffect(() => {
    setLoading(true);
    const settings = GET_STORAGE(SETTINGS);
    if (settings) {
      const settingsParsed = JSON.parse(settings);
      if (
        settingsParsed.shopSettingData &&
        settingsParsed.shopSettingData.theme_options &&
        settingsParsed.shopSettingData.theme_options.disable_shopping_cart
      ) {
        setDisable_shopping_cart(true);
      }
    }
    document.querySelector("body").scrollIntoView();
    axios
      .get(APIS.PRODUCTDETAILS + "/" + props.match.params.slug)
      .then((response) => {
        if (response.data.status == API_STATUS.OK) {
          if (response.data.response.result) {
            setProducts(response.data.response.data);

            axios
              .get(
                APIS.RELATEDPRODUCTS +
                  "?cat_id=" +
                  response.data.response.data.categories_id +
                  "&active_product_id=" +
                  response.data.response.data.id +
                  "&limit=5"
              )
              .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                  if (response.data.response.result) {
                    setRelatedProducts(response.data.response.data);
                  } else {
                    setRelatedProducts([]);
                  }
                }
              });

            // const option_price = OPTION_PRICE(response.data.response.data.options);
            // console.log('OPTION_PRICE', OPTION_PRICE(response.data.response.data.options))
            // console.log('CALCULATE_DISCOUNT', CALCULATE_DISCOUNT(response.data.response.data))
            // console.log('CALCULATE_SPECIAL_PRICE', CALCULATE_SPECIAL_PRICE(response.data.response.data))
            // console.log('CALCULATE_PRODUCT_PRICE_NEW', CALCULATE_PRODUCT_PRICE_NEW(response.data.response.data))
            calculate_price(response.data.response.data);
            // if (auth && auth.user && auth.user.type == "WH") {
            //     setTotalPrice(parseFloat(response.data.response.data.wholesale_price).toFixed(2) + ' - $' + parseFloat(eval(parseFloat(response.data.response.data.wholesale_price).toFixed(2) + "+" + parseFloat(OPTION_PRICE(response.data.response.data.options)).toFixed(2))).toFixed(2));
            // } else {
            //     setTotalPrice(parseFloat(response.data.response.data.price).toFixed(2) + ' - $' + parseFloat(eval(parseFloat(response.data.response.data.price).toFixed(2) + "+" + parseFloat(OPTION_PRICE(response.data.response.data.options)).toFixed(2))).toFixed(2));
            // }

            //seo
            if (response.data.response.data.others) {
              setMeta_title(response.data.response.data.others.meta_title);
              setMeta_description(
                response.data.response.data.others.meta_description
              );
            }
            setBase_product_id(response.data.response.data.base_product_id);
            // var cart_products = GET_STORAGE("cart_products") ? JSON.parse(GET_STORAGE("cart_products")) : {};

            // if (cart_products[response.data.response.data.slug]) {
            //     setInCart(true);
            // } else {
            //     setInCart(false);
            // }
            let product_images = [];
            let product_images_index = -1;
            if (response.data.response.data.others) {
              if (response.data.response.data.others.feature_image_full_url) {
                product_images_index++;
                product_images.push({
                  thumb: `${response.data.response.data.others.feature_image_full_url.thumb_img}`,
                  main: `${response.data.response.data.others.feature_image_full_url.main_img}`,
                  index: product_images_index,
                });
              }
              if (response.data.response.data.others.other_picture_full_url) {
                response.data.response.data.others.other_picture_full_url.map(
                  (galleryImages_single, galleryImages_index) => {
                    product_images_index++;
                    product_images.push({
                      thumb: galleryImages_single.thumb_img,
                      main: galleryImages_single.main_img,
                      index: product_images_index,
                    });
                  }
                );
              }

              if (
                response.data.response.data.others.out_of_stock_status &&
                response.data.response.data.others.out_of_stock_status ==
                  "Out Of Stock"
              ) {
                let stock = false;
                setStockProduct(stock);
              } else {
                let stock = true;
                setStockProduct(stock);
              }
            }
            if (response.data.response.data.options) {
              setProductOptions(response.data.response.data.options);
              if (Object.keys(response.data.response.data.options).length > 0) {
                Object.keys(response.data.response.data.options).map(
                  (options_index) => {
                    const options_single =
                      response.data.response.data.options[options_index];
                    if (
                      options_single.choose_field &&
                      Object.keys(options_single.choose_field).length > 0
                    ) {
                      Object.keys(options_single.choose_field).map(
                        (choose_field_index) => {
                          if (
                            options_single.choose_field[choose_field_index]
                              .image_full_url &&
                            options_single.choose_field[choose_field_index]
                              .image_full_url.medium_img
                          ) {
                            product_images_index++;
                            product_images.push({
                              thumb: `${options_single.choose_field[choose_field_index].image_full_url.thumb_img}`,
                              main: `${options_single.choose_field[choose_field_index].image_full_url.main_img}`,
                              index: product_images_index,
                            });
                          }
                        }
                      );
                    }
                  }
                );
              }
            }
            if (product_images.length > 0) {
              setActive_img(product_images[0]);
            }
            setProduct_images(product_images);
            setLoading(false);
            // slider = slider.rebuild();
          } else {
            toast.error(response.data.response.message);
          }
        }
      });
  }, [props.match.params]);

  // const addToCart = function () {
  //     CartOperations.addToCart(products, quantity, options, totalPrice);
  //     setInCart(true);
  // };

  // const removeFromCart = function () {
  //     if (inCart) {
  //         CartOperations.removeFromCart(products);
  //         setInCart(false);
  //     }
  // };

  const handleQuantity = function (e) {
    setQuantity(e.target.value);
    calculate_price();
  };

  const calculate_price = (products_param = false) => {
    let new_products = Object.assign(
      {},
      products_param ? products_param : products
    );
    if (options && options != "" && Object.keys(options).length > 0) {
      new_products.c_options = Object.assign({}, options);
    }
    const cal_result = CALCULATE_PRODUCT_PRICE_NEW(new_products);

    if (new_products.c_options) {
      setTotalPrice(cal_result);
    } else {
      if (
        productOptions &&
        productOptions != "" &&
        Object.keys(productOptions).length > 0
      ) {
        setTotalPrice("Starting From CA$ " + cal_result);
      } else {
        setTotalPrice(cal_result);
      }
    }

    // if (auth && auth.user && auth.user.type == "WH") {
    //     if (new_products.c_options) {
    //         setTotalPrice(parseFloat(cal_result.calculated_wholesale_unit_price).toFixed(2));
    //     } else {
    //         setTotalPrice(parseFloat(cal_result.calculated_wholesale_unit_price).toFixed(2) + ' - $' + parseFloat(cal_result.calculated_wholesale_max_price).toFixed(2));
    //     }
    // } else {
    //     if (new_products.c_options) {
    //         setTotalPrice(parseFloat(cal_result.calculated_unit_price).toFixed(2));
    //     } else {
    //         setTotalPrice(parseFloat(cal_result.calculated_min_unit_price).toFixed(2) + ' - $' + parseFloat(cal_result.calculated_max_price).toFixed(2));
    //     }
    // }
  };

  let rating_array = [];
  for (var i = 1; i <= productRating; i++) {
    rating_array.push(i);
  }

  let product_rating = parseInt(rating_array.length) + 1;

  let rating_ops_array = [];
  for (var i = product_rating; i <= 5; i++) {
    rating_ops_array.push(i);
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  const openLightbox = (active_img) => {
    setPhotoIndex(active_img.index);
    setIsOpen(true);
  };

  const chooseOption = (
    option,
    option_id,
    option_name,
    option_value,
    option_type = "Text",
    choose_field = false
  ) => {
    // let selected_option = JSON.parse(JSON.stringify(option));
    let selected_option = Object.assign({}, option);
    selected_option.option_id = option_id;
    selected_option.option_type = option_type;
    selected_option.option_value = option_value;

    let selected_options = options;
    let new_selected = selected_option;
    let prev_selected = selected_options[option_name];

    if (option_type == "Checkbox") {
      if (prev_selected) {
        let old_value = prev_selected.option_value;
        if (old_value && old_value.length > 0) {
          // var index = old_value.indexOf(Array.isArray(option_value) ? option_value[0] : option_value);
          var index = old_value.indexOf(option_value);
          if (index == -1) {
            // old_value.push(Array.isArray(option_value) ? option_value[0] : option_value)
            old_value.push(option_value);
          } else {
            old_value.splice(index, 1);
          }
        } else {
          if (Array.isArray(old_value)) {
            // old_value.push(Array.isArray(option_value) ? option_value[0] : option_value)
            old_value.push(option_value);
          } else {
            old_value = [];
            // old_value.push(Array.isArray(option_value) ? option_value[0] : option_value)
            old_value.push(option_value);
          }
        }
        prev_selected.option_value = old_value;
        new_selected = prev_selected;
      } else {
        new_selected.option_value = [];
        new_selected.option_value.push(option_value);
        new_selected.choose_field = choose_field;
      }
    }

    selected_options[option_name] = new_selected;
    setOptions(selected_options);
    calculate_price();
    // const selected_option_price = SELECTED_OPTION_PRICE(selected_options);
    // setTotalPrice(selected_option_price.max_price_value_with_checkbox)
    setMounted(mounted ? false : true);
    if (
      selected_option.hasOwnProperty("image_full_url") &&
      selected_option.image_full_url
    ) {
      if (selected_option.image_full_url.main_img) {
        let updated_active_img = {
          thumb: `${selected_option.image_full_url.thumb_img}`,
          main: `${selected_option.image_full_url.main_img}`,
          index: 0,
        };
        if (product_images.length > 0) {
          product_images.map((product_images_single, product_images_index) => {
            if (product_images_single.thumb == updated_active_img.thumb) {
              updated_active_img.index = product_images_single.index;
            }
          });
        }
        setActive_img(updated_active_img);
      }
    }
  };

  const triggerCartWishlist = (e, type) => {
    if (type == "cart") {
      dispatch(ManageCart(e, products, quantity, options));
    }
    if (type == "wishlist") {
      dispatch(AddToWishlist(e, products, quantity, options));
    }
  };

  const addToCartWishlist = (e, type) => {
    e.preventDefault();
    // console.log('productOptions', productOptions)
    // console.log('options', options)
    if (productOptions && Object.keys(productOptions).length > 0) {
      if (Object.keys(productOptions).length == Object.keys(options).length) {
        triggerCartWishlist(e, type);
      } else {
        toast.error("Please choose all of product options");
      }
    } else {
      triggerCartWishlist(e, type);
    }
  };

  const processCustomize = (e) => {
    e.preventDefault();
    if (base_product_id) {
      let selected_colors = "";
      let selected_colors_title = "";
      let selected_sizes = "";
      let selected_sizes_title = "";

      if (Object.keys(options).length > 0) {
        Object.keys(options).map((options_index) => {
          if (options[options_index].option_type == "Color") {
            selected_colors = options[options_index].option_img;
            selected_colors_title = options[options_index].option_value;
          }
          if (options[options_index].option_type == "Size") {
            selected_sizes = options[options_index].option_img;
            selected_sizes_title = options[options_index].option_value;
          }
        });
      }

      let formdata = {
        product_base_id: base_product_id,
        selected_colors: selected_colors,
        selected_colors_title: selected_colors_title,
        selected_sizes: selected_sizes,
        selected_sizes_title: selected_sizes_title,
      };

      console.log("formdata", FORMAT_FORM_DATA(formdata));

      setCustomizeLoading(true);
      axios
        .post(APIS.CHECKPRODUCTBASE, FORMAT_FORM_DATA(formdata))
        .then((response) => {
          if (response.data.status == API_STATUS.OK) {
            if (response.data.response.result) {
              let customize_url = `${CONFIG.CUSTOMIZE_URL}editor.php?product_base=${base_product_id}&product_id=${products.id}&selected_colors=${selected_colors}&selected_colors_title=${selected_colors_title}&selected_sizes=${selected_sizes}&selected_sizes_title=${selected_sizes_title}&ref=${window.location.href}`;
              window.location.href = customize_url;
            } else {
              setCustomizeLoading(false);
              toast.error(response.data.response.message);
            }
          } else {
            setCustomizeLoading(false);
            toast.error(response.data.response.message);
          }
        });
    }
  };

  let salePrice = products.sale_price
    ? parseFloat(products.sale_price).toFixed(2)
    : 0.0;
  let tPrice = products.price ? parseFloat(products.price).toFixed(2) : 0.0;
  //   console.log(salePrice);
  let cartData = Object.values(cart);
  let totalQty = 0;
  cartData.forEach((c) => {
    totalQty += c.c_quantity;
  });

  return (
    <Suspense>
      <Layout>
        <section className="product-details">
          <Helmet>
            <title>{meta_title}</title>
            <meta name="title" content={meta_title} />
            <meta name="description" content={meta_description} />
            <link
              rel="canonical"
              href={`${CONFIG.BASE_URL}/products/${props.match.params.slug}`}
            />
          </Helmet>
          <div className="container pt-80 pb-40">
            {loading ? (
              <ContentLoader
                speed={2}
                width={"100%"}
                height={"100%"}
                viewBox="0 0 600 500"
                backgroundColor="#f3f3f3"
                foregroundColor="#e8e3e3"
                {...props}
              >
                <circle cx="363" cy="77" r="6" />
                <rect x="354" y="11" rx="5" ry="5" width="220" height="17" />
                <circle cx="381" cy="77" r="6" />
                <rect x="357" y="45" rx="5" ry="5" width="119" height="9" />
                <circle cx="399" cy="77" r="6" />
                <rect x="355" y="111" rx="5" ry="5" width="220" height="10" />
                <circle cx="417" cy="77" r="6" />
                <rect x="355" y="137" rx="5" ry="5" width="220" height="10" />
                <rect x="81" y="13" rx="0" ry="0" width="255" height="229" />
                <rect x="355" y="165" rx="5" ry="5" width="219" height="10" />
                <circle cx="434" cy="77" r="6" />
                <circle cx="366" cy="200" r="8" />
                <circle cx="394" cy="200" r="8" />
                <circle cx="424" cy="200" r="8" />
                <circle cx="452" cy="200" r="8" />
                <circle cx="481" cy="200" r="8" />
                <rect x="357" y="234" rx="5" ry="5" width="220" height="10" />
                <rect x="357" y="260" rx="5" ry="5" width="220" height="10" />
                <rect x="8" y="14" rx="0" ry="0" width="64" height="62" />
                <rect x="7" y="87" rx="0" ry="0" width="64" height="62" />
                <rect x="7" y="161" rx="0" ry="0" width="64" height="62" />
                <rect x="7" y="329" rx="5" ry="5" width="112" height="17" />
                <rect x="6" y="360" rx="0" ry="0" width="107" height="94" />
                <rect x="19" y="464" rx="5" ry="5" width="82" height="8" />
                <rect x="33" y="479" rx="5" ry="5" width="54" height="5" />
                <rect x="121" y="360" rx="0" ry="0" width="107" height="94" />
                <rect x="134" y="464" rx="5" ry="5" width="82" height="8" />
                <rect x="149" y="479" rx="5" ry="5" width="54" height="5" />
                <rect x="237" y="360" rx="0" ry="0" width="107" height="94" />
                <rect x="250" y="464" rx="5" ry="5" width="82" height="8" />
                <rect x="264" y="479" rx="5" ry="5" width="54" height="5" />
                <rect x="352" y="360" rx="0" ry="0" width="107" height="94" />
                <rect x="363" y="466" rx="5" ry="5" width="82" height="8" />
                <rect x="377" y="481" rx="5" ry="5" width="54" height="5" />
                <rect x="469" y="359" rx="0" ry="0" width="107" height="94" />
                <rect x="480" y="465" rx="5" ry="5" width="82" height="8" />
                <rect x="495" y="480" rx="5" ry="5" width="54" height="5" />
                <circle cx="512" cy="200" r="8" />
              </ContentLoader>
            ) : (
              <Fragment>
                <div className="row">
                  <div className="col-md-6">
                    <div className="product_details_slider">
                      <div className="row">
                        <div className="col-sm-3 order-xs-2 mb-20 mb-sm-40">
                          <div className="product_details_thumb_wrapper">
                            {product_images.length > 0 ? (
                              <Fragment>
                                {/* {
                                                                            product_images.length > 3 &&
                                                                            <div className="text-center btn-thumb-up">
                                                                                <i className="fa fa-angle-up" onClick={() => previous()}></i>
                                                                            </div>
                                                                        } */}
                                <Slider
                                  ref={(s) => (sliderRef = s)}
                                  {...settings}
                                >
                                  {product_images.map(
                                    (
                                      product_images_single,
                                      product_images_index
                                    ) => {
                                      return (
                                        <div key={product_images_single.thumb}>
                                          <ReactImageFallback
                                            src={product_images_single.thumb}
                                            fallbackImage={
                                              require("../../../assets/img/no-image.png")
                                                .default
                                            }
                                            initialImage={
                                              require("../../../assets/img/no-image.png")
                                                .default
                                            }
                                            alt="product image thumb"
                                            style={{ width: "100%" }}
                                            className={
                                              product_images_single.index ==
                                              active_img.index
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() =>
                                              setActive_img(
                                                product_images_single
                                              )
                                            }
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                </Slider>
                              </Fragment>
                            ) : (
                              <div>
                                <img
                                  style={{
                                    width: "100%",
                                    border: "2px solid orange",
                                  }}
                                  src={
                                    product_images[0]
                                      ? product_images[0].thumb
                                      : require("../../../assets/img/no-image.png")
                                          .default
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-9">
                          <div className="product-details-main mb-10 order-xs-1">
                            <SideBySideMagnifier
                              className="input-position"
                              style={{ order: switchSides ? "1" : "0" }}
                              imageSrc={active_img.main}
                              largeImageSrc={active_img.main}
                              alwaysInPlace={alwaysInPlace}
                              overlayOpacity={overlayOpacity}
                              switchSides={switchSides}
                              zoomPosition="left"
                              inPlaceMinBreakpoint={641}
                              fillAvailableSpace={fillAvailableSpace}
                              fillAlignTop={fillAlignTop}
                              fillGapTop={fillGapTop}
                              fillGapRight={fillGapRight}
                              fillGapBottom={fillGapBottom}
                              fillGapLeft={fillGapLeft}
                              zoomContainerBorder="1px solid #ccc"
                              zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
                              touchActivation={TOUCH_ACTIVATION.TAP} // Optional
                            />
                          </div>
                          {isOpen && (
                            <Lightbox
                              mainSrc={product_images[photoIndex].main}
                              nextSrc={
                                product_images[
                                  (photoIndex + 1) % product_images.length
                                ].main
                              }
                              prevSrc={
                                product_images[
                                  (photoIndex + product_images.length - 1) %
                                    product_images.length
                                ].main
                              }
                              onCloseRequest={() => setIsOpen(false)}
                              onMovePrevRequest={() =>
                                setPhotoIndex(
                                  (photoIndex + product_images.length - 1) %
                                    product_images.length
                                )
                              }
                              onMoveNextRequest={() =>
                                setPhotoIndex(
                                  (photoIndex + 1) % product_images.length
                                )
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="product-basic-info mb-3">
                      <NavLink
                        className="product-category-name"
                        to={`/products?cat_id=${
                          products.category_info
                            ? products.category_info.id
                            : ""
                        }`}
                      >
                        {products.category_info
                          ? products.category_info.name
                          : ""}
                      </NavLink>
                      <h1 className="product-title">{products.name}</h1>
                      {products.new_arrival == 1 ? (
                        <span className="badge badge-warning">New</span>
                      ) : (
                        ""
                      )}
                      {products.stock_status == 1 ? (
                        <span className="ml-2 badge badge-info">In stock</span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="price-range">
                      <ul>
                        {salePrice > 0 ? (
                          <>
                            <li className="regular-price off-price">
                              CA$ {tPrice}
                            </li>
                            <li className="regular-price">CA$ {salePrice}</li>
                          </>
                        ) : (
                          <li className="regular-price">CA$ {tPrice}</li>
                        )}
                      </ul>
                    </div>
                    <div className="product-rating">
                      <ul>
                        {rating_array.length > 0
                          ? rating_array.map(
                              (rating_array_data, rating_array_index) => {
                                return (
                                  <Fragment
                                    key={`rating_array_index${rating_array_index}`}
                                  >
                                    <li
                                      className="stars"
                                      onClick={() =>
                                        setProductRating(rating_array_data)
                                      }
                                    >
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </li>
                                  </Fragment>
                                );
                              }
                            )
                          : ""}
                        {rating_ops_array.length > 0
                          ? rating_ops_array.map(
                              (rating_array_data, rating_array_index) => {
                                return (
                                  <Fragment
                                    key={`rating_array_index${rating_array_index}`}
                                  >
                                    <li
                                      className="stars"
                                      onClick={() =>
                                        setProductRating(rating_array_data)
                                      }
                                    >
                                      <i
                                        className="fa fa-star-o"
                                        aria-hidden="true"
                                      ></i>
                                    </li>
                                  </Fragment>
                                );
                              }
                            )
                          : ""}
                      </ul>
                    </div>

                    <div className="product-description">
                      {products.description ? parser(products.description) : ""}
                    </div>

                    <div className="product-attr-list mt-3">
                      {products.attributes_list &&
                      products.attributes_list.length > 0
                        ? Object.keys(products.attributes_list).map((index) => {
                            return (
                              <Fragment>
                                <h5>
                                  {products.attributes_list[index].attr_name}
                                </h5>
                                <p> {products.attributes_list[index].text} </p>
                              </Fragment>
                            );
                          })
                        : ""}
                    </div>

                    <div>
                      {products.options
                        ? Object.keys(products.options).map((index) => {
                            if (
                              products.options[index].option_type == "Color"
                            ) {
                              const selected_color_option_name =
                                products.options[index].option_name;
                              const selected_color_option_value =
                                options[selected_color_option_name];
                              return (
                                <div
                                  className="row mt-3"
                                  key={products.options[index].option_id}
                                >
                                  <div className="col-12">
                                    <p className="option-name option-name-color">
                                      {products.options[index].option_name}:
                                      {selected_color_option_value &&
                                        selected_color_option_value.option_value && (
                                          <span className="selected-option-value">
                                            {" "}
                                            {
                                              selected_color_option_value.option_value
                                            }
                                          </span>
                                        )}
                                    </p>
                                    {Object.keys(
                                      products.options[index].choose_field
                                    ).map((i) => {
                                      let color_option_selected = false;

                                      if (
                                        selected_color_option_value &&
                                        selected_color_option_value.option_value ==
                                          products.options[index].choose_field[
                                            i
                                          ].option_value
                                      ) {
                                        color_option_selected = true;
                                      }
                                      let option_img =
                                        require("../../../assets/img/no-image.png").default;
                                      if (
                                        products.options[index].choose_field[i]
                                          .image &&
                                        products.options[index].choose_field[i]
                                          .option_img != ""
                                      ) {
                                        option_img = `${products.options[index].choose_field[i].option_img}`;
                                        // option_img = `${CONFIG.API_BASE_URL}/../files/docs/Options/${products.options[index].choose_field[i].option_img}`;
                                      } else if (
                                        products.options[index].choose_field[i]
                                          .image_full_url &&
                                        products.options[index].choose_field[i]
                                          .image_full_url.thumb_img
                                      ) {
                                        option_img = `${products.options[index].choose_field[i].image_full_url.thumb_img}`;
                                        // option_img = `${CONFIG.API_BASE_URL}/../files/docs/Products/${products.options[index].choose_field[i].image.thumb_img}`
                                      }
                                      return (
                                        <span
                                          style={{
                                            backgroundColor: option_img,
                                          }}
                                          key={
                                            products.options[index]
                                              .choose_field[i].option_value
                                          }
                                          className={`option-color-img-wrapper ${
                                            color_option_selected
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            chooseOption(
                                              products.options[index]
                                                .choose_field[i],
                                              products.options[index].option_id,
                                              products.options[index]
                                                .option_name,
                                              products.options[index]
                                                .choose_field[i].option_value,
                                              products.options[index]
                                                .option_type
                                            )
                                          }
                                        >
                                          {color_option_selected && (
                                            <i className="fa fa-check"></i>
                                          )}
                                        </span>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            } else if (
                              products.options[index].option_type == "Size"
                            ) {
                              const selected_size_option_name =
                                products.options[index].option_name;
                              const selected_size_option_value =
                                options[selected_size_option_name];
                              return (
                                <div
                                  className="row mt-3"
                                  key={products.options[index].option_id}
                                >
                                  <div className="col-12">
                                    <p className="option-name option-name-size">
                                      {products.options[index].option_name}:
                                      {selected_size_option_value &&
                                        selected_size_option_value.option_value && (
                                          <span className="selected-option-value">
                                            {" "}
                                            {
                                              selected_size_option_value.option_value
                                            }
                                          </span>
                                        )}
                                    </p>
                                    <div className="option-size-area">
                                      {Object.keys(
                                        products.options[index].choose_field
                                      ).map((i) => {
                                        let size_option_selected = false;
                                        if (
                                          selected_size_option_value &&
                                          selected_size_option_value.option_value ==
                                            products.options[index]
                                              .choose_field[i].option_value
                                        ) {
                                          size_option_selected = true;
                                        }
                                        return (
                                          <span
                                            key={
                                              products.options[index]
                                                .choose_field[i].option_value
                                            }
                                            className={`option-size-img-wrapper ${
                                              size_option_selected
                                                ? "active"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              chooseOption(
                                                products.options[index]
                                                  .choose_field[i],
                                                products.options[index]
                                                  .option_id,
                                                products.options[index]
                                                  .option_name,
                                                products.options[index]
                                                  .choose_field[i].option_value,
                                                products.options[index]
                                                  .option_type
                                              )
                                            }
                                          >
                                            {
                                              products.options[index]
                                                .choose_field[i].option_img
                                            }
                                          </span>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              );
                            } else {
                              if (
                                products.options[index].option_type ==
                                "Textarea"
                              ) {
                                return (
                                  <div
                                    className="row mt-3"
                                    key={products.options[index].option_id}
                                  >
                                    <div className="col-12">
                                      <p className="option-name option-name-color">
                                        {products.options[index].option_name}:
                                      </p>
                                      <span
                                        className={`option-textarea-wrapper`}
                                      >
                                        <textarea
                                          onChange={(e) =>
                                            chooseOption(
                                              products.options[index],
                                              products.options[index].option_id,
                                              products.options[index]
                                                .option_name,
                                              e.target.value,
                                              products.options[index]
                                                .option_type
                                            )
                                          }
                                          className="form-control"
                                          rows="3"
                                        ></textarea>
                                      </span>
                                    </div>
                                  </div>
                                );
                              } else if (
                                products.options[index].option_type == "Text" ||
                                products.options[index].option_type == "File" ||
                                products.options[index].option_type == "Date" ||
                                products.options[index].option_type == "Time" ||
                                products.options[index].option_type ==
                                  "Date & Time"
                              ) {
                                return (
                                  <div
                                    className="row mt-3"
                                    key={products.options[index].option_id}
                                  >
                                    <div className="col-12">
                                      <p className="option-name option-name-color">
                                        {products.options[index].option_name}:
                                      </p>

                                      <span className={`option-text-wrapper`}>
                                        <input
                                          type={
                                            products.options[index]
                                              .option_type == "Date & Time"
                                              ? "datetime-local"
                                              : products.options[index]
                                                  .option_type
                                          }
                                          className="form-control"
                                          onChange={(e) =>
                                            chooseOption(
                                              products.options[index],
                                              products.options[index].option_id,
                                              products.options[index]
                                                .option_name,
                                              e.target.value,
                                              products.options[index]
                                                .option_type
                                            )
                                          }
                                        />
                                      </span>
                                    </div>
                                  </div>
                                );
                              } else if (
                                products.options[index].option_type ==
                                  "Select" ||
                                products.options[index].option_type == "Radio"
                              ) {
                                const selected_dynamic_option_name =
                                  products.options[index].option_name;
                                const selected_dynamic_option_value =
                                  options[selected_dynamic_option_name];
                                return (
                                  <div
                                    className="row mt-3"
                                    key={products.options[index].option_id}
                                  >
                                    <div className="col-12">
                                      <p className="option-name option-name-others">
                                        {products.options[index].option_name}:
                                        {selected_dynamic_option_value &&
                                          selected_dynamic_option_value.option_value && (
                                            <span className="selected-option-value">
                                              {" "}
                                              {
                                                selected_dynamic_option_value.option_value
                                              }
                                            </span>
                                          )}
                                      </p>

                                      <div className="row">
                                        <div className="col-12">
                                          <select
                                            className="form-control"
                                            onChange={(e) =>
                                              chooseOption(
                                                products.options[index]
                                                  .choose_field[e.target.value],
                                                products.options[index]
                                                  .option_id,
                                                products.options[index]
                                                  .option_name,
                                                products.options[index]
                                                  .choose_field[e.target.value]
                                                  .option_value,
                                                products.options[index]
                                                  .option_type
                                              )
                                            }
                                          >
                                            <option value="">
                                              Select{" "}
                                              {
                                                products.options[index]
                                                  .option_name
                                              }
                                            </option>
                                            {Object.keys(
                                              products.options[index]
                                                .choose_field
                                            ).map((i) => {
                                              return (
                                                <option
                                                  key={
                                                    products.options[index]
                                                      .choose_field[i]
                                                      .option_value
                                                  }
                                                  value={i}
                                                >
                                                  {
                                                    products.options[index]
                                                      .choose_field[i]
                                                      .option_value
                                                  }
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else if (
                                products.options[index].option_type ==
                                "Checkbox"
                              ) {
                                return (
                                  <div
                                    className="row mt-3"
                                    key={products.options[index].option_id}
                                  >
                                    <div className="col-12">
                                      <p className="option-name option-name-others">
                                        {products.options[index].option_name}:
                                      </p>
                                      {Object.keys(
                                        products.options[index].choose_field
                                      ).map((i) => {
                                        return (
                                          <div
                                            className="row"
                                            key={
                                              products.options[index]
                                                .choose_field[i].option_value
                                            }
                                          >
                                            <div className="col-12">
                                              <label>
                                                <input
                                                  type={
                                                    products.options[index]
                                                      .option_type
                                                  }
                                                  id={
                                                    products.options[index]
                                                      .choose_field[i]
                                                      .option_value
                                                  }
                                                  name={
                                                    products.options[index]
                                                      .option_name
                                                  }
                                                  onChange={(e) =>
                                                    chooseOption(
                                                      products.options[index]
                                                        .choose_field[i],
                                                      products.options[index]
                                                        .option_id,
                                                      products.options[index]
                                                        .option_name,
                                                      products.options[index]
                                                        .choose_field[i]
                                                        .option_value,
                                                      products.options[index]
                                                        .option_type,
                                                      products.options[index]
                                                        .choose_field
                                                    )
                                                  }
                                                />
                                                &nbsp;
                                                {
                                                  products.options[index]
                                                    .choose_field[i]
                                                    .option_value
                                                }
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    className="row mt-3"
                                    key={products.options[index].option_id}
                                  >
                                    <div className="col-12">
                                      <p className="option-name option-name-color">
                                        {products.options[index].option_name}:
                                      </p>
                                      <span className={`option-text-wrapper`}>
                                        <input
                                          type={
                                            products.options[index].option_type
                                          }
                                          className="form-control"
                                          onChange={(e) =>
                                            chooseOption(
                                              products.options[index],
                                              products.options[index].option_id,
                                              products.options[index]
                                                .option_name,
                                              e.target.value,
                                              products.options[index]
                                                .option_type
                                            )
                                          }
                                        />
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          })
                        : ""}
                    </div>

                    {!disable_shopping_cart && (
                      <div className="row product-quantity">
                        <div className="col-12 product-quantity-meta">
                          <p>
                            <span>Quantity:</span>
                            <input
                              type="number"
                              id="quantity"
                              name="quantity"
                              className="form-control"
                              placeholder="Quantity"
                              onChange={handleQuantity}
                              autoComplete="off"
                              defaultValue="1"
                              min="1"
                            />
                          </p>
                        </div>
                      </div>
                    )}

                    {!disable_shopping_cart && (
                      <div className="row product-details-add-to-cart">
                        <div className="col-6">
                          <div className="add-to-link mt-4">
                            {products.stock_status == 1 ? (
                              <Fragment>
                                <a
                                  className="btn cart-btn add-to-cart"
                                  href="#"
                                  onClick={(e) => addToCartWishlist(e, "cart")}
                                >
                                  <span>Add to cart</span>
                                </a>
                                {!disable_shopping_cart && (
                                  <NavLink
                                    to="/cart"
                                    className="btn cart-btn add-to-cart"
                                  >
                                    <span>View Cart</span>&nbsp;
                                    {cart ? <span>({totalQty})</span> : ""}
                                  </NavLink>
                                )}
                              </Fragment>
                            ) : (
                              <a className="btn cart-btn add-to-cart disable-cart">
                                <span>Out of stock</span>
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/*Related Products*/}
                {relatedProducts && relatedProducts.length > 0 && (
                  <div className="related-products-area">
                    <div className="related-products-title">
                      <h4>Related Products</h4>
                    </div>
                    <div className="row">
                      <div className="related-products-main">
                        {relatedProducts.map(
                          (products_single, products_single_index) => {
                            return (
                              <RelatedProductListSingle
                                productsSingle={products_single}
                                key={"related-product-" + products_single_index}
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </section>
      </Layout>
    </Suspense>
  );
};

export default ProductDetails;
