import React, { Fragment, Suspense, useEffect, useState } from "react";
import { CART, GET_STORAGE } from "../../../config/constant";
import { CONFIG } from "../../../config/settings";
import ReactImageFallback from "react-image-fallback";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ManageCart, RemoveFromCart } from "../../../redux/actions/cart";
import {
  AddToWishlist,
  RemoveFromWishlist,
} from "../../../redux/actions/wishlist";

const LatestProductSingle = (props) => {
  const [productsSingle, setProductsSingle] = useState({});
  const [totalPrice, setTotalPrice] = useState(0.0);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart[0]);
  const wishlist = useSelector((state) => state.wishlist[0]);

  useEffect(() => {
    setProductsSingle(props.productsSingle);
    if (auth && auth.user && auth.user.type == "WH") {
      setTotalPrice(
        props.productsSingle.wholesale_price
          ? parseFloat(props.productsSingle.wholesale_price).toFixed(2)
          : props.productsSingle.price
          ? parseFloat(props.productsSingle.price).toFixed(2)
          : 0.0
      );
    } else {
      setTotalPrice(
        props.productsSingle.price
          ? parseFloat(props.productsSingle.price).toFixed(2)
          : 0.0
      );
    }
  }, [props]);

  const others_data = JSON.parse(props.productsSingle.others);

  const feature_image =
    others_data &&
    others_data.feature_image_full_url &&
    others_data.feature_image_full_url.medium_img
      ? others_data.feature_image_full_url.medium_img
      : require("../../../assets/img/no-image.png").default;

  const second_img =
    others_data &&
    others_data.other_picture_full_url &&
    others_data.other_picture_full_url.length > 0
      ? others_data.other_picture_full_url[0].medium_img
      : feature_image;

  let single_price = props.productsSingle.price
    ? parseFloat(props.productsSingle.price).toFixed(2)
    : 0.0;

  return (
    <div className="product-wrapper">
      <div className="product-content">
        <div className="product-image">
          <ReactImageFallback
            src={`${feature_image}`}
            fallbackImage={require("../../../assets/img/no-image.png").default}
            initialImage={
              require("../../../assets/img/loading_img.png").default
            }
            alt={props.productsSingle.name}
            className="first-img"
          />
        </div>
        <div className="product-info">
          <h3>{props.productsSingle.name}</h3>
          <p>
            Dense self-leveling texture, high pigmentation, medium-thick
            consistency.
          </p>
          <span>CA$ {totalPrice}</span>
        </div>
        <NavLink
          to={`/products/${props.productsSingle.slug}`}
          className=""
        ></NavLink>
      </div>
    </div>
  );
};

export default LatestProductSingle;
