import React, { Fragment, Suspense, useEffect, useState, useRef } from "react";
import ReactImageFallback from "react-image-fallback";
import axios from 'axios';
import { APIS } from '../config/constant';
import { API_STATUS, FORMAT_FORM_DATA } from '../../../config/constant';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';

const ChangePassword = (props) => {
    const [loading, setLoading] = useState(true);
    const [is_submitted, setIs_submitted] = useState(false);

    const { register, errors, handleSubmit, watch, setValue, setError } = useForm();
    const password = useRef({});
    password.current = watch("password", "");

    useEffect(() => {
        setLoading(false)
    }, []);

    const submitForm = (formdata) => {
        setIs_submitted(true)
        axios.post(APIS.CHANGEPASSWORD, FORMAT_FORM_DATA(formdata))
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        setValue('current_password', '')
                        setValue('password', '')
                        setValue('re_password', '')
                        toast.success(response.data.response.message)
                    } else {
                        toast.error(response.data.response.message)
                        if (response.data.response.data && response.data.response.data.errors && Object.keys(response.data.response.data.errors) && Object.keys(response.data.response.data.errors).length > 0) {
                            Object.keys(response.data.response.data.errors).map((error_index) => {
                                let errors = '';
                                Object.keys(response.data.response.data.errors[error_index]).map((error_single_index) => {
                                    errors += response.data.response.data.errors[error_index][error_single_index] + ' ';
                                })
                                setError(error_index, {
                                    type: "manual",
                                    message: errors
                                });
                            })
                        }
                    }
                } else {
                    toast.error('Unknown error occured')
                }
                setIs_submitted(false)
            });
    }

    return (
        <Fragment>
            <div className="account-info-form">
                {
                    loading ?
                        <div className="loading">
                            <ReactImageFallback
                                src={require('../../../assets/img/loading.gif').default}
                                fallbackImage={require('../../../assets/img/no-image.png').default}
                                initialImage={require('../../../assets/img/loading.gif').default}
                                alt="no image"
                                style={{ width: '30px' }}
                                className="" />
                        </div>
                        :
                        <div className="row">
                            <div className="col-md-12">
                                <form method="POST" onSubmit={handleSubmit(submitForm)}>
                                    <div className="form-group">
                                        <input type="password" name="current_password" className="form-control" id="current_password" placeholder="Current Password" ref={register({
                                            required: "This field is required"
                                        })} />
                                        {errors.current_password && <label className="text-danger">* {errors.current_password.message}</label>}
                                    </div>
                                    <div className="form-group">
                                        <input type="password" name="password" className="form-control" id="password" placeholder="New Password" ref={register({
                                            required: "This field is required"
                                        })} />
                                        {errors.password && <label className="text-danger">* {errors.password.message}</label>}
                                    </div>
                                    <div className="form-group">
                                        <input type="password" name="re_password" className="form-control" id="re_password" placeholder="Confirm Password" ref={register({
                                            required: "This field is required",
                                            validate: value =>
                                                value === password.current || "Confirm Password did not match"
                                        })} />
                                        {errors.re_password && <label className="text-danger">* {errors.re_password.message ? errors.re_password.message : errors.password.re_password}</label>}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4">
                                            <div className="text-center"><input className="btn btn-warning coyote-btn form-control" type="submit" name="submit" disabled={is_submitted} value={is_submitted ? 'Please Wait ...' : 'Update'} /></div>
                                        </div>
                                        <div className="col-md-4"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                }
            </div>
        </Fragment>
    )
}

export default ChangePassword;