import React, { Suspense, useState, useEffect, useReducer } from "react";
import { useStore, useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory, NavLink } from 'react-router-dom';
import axios from 'axios';

import Layout from '../../../utils/templates/layout/default';
import { APIS } from '../config/constant';
import { API_STATUS, FORMAT_FORM_DATA } from '../../../config/constant';
import { initUserAuthentication } from '../../../redux/actions/auth'

import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";


const ForgotPassword = () => {
    const history = useHistory()
    // const store = useStore()
    const [is_submitted, setIs_submitted] = useState(false);
    const url = new URL(window.location.href);
    const redirect = url.searchParams.get('redirect');
    let redirect_url = false;
    if (redirect) {
        redirect_url = '/' + redirect;
    }

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const { register, errors, handleSubmit, setValue } = useForm();

    useEffect(() => {
        if (auth && auth.token) {
            toast.error('You are already logged in');
            history.push('/');
        }
    }, []);

    const submitForm = (formdata) => {
        setIs_submitted(true)
        axios.post(APIS.FORGOTPASSWORD, FORMAT_FORM_DATA(formdata))
            .then((response) => {
                if (response.data.status == API_STATUS.OK) {
                    if (response.data.response.result) {
                        toast.success(response.data.response.message)
                        setValue('username', '')
                    } else {
                        toast.error(response.data.response.message)
                    }
                }
                setIs_submitted(false)
            });
    }

    return (
        <Suspense>
            <Layout>
                <section className="login-page">
                <div className="container-fluid title-inner-card">
                                    <div className="all-page-title">
                                        <h2>Forgot Password</h2>
                                    </div>
                                </div>
                    <div className="container py-md-80 py-50 container-maxWidth">
                        <div className="row align-items-center">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <form method="POST" onSubmit={handleSubmit(submitForm)}>
                                    <input type="email" name="email" id="email" className="form-control" ref={register({
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "invalid email address"
                                        }
                                    })} placeholder="Email" />
                                    {errors.email && <label className="text-danger">* {errors.email.message ? errors.email.message : 'This field is required'}</label>}
                                    <br />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="text-center"><input className="btn btn-warning coyote-btn form-control" type="submit" name="submit" disabled={is_submitted} value={is_submitted ? 'Please Wait ...' : 'Submit'} /></div>
                                        </div>
                                        <div className="col-md-6 d-flex align-items-center justify-content-end"><NavLink to="/auth/login" className="pr-10">Login</NavLink> <a href="#">|</a> <NavLink to="/auth/register" className="pl-10"> Register</NavLink></div>
                                    </div>

                                </form>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                    </div>
                    <br />
                </section>
            </Layout>
        </Suspense>
    )
};

export default withRouter(ForgotPassword);