import React, { Fragment, Suspense, useEffect, useState } from "react";
import {
  CART,
  GET_STORAGE,
  SET_STORAGE,
  SET_SESSION,
  GET_SESSION,
  QUANTITY_UPDATE,
  SETTINGS,
} from "../../../config/constant";
import { CONFIG } from "../../../config/settings";
import ReactImageFallback from "react-image-fallback";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ManageCart, RemoveFromCart } from "../../../redux/actions/cart";
import axios from "axios";
import moment from "moment";
import { APIS } from "../config/constant";
import {
  API_STATUS,
  FIND_ORDER_STATUS,
  FORMAT_FORM_DATA,
} from "../../../config/constant";
import Layout from "../../../utils/templates/layout/default";

const MyOrderList = (props) => {
  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState("");
  const [product_info, setProduct_info] = useState("");
  const [product_info_list, setProduct_info_list] = useState("");
  const [shipping_address, setShipping_address] = useState("");
  const [billing_address, setBilling_address] = useState("");
  const dispatch = useDispatch();
  const [defaultCurrency, setDefaultCurrency] = useState("");

  useEffect(() => {
    const form_data = { id: props.match.params.id };
    axios
      .post(APIS.ORDERDETAILS, FORMAT_FORM_DATA(form_data))
      .then((response) => {
        if (response.data.status == API_STATUS.OK) {
          if (response.data.response.result) {
            setOrderDetails(response.data.response.data);
            setShipping_address(response.data.response.data.shipping_address);
            setBilling_address(response.data.response.data.billing_address);
            setProduct_info(response.data.response.data.product_info);
            setProduct_info_list(
              response.data.response.data.product_info &&
                response.data.response.data.product_info.list
                ? response.data.response.data.product_info.list
                : ""
            );
          }
        }
        setLoading(false);
      });

    const settings = GET_STORAGE(SETTINGS);
    if (settings) {
      const settingsParsed = JSON.parse(settings);
      if (
        settingsParsed.shopSettingData &&
        settingsParsed.shopSettingData.product_options &&
        settingsParsed.shopSettingData.product_options.default_currency
      ) {
        setDefaultCurrency(
          settingsParsed.shopSettingData.product_options.default_currency
        );
      }
    }
  }, []);

  const goToPrint = () => {
    window.print();
  };

  const showPromotionalMessage = (productInfo) => {
    let text =
      "Your promotional code is " + productInfo.coupon_info.code + " and ";
    let discountText =
      "You have received a " +
      defaultCurrency +
      productInfo.coupon_info.rate +
      " discount.";
    if (productInfo.coupon_info.type === "P") {
      discountText =
        "You have received a " + productInfo.coupon_info.rate + "% discount.";
    }

    return text + discountText;
  };

  return (
    <Layout>
      <Suspense>
        <section className="myaccount">
          <div className="container-fluid title-inner-card no_print">
            <div className="all-page-title">
              <h2>Order Details </h2>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* <br /> */}
                <br />
                <Fragment>
                  <div className="all-page-title">
                    <h2 className="no_print">
                      <span
                        className="pull-right print_btn"
                        onClick={goToPrint}
                        title="Print Invoice"
                      >
                        <i className="fa fa-print"></i>
                      </span>
                    </h2>
                    <h2 className="d-none print">Invoice</h2>
                  </div>
                  <div>
                    {loading ? (
                      <div className="loading">
                        <ReactImageFallback
                          src={
                            require("../../../assets/img/loading.gif").default
                          }
                          fallbackImage={
                            require("../../../assets/img/no-image.png").default
                          }
                          initialImage={
                            require("../../../assets/img/loading.gif").default
                          }
                          alt="no image"
                          style={{ width: "30px" }}
                          className=""
                        />
                      </div>
                    ) : (
                      <Fragment>
                        <div className="row mt-30">
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="order-details">
                              <div className="title">Order Details</div>
                              <div className="details">
                                <p>
                                  Order Number :{" "}
                                  <span>{orderDetails.order_number}</span>
                                </p>
                                <p>
                                  Order Date :{" "}
                                  <span>
                                    {orderDetails.created_at
                                      ? moment(orderDetails.created_at).format(
                                          "MMM D, YYYY"
                                        )
                                      : ""}
                                  </span>
                                </p>
                                {orderDetails.shipping_method &&
                                  orderDetails.shipping_method.type && (
                                    <p>
                                      Shipping Method :{" "}
                                      <span>
                                        {orderDetails.shipping_method.type}
                                      </span>
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="order-details">
                              <div className="title">Billing To</div>
                              <div className="details">
                                <p>
                                  <span>
                                    {" "}
                                    {billing_address.first_name}{" "}
                                    {billing_address.last_name}{" "}
                                  </span>
                                </p>
                                {billing_address.address2 !== "" && (
                                  <p>
                                    <span> {billing_address.address1} </span>{" "}
                                  </p>
                                )}
                                {billing_address.address2 !== "" && (
                                  <p>
                                    <span> {billing_address.address1} </span>{" "}
                                  </p>
                                )}
                                {(billing_address.city !== "" ||
                                  billing_address.province_text !== "" ||
                                  billing_address.country_text !== "") && (
                                  <p>
                                    <span>
                                      {billing_address.city}{" "}
                                      {billing_address.province_text}{" "}
                                      {billing_address.country_text}
                                    </span>
                                  </p>
                                )}
                                {billing_address.postal_code !== "" && (
                                  <p>
                                    {" "}
                                    Postcode :{" "}
                                    <span>{billing_address.postal_code}</span>
                                  </p>
                                )}
                                {billing_address.email !== "" && (
                                  <p>
                                    <a href={`mailto:${billing_address.email}`}>
                                      {" "}
                                      Email :{" "}
                                      <span>{billing_address.email}</span>
                                    </a>
                                  </p>
                                )}
                                {billing_address.telephone !== "" && (
                                  <p>
                                    <a
                                      href={`tel:${billing_address.telephone}`}
                                    >
                                      Phone :{" "}
                                      <span>{billing_address.telephone}</span>
                                    </a>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="order-details">
                              <div className="title">Ship To</div>
                              <div className="details">
                                <p>
                                  <span>
                                    {" "}
                                    {shipping_address.first_name}{" "}
                                    {shipping_address.last_name}{" "}
                                  </span>
                                </p>
                                {shipping_address.address2 !== "" && (
                                  <p>
                                    <span> {shipping_address.address1} </span>{" "}
                                  </p>
                                )}
                                {shipping_address.address2 !== "" && (
                                  <p>
                                    <span> {shipping_address.address1} </span>{" "}
                                  </p>
                                )}
                                {(shipping_address.city !== "" ||
                                  shipping_address.province_text !== "" ||
                                  shipping_address.country_text !== "") && (
                                  <p>
                                    <span>
                                      {shipping_address.city}{" "}
                                      {shipping_address.province_text}{" "}
                                      {shipping_address.country_text}
                                    </span>
                                  </p>
                                )}
                                {shipping_address.postal_code !== "" && (
                                  <p>
                                    {" "}
                                    Postcode :{" "}
                                    <span>{shipping_address.postal_code}</span>
                                  </p>
                                )}
                                {shipping_address.email !== "" && (
                                  <p>
                                    <a
                                      href={`mailto:${shipping_address.email}`}
                                    >
                                      {" "}
                                      Email :{" "}
                                      <span>{shipping_address.email}</span>
                                    </a>
                                  </p>
                                )}
                                {shipping_address.telephone !== "" && (
                                  <p>
                                    <a
                                      href={`tel:${shipping_address.telephone}`}
                                    >
                                      Phone :{" "}
                                      <span>{shipping_address.telephone}</span>
                                    </a>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12">
                                <div
                                  className="profile-parent account-common-wrapper pt-50"
                                  id="deliveryDateHtml"
                                >
                                  <h2 className="section-main-title">
                                    Product Information
                                  </h2>
                                  <div className="order-content-parent p-top-10">
                                    <div className="table-responsive">
                                      <table className="table table-bordered table-stripe text-center product-table">
                                        <thead>
                                          <tr>
                                            <th>Name </th>
                                            <th className="text-center">
                                              Quantity
                                            </th>
                                            <th className="text-center">
                                              Price
                                            </th>
                                            <th className="text-center">
                                              Discount
                                            </th>
                                            <th className="text-center">
                                              Sub-Tax
                                            </th>
                                            <th className="text-right">
                                              Subtotal
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {product_info_list &&
                                          Object.keys(product_info_list)
                                            .length > 0 ? (
                                            <Fragment>
                                              {Object.keys(
                                                product_info_list
                                              ).map(
                                                (product_info_list_index) => {
                                                  return (
                                                    <tr
                                                      key={
                                                        product_info_list_index
                                                      }
                                                    >
                                                      <td className="text-left">
                                                        {product_info_list[
                                                          product_info_list_index
                                                        ].type == "custom" ? (
                                                          <div className="row">
                                                            <div className="col-md-2 col-sm-12">
                                                              <a
                                                                href={
                                                                  product_info_list[
                                                                    product_info_list_index
                                                                  ].stages.front
                                                                    .print_file
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer"
                                                              >
                                                                <ReactImageFallback
                                                                  src={
                                                                    product_info_list[
                                                                      product_info_list_index
                                                                    ].stages
                                                                      .front
                                                                      .screenshot
                                                                  }
                                                                  fallbackImage={
                                                                    require("../../../assets/img/no-image.png")
                                                                      .default
                                                                  }
                                                                  initialImage={
                                                                    require("../../../assets/img/loading.gif")
                                                                      .default
                                                                  }
                                                                  alt="no image"
                                                                  style={{
                                                                    width:
                                                                      "25px",
                                                                  }}
                                                                  className=""
                                                                />
                                                              </a>
                                                              {product_info_list[
                                                                product_info_list_index
                                                              ].stages &&
                                                              product_info_list[
                                                                product_info_list_index
                                                              ].stages.back &&
                                                              product_info_list[
                                                                product_info_list_index
                                                              ].stages.back
                                                                .print_file ? (
                                                                <Fragment>
                                                                  &nbsp;
                                                                  <a
                                                                    href={
                                                                      product_info_list[
                                                                        product_info_list_index
                                                                      ].stages
                                                                        .back
                                                                        .print_file
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                  >
                                                                    <ReactImageFallback
                                                                      src={
                                                                        product_info_list[
                                                                          product_info_list_index
                                                                        ].stages
                                                                          .back
                                                                          .screenshot
                                                                      }
                                                                      fallbackImage={
                                                                        require("../../../assets/img/no-image.png")
                                                                          .default
                                                                      }
                                                                      initialImage={
                                                                        require("../../../assets/img/loading.gif")
                                                                          .default
                                                                      }
                                                                      alt="no image"
                                                                      style={{
                                                                        width:
                                                                          "25px",
                                                                      }}
                                                                      className=""
                                                                    />
                                                                  </a>
                                                                </Fragment>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </div>
                                                            <div className="col-md-10 col-sm-12">
                                                              {
                                                                product_info_list[
                                                                  product_info_list_index
                                                                ].name
                                                              }
                                                              {product_info_list[
                                                                product_info_list_index
                                                              ].options &&
                                                              Object.keys(
                                                                product_info_list[
                                                                  product_info_list_index
                                                                ].options
                                                              ).length > 0 ? (
                                                                <Fragment>
                                                                  <br />({" "}
                                                                  {Object.keys(
                                                                    product_info_list[
                                                                      product_info_list_index
                                                                    ].options
                                                                  ).map(
                                                                    (
                                                                      option_index
                                                                    ) => {
                                                                      return (
                                                                        product_info_list[
                                                                          product_info_list_index
                                                                        ]
                                                                          .options[
                                                                          option_index
                                                                        ] + " "
                                                                      );
                                                                    }
                                                                  )}
                                                                  )
                                                                </Fragment>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div className="row">
                                                            <div className="col-md-2 col-sm-12">
                                                              <ReactImageFallback
                                                                src={
                                                                  product_info_list[
                                                                    product_info_list_index
                                                                  ].image
                                                                }
                                                                fallbackImage={
                                                                  require("../../../assets/img/no-image.png")
                                                                    .default
                                                                }
                                                                initialImage={
                                                                  require("../../../assets/img/loading.gif")
                                                                    .default
                                                                }
                                                                alt="no image"
                                                                style={{
                                                                  width: "50px",
                                                                }}
                                                                className=""
                                                              />
                                                            </div>
                                                            <div className="col-md-10 col-sm-12">
                                                              {
                                                                product_info_list[
                                                                  product_info_list_index
                                                                ].name
                                                              }
                                                              {product_info_list[
                                                                product_info_list_index
                                                              ].options &&
                                                              Object.keys(
                                                                product_info_list[
                                                                  product_info_list_index
                                                                ].options
                                                              ).length > 0 ? (
                                                                <Fragment>
                                                                  <br />({" "}
                                                                  {Object.keys(
                                                                    product_info_list[
                                                                      product_info_list_index
                                                                    ].options
                                                                  ).map(
                                                                    (
                                                                      option_index
                                                                    ) => {
                                                                      return (
                                                                        product_info_list[
                                                                          product_info_list_index
                                                                        ]
                                                                          .options[
                                                                          option_index
                                                                        ]
                                                                          .option_value +
                                                                        " "
                                                                      );
                                                                    }
                                                                  )}
                                                                  )
                                                                </Fragment>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </div>
                                                          </div>
                                                        )}
                                                      </td>
                                                      <td>
                                                        {
                                                          product_info_list[
                                                            product_info_list_index
                                                          ].quantity
                                                        }
                                                      </td>
                                                      <td>
                                                        {defaultCurrency}
                                                        {parseFloat(
                                                          product_info_list[
                                                            product_info_list_index
                                                          ].price_with_variation
                                                        ).toFixed(2)}
                                                      </td>
                                                      <td>
                                                        {defaultCurrency}
                                                        {parseFloat(
                                                          product_info_list[
                                                            product_info_list_index
                                                          ].discount_price
                                                        ).toFixed(2)}
                                                      </td>
                                                      <td>
                                                        {defaultCurrency}0.00
                                                      </td>
                                                      <td className="text-right">
                                                        {defaultCurrency}
                                                        {parseFloat(
                                                          product_info_list[
                                                            product_info_list_index
                                                          ].item_total
                                                        ).toFixed(2)}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </Fragment>
                                          ) : (
                                            ""
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 pb-50">
                                <table className="subtotal-table table-bordered">
                                  <tbody>
                                    <tr>
                                      <th>Subtotal</th>
                                      <td>
                                        {defaultCurrency}
                                        {parseFloat(
                                          product_info.sub_total
                                        ).toFixed(2)}
                                      </td>
                                    </tr>
                                    {product_info.coupon_info &&
                                      product_info.discount > 0 && (
                                        <tr>
                                          <th>
                                            Discount(-):
                                            <br />
                                            <small className="text-danger">
                                              {showPromotionalMessage(
                                                product_info
                                              )}
                                            </small>
                                          </th>
                                          <td>
                                            {defaultCurrency}
                                            {parseFloat(
                                              product_info.discount
                                            ).toFixed(2)}
                                          </td>
                                        </tr>
                                      )}
                                    {product_info.tax_text &&
                                      product_info.tax_amount > 0 && (
                                        <tr>
                                          <th>{product_info.tax_text}</th>
                                          <td>
                                            {defaultCurrency}
                                            {parseFloat(
                                              product_info.tax_amount
                                            ).toFixed(2)}
                                          </td>
                                        </tr>
                                      )}
                                    {product_info.shipping_final_price && (
                                      <tr>
                                        <th>Shipping</th>
                                        <td>
                                          {defaultCurrency}
                                          {parseFloat(
                                            product_info.shipping_final_price
                                          ).toFixed(2)}
                                        </td>
                                      </tr>
                                    )}
                                    <tr>
                                      <th>Grand Total</th>
                                      <td>
                                        {defaultCurrency}
                                        {parseFloat(
                                          orderDetails.amount
                                        ).toFixed(2)}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </Fragment>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </Layout>
  );
};

export default MyOrderList;
