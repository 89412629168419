import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink, withRouter, useHistory } from 'react-router-dom';

// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../../assets/css/style.scss';

// import FontsCSS from './../../../assets/fonts/fonts.css';
// import StyleCSS from './../../../assets/css/style.scss';
// import CustomScrollcarCSS from './../../../assets/css/jquery.mCustomScrollbar.css';


import { logout } from '../../../redux/actions/auth'
import Header from './header';
import Footer from './footer';
//import BottomNav from './bottomNav';


const Layout = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector(state => state.auth);

    const logout_user = (e) => {
        e.preventDefault();
        dispatch(logout())
        history.push('/auth/login')
    }

    return (
        <>
            {/* <link rel="stylesheet" href={FontsCSS} /> */}
            {/* <link rel="stylesheet" href={CustomScrollcarCSS} /> */}
            {/* <link rel="stylesheet" href={StyleCSS} /> */}

            {/* <ToastContainer /> */}

            <Header />

            {props.children}

            <Footer />
            {/* <BottomNav /> */}


        </>
    );
}

export default withRouter(Layout);